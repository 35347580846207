import noop from "lodash/noop";
import { createContext } from "react";

import { VerticalScrollContextValue } from "./VerticalScroll.types";

export const VerticalScrollContext = createContext<VerticalScrollContextValue>({
  itemsRef: { current: new Map() },
  isScrollingRef: { current: false },
  hiddenSlideIndexes: new Set(),
  hideSlide: noop,
  showSlide: noop,
  currentSlideRef: { current: 0 },
  slidesCount: 0,
  options: {
    isDisabled: false,
    lockFromOverscroll: false,
    noMobileNav: false,
    noMobileSnapping: false,
    disableSnappingOnFooter: false,
    hideNavAfterLastThreshold: 0.1,
    navButtonVisibilityThreshold: 0,
  },
});
