import { createContext, useContext, useMemo } from "react";

import type { PrimaryNavSlots } from "./types/PrimaryNav.types";

interface PrimaryNavControls {
  pushActiveMenuId: (menuId: string) => void;
  popActiveMenu: () => void;
}

interface PrimaryNavContextValue {
  slots: PrimaryNavSlots;
  controls: PrimaryNavControls;
}

const PrimaryNavSlotsContext = createContext<PrimaryNavSlots>({
  searchMenu: undefined,
  searchPopover: undefined,
  searchMenuTrigger: undefined,
});

const throwIfNoProvider = () => {
  throw new Error("You can't use this operation without PrimaryNavContextProvider");
};

const PrimaryNavControlsContext = createContext<PrimaryNavControls>({
  pushActiveMenuId: throwIfNoProvider,
  popActiveMenu: throwIfNoProvider,
});

export const PrimaryNavContextProvider: React.FC<
  React.PropsWithChildren<PrimaryNavContextValue>
> = ({ slots, controls, children }) => {
  const slotsContextValue: PrimaryNavSlots = useMemo(
    () => ({
      searchMenu: slots.searchMenu,
      searchPopover: slots.searchPopover,
      searchMenuTrigger: slots.searchMenuTrigger,
    }),
    [slots.searchMenu, slots.searchPopover, slots.searchMenuTrigger]
  );

  const controlsContextValue: PrimaryNavControls = useMemo(
    () => ({
      pushActiveMenuId: controls.pushActiveMenuId,
      popActiveMenu: controls.popActiveMenu,
    }),
    [controls.pushActiveMenuId, controls.popActiveMenu]
  );

  return (
    <PrimaryNavSlotsContext.Provider value={slotsContextValue}>
      <PrimaryNavControlsContext.Provider value={controlsContextValue}>
        {children}
      </PrimaryNavControlsContext.Provider>
    </PrimaryNavSlotsContext.Provider>
  );
};

export const usePrimaryNavSlot = <T extends keyof PrimaryNavSlots>(slot: T): PrimaryNavSlots[T] =>
  useContext(PrimaryNavSlotsContext)[slot];

export const usePrimaryNavControls = (): PrimaryNavControls =>
  useContext(PrimaryNavControlsContext);
