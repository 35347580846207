import jump from "jump.js";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useAnalytics } from "@/utils/DataAnalytics";
import {
  DataAnalyticsEventActions,
  DataAnalyticsEventTargets,
} from "@/utils/DataAnalytics/models/DataAnalyticsDefinitions";

import { SCROLLING_TRACKING_STEPS } from "./VerticalScroll.constants";
import styles from "./VerticalScroll.module.scss";
import {
  VerticalScrollSlideIntersectionEvent,
  VerticalScrollSlideResizeEvent,
} from "./VerticalScroll.types";
import { ensureScrollItemByKey, getVisibleIndexes } from "./VerticalScroll.utils";
import { VerticalScrollContext } from "./VerticalScrollContext";
import { VerticalScrollItem } from "./VerticalScrollItem";

export const useHandleResize = () => {
  const {
    hiddenSlideIndexes,
    hideSlide,
    showSlide,
    options: { navButtonVisibilityThreshold },
  } = useContext(VerticalScrollContext);

  return useCallback(
    (ev: VerticalScrollSlideResizeEvent) => {
      const isHidden = ev.heightRatio < navButtonVisibilityThreshold;
      const isCurrentlyHidden = hiddenSlideIndexes.has(ev.index);
      if (isHidden && !isCurrentlyHidden) {
        hideSlide(ev.index);
      } else if (!isHidden && isCurrentlyHidden) {
        showSlide(ev.index);
      }
    },
    [navButtonVisibilityThreshold, hiddenSlideIndexes, hideSlide, showSlide]
  );
};

/**
 * Handles the intersection change of the slides
 * @param slidesCount The number of slides
 * @param updateScrollingControlsVisibility Function to update the visibility of the scrolling controls
 * @returns Function to handle the intersection change
 */
export const useHandleIntersectionChange = (
  slidesCount: number,
  updateScrollingControlsVisibility: () => void
) => {
  const { registerInteraction } = useAnalytics();
  const {
    itemsRef,
    currentSlideRef,
    hiddenSlideIndexes,
    options: { hideNavAfterLastThreshold, lockFromOverscroll },
  } = useContext(VerticalScrollContext);

  return useCallback(
    (ev: VerticalScrollSlideIntersectionEvent): void => {
      const [firstIndex, lastIndex] = getVisibleIndexes(itemsRef, hiddenSlideIndexes);
      const isFirst = ev.index === firstIndex;
      const isLast = ev.index === lastIndex;
      const isFooter = isLast && ev.ratio > hideNavAfterLastThreshold;
      const blockOverscroll =
        lockFromOverscroll && ((ev.ratio < 0 && isFirst) || (ev.ratio > 0 && isLast));
      const currentScrollItem = ensureScrollItemByKey(itemsRef, ev.index);
      const currentIndex = isFooter ? lastIndex + 1 : ev.index;
      const scrollDirection =
        currentSlideRef.current === currentIndex || !ev.isActive
          ? 0
          : currentSlideRef.current < currentIndex
          ? 1
          : -1;

      if (ev.isActive || isFooter) {
        currentSlideRef.current = currentIndex;
      }

      updateScrollingControlsVisibility();

      currentScrollItem.progress = blockOverscroll ? 0 : ev.ratio;

      if (scrollDirection) {
        registerInteraction(
          DataAnalyticsEventTargets.VerticalScroll,
          DataAnalyticsEventActions.VerticalScrollUpdate,
          {
            action: `Showing ${isFooter ? "Footer" : ev.index + 1} of ${slidesCount}`,
            currentTile: ev.index + 1,
            totalTiles: slidesCount,
            direction: scrollDirection,
          }
        );
      }
    },
    [
      itemsRef,
      hiddenSlideIndexes,
      hideNavAfterLastThreshold,
      lockFromOverscroll,
      currentSlideRef,
      updateScrollingControlsVisibility,
      registerInteraction,
      slidesCount,
    ]
  );
};

const isFullyVisible = (item: VerticalScrollItem | undefined) =>
  Math.abs(item?.progress || 0) <= 1 / SCROLLING_TRACKING_STEPS;

const isBiggerThanScreen = (item: VerticalScrollItem | undefined): boolean =>
  typeof window !== "undefined" && (item?.slideWrapper?.offsetHeight || 0) > window.innerHeight;

/**
 * Handles the visibility of the scrolling controls
 * @param isDisabled Whether the vertical scroll is disabled
 * @param slidesCount The number of slides
 * @param currentSlideRef Ref containing the current active slide index
 * @returns Whether the scroll indicator and the scroll nav should be hidden and a function to update their visibility
 */
export const useScrollingControlsState = () => {
  const {
    itemsRef,
    currentSlideRef,
    hiddenSlideIndexes,
    options: { disableSnappingOnFooter, isDisabled },
  } = useContext(VerticalScrollContext);

  const [hideScrollIndicator, setHideScrollIndicator] = useState(isDisabled);
  const [hideScrollNav, setHideScrollNav] = useState(isDisabled);
  const { enableGlobalSnapping, disableGlobalSnapping } = useGlobalSnapping();

  const updateScrollingControlsState = useCallback(() => {
    const [firstIndex, lastIndex] = getVisibleIndexes(itemsRef, hiddenSlideIndexes);
    const currentItem = itemsRef.current.get(currentSlideRef.current);
    const isFirst = currentSlideRef.current <= firstIndex;
    const isFooter = currentSlideRef.current > lastIndex;
    const hideNav = isDisabled || isFooter || firstIndex === lastIndex;

    setHideScrollNav(hideNav);
    setHideScrollIndicator(!isFirst || hideNav);
    if (disableSnappingOnFooter) {
      // Browsers won't allow space scrolling if footer is too small after the last slide
      // so we need to check isFullLast
      const isFullLast =
        currentSlideRef.current === lastIndex &&
        // Check if the last slide is fully visible or bigger than the screen (edge case)
        (isFullyVisible(currentItem) || isBiggerThanScreen(currentItem));

      if (isFooter || isFullLast) {
        disableGlobalSnapping();
      } else {
        enableGlobalSnapping();
      }
    }
  }, [
    itemsRef,
    hiddenSlideIndexes,
    currentSlideRef,
    isDisabled,
    disableSnappingOnFooter,
    disableGlobalSnapping,
    enableGlobalSnapping,
  ]);

  useEffect(() => {
    updateScrollingControlsState();
  }, [updateScrollingControlsState]);

  return { hideScrollIndicator, hideScrollNav, updateScrollingControlsState };
};

/**
 * Handles scrolling to a specific slide
 * @param scrollItemsRef Ref containing the slide elements
 * @returns Functions to scroll to a specific slide and to scroll to the second slide
 */
export const useSlideScrolling = () => {
  const { itemsRef, isScrollingRef, currentSlideRef } = useContext(VerticalScrollContext);
  const { disableGlobalSnapping, enableGlobalSnapping } = useGlobalSnapping();

  const handleScrollToSlide = useCallback(
    (index: number) => {
      const currentSlide = itemsRef.current.get(index)?.slideWrapper;
      if (currentSlide) {
        isScrollingRef.current = true;
        disableGlobalSnapping();
        jump(currentSlide, {
          callback() {
            isScrollingRef.current = false;
            enableGlobalSnapping();
            currentSlide?.focus();
          },
        });
      }
    },
    [disableGlobalSnapping, enableGlobalSnapping, isScrollingRef, itemsRef]
  );

  const scrollToNext = useCallback(() => {
    handleScrollToSlide(currentSlideRef.current + 1);
  }, [currentSlideRef, handleScrollToSlide]);

  return { handleScrollToSlide, scrollToNext } as const;
};

const useGlobalSnapping = () => {
  const {
    isScrollingRef,
    options: { isDisabled, noMobileNav, useProximitySnapping },
  } = useContext(VerticalScrollContext);

  return useMemo(
    () => ({
      disableGlobalSnapping: () => {
        document.documentElement.classList.remove(styles.htmlDesktopProximity);
        document.documentElement.classList.remove(styles.htmlDesktopMandatory);
        document.documentElement.classList.remove(styles.htmlMobileProximity);
        document.documentElement.classList.remove(styles.htmlMobileMandatory);
        document.documentElement.classList.remove(styles.bodyDesktop);
        document.documentElement.classList.remove(styles.bodyMobile);
      },
      enableGlobalSnapping: () => {
        if (isDisabled || isScrollingRef.current) {
          return;
        }

        document.documentElement.classList.add(
          useProximitySnapping ? styles.htmlDesktopProximity : styles.htmlDesktopMandatory
        );
        document.documentElement.classList.add(styles.bodyDesktop);

        if (noMobileNav) {
          document.documentElement.classList.add(
            useProximitySnapping ? styles.htmlMobileProximity : styles.htmlMobileMandatory
          );
          document.documentElement.classList.add(styles.bodyMobile);
        }
      },
    }),
    [isDisabled, isScrollingRef, noMobileNav, useProximitySnapping]
  );
};

/**
 * Adds global scrolling styles to the document to enable native snapping
 * @param isDisabled Whether the vertical scroll is disabled
 */
export const useGlobalScrollingStyles = () => {
  const { disableGlobalSnapping, enableGlobalSnapping } = useGlobalSnapping();

  useEffect(() => {
    enableGlobalSnapping();

    return () => {
      disableGlobalSnapping();
    };
  }, [disableGlobalSnapping, enableGlobalSnapping]);
};
