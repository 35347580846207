import cn from "classnames";
import type { CSSProperties } from "react";

import { ComponentWithRequiredProps } from "@/utils/advancedTypes";
import { mergeProps } from "@/utils/reactExtensions";

import styles from "./InputBorder.module.scss";
import { getInputBorderStyles, InputBorderStyleProps } from "./InputBorder.utils";

type RequiredProps = React.PropsWithChildren<{
  className?: string | undefined;
  style?: CSSProperties | undefined;
}>;

type ValidComponent<P = NonNullable<unknown>> =
  | React.JSXElementConstructor<P>
  | keyof JSX.IntrinsicElements;

export interface BaseInputBorderProps
  extends React.HTMLAttributes<HTMLElement>,
    InputBorderStyleProps {}

export type InputBorderProps<C extends ValidComponent> = {
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
} & BaseInputBorderProps &
  (
    | ({ component?: never | "label" } & React.ComponentProps<"label">)
    | ({
        component: ComponentWithRequiredProps<C, RequiredProps>;
      } & Omit<React.ComponentProps<C>, keyof BaseInputBorderProps>)
  );

/**
 * InputBorder component offers border with predefined styles. This component should only be used for `form elements`
 * and should be used in conjunction with the `FormContainer` component.
 *
 * The `state` property is used to determine the border color.
 * The `borderRadius` property is used to determine the border radius.
 * The `isHoverStateDisabled` property is used to determine if the hover state should be disabled or not.
 *
 * ## Usage
 *
 * ```tsx
 * <InputBorder isHoverStateDisabled={false} state="success" borderRadius="small">
 *   <input type="text" />
 * </InputBorder>;
 * ```
 */
export const InputBorder = <C extends ValidComponent>({
  component: Component = "label",
  className,
  children,
  borderRadius,
  isHoverStateDisabled,
  state,
  ...rest
}: InputBorderProps<C>) => {
  const { inputBorderStyles, className: InputBorderHoverStateClassName } = getInputBorderStyles({
    borderRadius,
    isHoverStateDisabled,
    state,
  });

  const inputBorderProps = {
    style: inputBorderStyles,
    className: cn(styles.inputBorder, InputBorderHoverStateClassName, className),
  };

  return <Component {...mergeProps(rest, inputBorderProps)}>{children}</Component>;
};
