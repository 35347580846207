var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import PropTypes from 'prop-types';
import { MissingComponent } from './MissingComponent';
import { convertAttributesToReactProps } from '../utils';
import { HiddenRendering, HIDDEN_RENDERING_NAME } from './HiddenRendering';
/** [SXA] common marker by which we find container fo replacing **/
const PREFIX_PLACEHOLDER = 'container-{*}';
export class PlaceholderCommon extends React.Component {
    constructor(props) {
        super(props);
        this.nodeRefs = [];
        this.state = {};
        this.addRef = this.addRef.bind(this);
        this.updateKeyAttributes = this.updateKeyAttributes.bind(this);
        this.createRawElement = this.createRawElement.bind(this);
    }
    static getPlaceholderDataFromRenderingData(rendering, name) {
        let result;
        /** [SXA] it needs for deleting dynamics placeholder when we set him number(props.name) of container.
        from backend side we get common name of placeholder is called 'container-{*}' where '{*}' marker for replacing **/
        if (rendering && rendering.placeholders && rendering.placeholders[PREFIX_PLACEHOLDER]) {
            rendering.placeholders[name] = rendering.placeholders[PREFIX_PLACEHOLDER];
            delete rendering.placeholders[PREFIX_PLACEHOLDER];
        }
        if (rendering && rendering.placeholders && Object.keys(rendering.placeholders).length > 0) {
            result = rendering.placeholders[name];
        }
        else {
            result = null;
        }
        if (!result) {
            console.warn(`Placeholder '${name}' was not found in the current rendering data`, JSON.stringify(rendering, null, 2));
            return [];
        }
        return result;
    }
    componentDidMount() {
        this.updateKeyAttributes();
    }
    componentDidUpdate() {
        this.updateKeyAttributes();
    }
    componentDidCatch(error) {
        this.setState({ error });
    }
    getSXAParams(rendering) {
        return (rendering.params.FieldNames && {
            styles: `${rendering.params.GridParameters || ''} ${rendering.params.Styles || ''}`,
        });
    }
    getComponentsForRenderingData(placeholderData) {
        const _a = this.props, { name, fields: placeholderFields, params: placeholderParams, missingComponentComponent, hiddenRenderingComponent } = _a, placeholderProps = __rest(_a, ["name", "fields", "params", "missingComponentComponent", "hiddenRenderingComponent"]);
        return placeholderData
            .map((rendering, index) => {
            const key = rendering.uid
                ? rendering.uid
                : `component-${index}`;
            const commonProps = { key };
            // if the element is not a 'component rendering', render it 'raw'
            if (!rendering.componentName &&
                rendering.name) {
                return this.createRawElement(rendering, commonProps);
            }
            const componentRendering = rendering;
            let component;
            if (componentRendering.componentName === HIDDEN_RENDERING_NAME) {
                component = hiddenRenderingComponent !== null && hiddenRenderingComponent !== void 0 ? hiddenRenderingComponent : HiddenRendering;
            }
            else {
                component = this.getComponentForRendering(componentRendering);
            }
            if (!component) {
                console.error(`Placeholder ${name} contains unknown component ${componentRendering.componentName}. Ensure that a React component exists for it, and that it is registered in your componentFactory.js.`);
                component = missingComponentComponent !== null && missingComponentComponent !== void 0 ? missingComponentComponent : MissingComponent;
            }
            const finalProps = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, commonProps), placeholderProps), ((placeholderFields || componentRendering.fields) && {
                fields: Object.assign(Object.assign({}, placeholderFields), componentRendering.fields),
            })), ((placeholderParams || componentRendering.params) && {
                params: Object.assign(Object.assign(Object.assign({}, placeholderParams), componentRendering.params), this.getSXAParams(componentRendering)),
            })), { rendering: componentRendering });
            return React.createElement(component, this.props.modifyComponentProps ? this.props.modifyComponentProps(finalProps) : finalProps);
        })
            .filter((element) => element); // remove nulls
    }
    getComponentForRendering(renderingDefinition) {
        var _a;
        const componentFactory = this.props.componentFactory;
        if (!componentFactory || typeof componentFactory !== 'function') {
            console.warn(`No componentFactory was available to service request for component ${renderingDefinition}`);
            return null;
        }
        // Render SXA Rendering Variant
        if ((_a = renderingDefinition.params) === null || _a === void 0 ? void 0 : _a.FieldNames) {
            return componentFactory(renderingDefinition.componentName, renderingDefinition.params.FieldNames);
        }
        return componentFactory(renderingDefinition.componentName);
    }
    addRef(nodeRef) {
        this.nodeRefs.push(nodeRef);
    }
    createRawElement(elem, baseProps) {
        if (!elem.name) {
            console.error('"elem.name" is undefined in "createRawElement". Something is likely wrong with your component data. Ensure that your components have a name.');
            return null;
        }
        const attributes = convertAttributesToReactProps(elem.attributes);
        const props = Object.assign(Object.assign(Object.assign({}, baseProps), attributes), { dangerouslySetInnerHTML: elem.contents ? { __html: elem.contents } : undefined });
        /* Since we can't set the "key" attribute via React, stash it
         * so we can set in the DOM after render.
         */
        if (!Array.isArray(attributes) && attributes && attributes.chrometype === 'placeholder') {
            props.phkey = elem.attributes.key; // props that get rendered as dom attribute names need to be lowercase, otherwise React complains.
            props.ref = this.addRef; // only need ref for placeholder containers, trying to add it to other components (e.g. stateless components) may result in a warning.
        }
        return React.createElement(elem.name, props);
    }
    updateKeyAttributes() {
        if (!this.nodeRefs) {
            return;
        }
        this.nodeRefs.forEach((ref) => {
            if (ref && ref.getAttribute) {
                // ref might be a wrapped component, so check for existence of getAttribute method
                const key = ref.getAttribute('phkey');
                if (key) {
                    // need to manually set the 'key' attribute after component mount because
                    // 'key' is a reserved attribute/prop in React. so it will never be rendered
                    // as an html attribute.
                    ref.setAttribute('key', key);
                }
            }
        });
    }
}
PlaceholderCommon.propTypes = {
    rendering: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.object,
    ]).isRequired,
    fields: PropTypes.objectOf(PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.object,
    ]).isRequired),
    params: PropTypes.objectOf(PropTypes.string.isRequired),
    missingComponentComponent: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func,
    ]),
    hiddenRenderingComponent: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func,
    ]),
    errorComponent: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func,
    ]),
    modifyComponentProps: PropTypes.func,
};
