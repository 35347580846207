import { logoutThunk } from "ReduxSlices/user";
import { useEffect } from "react";

import { useThunkDispatch } from "Hooks/thunk";

const SilentSignOut: React.FC = () => {
    const dispatch = useThunkDispatch();

    useEffect(() => {
        dispatch(logoutThunk);
    }, []);

    return null;
};

export default SilentSignOut;
