import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGChevronRight: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fill="currentColor"
      d="M8.67157 7.00019L11.5 4.17176L23.3284 16.0002L11.5 27.8286L8.67157 25.0002L17.6716 16.0002L8.67157 7.00019Z"
    />
  </svg>
);

SVGChevronRight = memo(SVGChevronRight);

export { SVGChevronRight };
