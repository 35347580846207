import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGHome: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    {...rest}
  >
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M7.54.162a.736.736 0 0 1 .92 0l6.622 5.307c.174.14.276.35.276.574v9.22c0 .407-.33.737-.737.737h-4.584a.736.736 0 0 1-.736-.736v-5.087H6.7v5.087c0 .406-.33.736-.736.736H1.379a.736.736 0 0 1-.736-.736v-9.22c0-.224.101-.436.275-.575L7.54.162ZM2.115 6.397v8.13h3.112V9.44c0-.406.33-.736.736-.736h4.074c.407 0 .737.33.737.736v5.087h3.111v-8.13L8 1.68 2.115 6.397Z"
      clip-rule="evenodd"
    />
  </svg>
);

SVGHome = memo(SVGHome);

export { SVGHome };
