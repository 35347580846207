import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGKey: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 36 36"
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7214 16.7502C11.2213 16.7439 10.7932 16.9167 10.4369 17.2687C10.0806 17.6207 9.89932 18.05 9.89309 18.5565C9.88687 19.063 10.0575 19.4967 10.405 19.8576C10.7526 20.2184 11.1764 20.402 11.6765 20.4083C12.1765 20.4146 12.6047 20.2418 12.961 19.8898C13.3173 19.5378 13.4986 19.1085 13.5048 18.602C13.511 18.0955 13.3404 17.6618 12.9928 17.301C12.6453 16.9401 12.2215 16.7565 11.7214 16.7502ZM11.775 12.3886C10.0711 12.3671 8.619 12.9492 7.41886 14.1348C6.21873 15.3205 5.60805 16.7763 5.58684 18.5022C5.56562 20.2281 6.14033 21.6988 7.31095 22.9144C8.48157 24.1299 9.91887 24.7485 11.6228 24.7699C12.8082 24.7849 13.8863 24.4889 14.857 23.882C15.8277 23.2751 16.5786 22.4684 17.1097 21.4619L27.1113 21.588L29.9241 18.8091L25.6986 14.4498L23.6233 15.9997L21.5316 14.3691L19.3181 15.8609L17.1789 15.834C16.6725 14.8332 15.9416 14.0123 14.9862 13.3713C14.0308 12.7311 12.9604 12.4035 11.775 12.3886ZM11.7543 14.0769C12.8285 14.0905 13.7506 14.4304 14.5204 15.0968C15.2902 15.7632 15.7898 16.567 16.019 17.508L19.8252 17.556L21.4504 16.4507L23.5977 18.082L25.5049 16.6426L27.5626 18.7793L26.4375 19.8909L15.9914 19.7591C15.7391 20.6941 15.2199 21.4849 14.4339 22.1317C13.648 22.7785 12.7178 23.0951 11.6436 23.0816C10.4212 23.0662 9.38013 22.6121 8.52046 21.7194C7.66078 20.8267 7.23856 19.7613 7.25378 18.5232C7.26899 17.2851 7.71728 16.2306 8.59863 15.3599C9.47998 14.4892 10.5319 14.0615 11.7543 14.0769Z"
    />
  </svg>

  /* eslint-enable max-len */
);
SVGKey = memo(SVGKey);

export { SVGKey };
