import cn from "classnames";

import { getBorderRadiusProps } from "@/components/BorderRadius/BorderRadius";
import { getFocusOutlineProps } from "@/components/FocusOutline/FocusOutline";
import { Stack } from "@/components/Stack/Stack";
import { mergeProps } from "@/utils/reactExtensions";

import styles from "./Chip.module.scss";
import { Typography } from "../Typography/Typography";

export type ChipProps = React.HTMLAttributes<HTMLDivElement | HTMLButtonElement> & {
  /**
   * The SVGIcon used in the chip. It renders in front of the content if both are provided.
   */
  icon?: React.ReactNode;

  /**
   * If true, the chip will expand to fill the width of its container.
   */
  fullWidth?: boolean;

  /**
   * The state of the chip. @default "default"
   */
  state?: "default" | "selected" | "success";

  /**
   * The variant of the chip. Solid is a filled chip, ghost is an outlined chip.
   */
  variant?: "solid" | "ghost";

  /**
   * The size of the chip. @default "large"
   */
  size?: "small" | "large";

  /**
   * If true, the chip will be disabled, greyed out, and not clickable.
   */
  disabled?: boolean;

  /**
   * Callback function triggered when the chip is clicked.
   * Note: The chip will be rendered as a button if this prop is provided.
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;

  /**
   * If true, the chip will have interaction effects like hover, focus and state changes.
   * Note: The button chip will ignore this prop and always be interactive.
   */
  isInteractive?: boolean;
};

/**
 * Chips are a multi purpose component. They can be used as an interactive element to narrow down a collection of items
 * (as a filter) or enable editing, or they can be used as a categorisation tag or provide success feedback.
 *
 * To use multiple chips in a row with default spacing, wrap them in a `ChipsGroup` component.
 *
 * ## Usage
 *
 * ```tsx
 * // As a chip
 * <Chip icon={<SVGCheckMark />}>Your content here</Chip>
 * // As an interact-able chip
 * <Chip icon={<SVGCheckMark />} isInteractive>Your content here</Chip>
 * // As a button chip (implicitly interactive)
 * <Chip onClick={()=>alert('Clicked')}>Your button</Chip>
 * ```
 */
const Chip: React.FC<ChipProps> = ({
  children,
  className,
  variant = "solid",
  size = "large",
  icon,
  fullWidth,
  state = "default",
  disabled,
  onClick,
  isInteractive,
  ...rest
}) => {
  const Component = onClick ? "button" : "div";

  const isClickable = onClick && !disabled;

  const isInteractableOrClickable = isInteractive || isClickable;

  return (
    <Component
      tabIndex={isClickable ? 0 : -1}
      onClick={isClickable ? onClick : undefined}
      disabled={disabled}
      {...mergeProps(
        rest,
        getBorderRadiusProps("rounded"),
        isClickable ? getFocusOutlineProps({}) : null,
        {
          className: cn(
            styles.chip,
            {
              [styles.fullWidth]: fullWidth,
              [styles.clickable]: isClickable,
              [styles.interactive]: isInteractableOrClickable,
            },
            styles[variant],
            styles[size],
            styles[state],
            className
          ),
        }
      )}
    >
      <Stack spacing="3xs" alignItems="center" className={styles.truncateText}>
        {icon && <div className={styles.icon}>{icon}</div>}
        {children && (
          <Typography variant="l1" className={styles.truncateText}>
            {children}
          </Typography>
        )}
      </Stack>
    </Component>
  );
};

export { Chip };
