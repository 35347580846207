import { useContext } from "react";
import { TabPanel as PrimitiveTabPanel, TabListStateContext } from "react-aria-components";

import { Box } from "@/components/Box/Box";
import { mergeProps } from "@/utils/reactExtensions";

import styles from "./Tabs.module.scss";
import { getFocusOutlineProps } from "../FocusOutline/FocusOutline";

export interface TabsPanelProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;

  /**
   * The unique id for each Tab, which is used to connect Tab and Tab Panel.
   */
  id: string;

  /**
   * Ref to tab panel.
   */
  ref?: React.Ref<HTMLDivElement>;
}

export const TabsPanel: React.FC<TabsPanelProps> = ({ children, id, ...rest }) => {
  const focusOutlineProps = getFocusOutlineProps({ outlineOffSet: "none" });
  const state = useContext(TabListStateContext);

  return (
    <Box p="2xs" className={state && id !== state.selectedKey ? styles.isTabPanelHidden : ""}>
      <PrimitiveTabPanel
        id={id}
        {...mergeProps({ className: styles.tabPanel }, focusOutlineProps, rest)}
        shouldForceMount={true}
      >
        {children}
      </PrimitiveTabPanel>
    </Box>
  );
};
