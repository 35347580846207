import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGChevronDown: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path fill="currentColor" d="M25 8.7L27.8 11.5 16 23.3 4.2 11.5 7 8.7 16 17.7 25 8.7Z" />
  </svg>
);
SVGChevronDown = memo(SVGChevronDown);

export { SVGChevronDown };
