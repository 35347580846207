import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGChevronLeft: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fill="currentColor"
      d="M23.3284 25.0003L20.5 27.8287L8.67157 16.0003L20.5 4.17188L23.3284 7.0003L14.3284 16.0003L23.3284 25.0003Z"
    />
  </svg>
  /* eslint-enable max-len */
);
SVGChevronLeft = memo(SVGChevronLeft);

export { SVGChevronLeft };
