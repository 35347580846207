import { VerticalScrollItem } from "./VerticalScrollItem";

/**
 * Ensures that the indicator exists in the ref and returns it
 * @param itemsRef Ref containing the navigation items
 * @param index The index of the indicator
 * @returns The indicator
 */
export const ensureScrollItemByKey = (
  itemsRef: React.MutableRefObject<Map<number, VerticalScrollItem>>,
  index: number
) => {
  let current = itemsRef.current.get(index);
  if (!current) {
    current = new VerticalScrollItem();
    itemsRef.current.set(index, current);
  }
  return current;
};

/**
 * Gets the first and last visible indexes
 * @param itemsRef Ref containing the scroll navigation items
 * @param hiddenSlideIndexes Set containing the hidden slide indexes
 * @returns The first and last visible indexes or -1 if none are visible
 */
export const getVisibleIndexes = (
  itemsRef: React.MutableRefObject<Map<number, VerticalScrollItem>>,
  hiddenSlideIndexes: Set<number>
) => {
  const entries = Array.from(itemsRef.current.keys()).sort();
  const first = entries.find((index) => !hiddenSlideIndexes.has(index)) ?? -1;
  const last = entries.reverse().find((index) => !hiddenSlideIndexes.has(index)) ?? -1;

  return [first, last] as const;
};
