import cn from "classnames";
import { Children, isValidElement } from "react";
import {
  GroupedChildrenProps,
  WithGroupedChildrenComponent,
  withGroupedChildren,
} from "react-grouped-children";

import { Layers } from "@/components/Layers/Layers";
import { Oriented } from "@/components/Oriented/Oriented";
import { TooltipIconColor, TooltipWithIcon } from "@/components/TooltipWithIcon/TooltipWithIcon";

import styles from "./ImageWithToolTip.module.scss";
import {
  getCustomCssProps,
  getToolTipPosition,
  TooltipLocationType,
} from "./ImageWithToolTip.utils";

type ImageWithToolTipProps = {
  /**
   * Set className for imageWithTooltip, default is empty.
   */
  className?: string;

  /**
   * Set className for imageWithTooltip, default is empty.
   */
  classNameLayerContainer?: string;

  /**
   * Use to set position for tooltip. Available options are topRight and bottomRight
   */
  toolTipPosition?: TooltipLocationType;

  /**
   * Use to set tooltip description for landscape image
   */
  landscapeImageToolTipDescription?: string | React.ReactNode;

  /**
   * Use to set tooltip description for portrait image
   */
  portraitImageToolTipDescription?: string | React.ReactNode;
  /**
   * Use to display all children and ignore orientation rules.
   */

  showAll?: boolean;
  /**
   * Use to set aspect ratio for images
   */

  aspectRatio?: number;
  /**
   * For those do not need to support two images or if the containing element does not
   * have defined size either through fixed  or relative dimensions, we just set it as
   * normal container because the container query won't have any dimensions to query against.
   */
  isNormalContainer?: boolean;

  /**
   * Tooltip re-size on screen change
   */
  isPopupFixed?: boolean;
};

const ImageWithToolTipInternal = ({
  className,
  landscapeImage,
  portraitImage,
  toolTipPosition = "bottomRight",
  landscapeImageToolTipDescription,
  portraitImageToolTipDescription,
  showAll,
  aspectRatio = 0,
  isNormalContainer = false,
  isPopupFixed,
  classNameLayerContainer,
  ...rest
}: GroupedChildrenProps<typeof childrenSpec> & ImageWithToolTipProps) => {
  const imageLandscape = Children.toArray(landscapeImage).filter(isValidElement)[0];
  const imagePortrait = Children.toArray(portraitImage).filter(isValidElement)[0];

  if (!imageLandscape && !imagePortrait) {
    return null;
  }

  const customCSSProperties = getCustomCssProps({ aspectRatio, ...rest });
  const hasOnlyOne = imagePortrait ? !imageLandscape : imageLandscape;

  return (
    <div
      style={customCSSProperties}
      className={cn(
        styles.imageWithTooltip,
        {
          [styles.aspect]: typeof aspectRatio === "number" && aspectRatio > 0,
          [styles.normalContainerType]: isNormalContainer,
        },
        className
      )}
    >
      <Layers>
        <div className={classNameLayerContainer} style={customCSSProperties}>
          <Oriented defaultOrientation="portrait" showAll={!!hasOnlyOne || showAll}>
            {imagePortrait && <Oriented.Portrait>{imagePortrait}</Oriented.Portrait>}
            {imageLandscape && <Oriented.Landscape>{imageLandscape}</Oriented.Landscape>}
          </Oriented>
        </div>

        <div className={cn(styles.toolTipContainer, toolTipPosition && styles[toolTipPosition])}>
          <TooltipWithIcon
            isPopupFixed={isPopupFixed}
            showPopupOnFocus
            iconColor={TooltipIconColor.ACCENT}
            ariaLabel="image with tooltip"
            pointerPosition={getToolTipPosition(toolTipPosition)}
          >
            {portraitImageToolTipDescription || landscapeImageToolTipDescription}
          </TooltipWithIcon>
        </div>
      </Layers>
    </div>
  );
};

const childrenSpec = {
  LandscapeImage: null,
  PortraitImage: null,
} as const;

const Component: WithGroupedChildrenComponent<typeof childrenSpec, ImageWithToolTipProps> =
  withGroupedChildren({ childrenSpec })(ImageWithToolTipInternal);

/**
 * A helper component which allows content and developers to present images with additional overlay features like tooltips or text. There is a requirement that images on Lexus.com must have the ability for tooltip disclaimer display.
 *
 * ## Usage
 *
 * ```
 * <ImageWithToolTip
    landscapeImageToolTipDescription={landscapeImageToolTipDescription} portraitImageToolTipDescription={portraitImageToolTipDescription} {...args}
  >
      {!noPortrait && (
        <ImageWithToolTip.PortraitImage><img alt="portrait" src={portraitImage} /></ImageWithToolTip.PortraitImage>
      )}
      {!noLandscape && (
        <ImageWithToolTip.LandscapeImage><img alt="Landscape" src={landscapeImage} /></ImageWithToolTip.LandscapeImage>
      )}
  </ImageWithToolTip>
 * ```
 */
export const ImageWithToolTip = Component;
