import { memo } from "react";

import styles from "./FocusCatcher.module.scss";

interface FocusCatcherProps {
  /**
   * Callback when focus is caught.
   */
  onFocus?: React.EventHandler<React.FocusEvent<HTMLButtonElement>>;

  /**
   * Callback when focus is lost.
   */
  onBlur?: React.EventHandler<React.FocusEvent<HTMLButtonElement>>;
}

/**
 * FocusCatcher component is an invisible element used to catch focus.
 * It can be used to catch if user tries to leave certain area.
 *
 * ## Usage
 *
 * Place component in the beginning and the end of the area you want to catch focus.
 * ```tsx
 * <Popover>
 *   <FocusCatcher onFocus={() => console.log("Focus caught at the start"); closePopup() } />
 *   Some other focusable content
 *   <FocusCatcher onFocus={() => console.log("Focus caught at the end"); closePopup() } />
 * </Popover>
 * ```
 */
export const FocusCatcher: React.FC<FocusCatcherProps> = memo(function FocusCatcher({
  onFocus,
  onBlur,
}) {
  return (
    <button
      className={styles.focusCatcher}
      data-is-lk-focus-catcher="true"
      onFocus={onFocus}
      onBlur={onBlur}
      aria-hidden="true"
    />
  );
});
