import cn from "classnames";
import { type CSSProperties } from "react";

import styles from "./InputBorder.module.scss";

export const borderRadiusMap = {
  small: "--ld-c-input-border-border-radius-default",
  medium: "--ld-c-input-border-border-radius-medium",
  "extra-medium": "--ld-c-input-border-border-radius-extra-medium",
  large: "--ld-c-input-border-border-radius-large",
  "extra-large": "--ld-c-input-border-border-radius-extra-large",
  rounded: "--ld-c-input-border-border-radius-rounded",
};

export type BorderRadiusScale = keyof typeof borderRadiusMap;
export const STATE_VARIANTS = ["invalid", "success", "selected"] as const;
export type StateVariant = (typeof STATE_VARIANTS)[number];

export const mapBorderRadiusToCSSVar = (borderRadius: BorderRadiusScale): string => {
  return borderRadiusMap[borderRadius];
};

export interface InputBorderStyleProps {
  borderRadius?: BorderRadiusScale;
  isHoverStateDisabled?: boolean;
  state?: StateVariant;
}

type InputBorderStateProps = {
  isInvalid?: boolean;
  isSelected?: boolean;
  isDefault?: boolean;
};

export const getInputBorderState = ({
  isInvalid,
  isSelected,
  isDefault,
}: InputBorderStateProps): StateVariant | undefined => {
  if (isInvalid) return "invalid";
  if (isSelected) return "selected";
  if (isDefault) return undefined;
  return "success";
};

/**
 * Headless component for `InputBorder` component, which is used to handle different data state styles for `InputBorder`.
 */

export const getInputBorderStyles = ({
  borderRadius = "small",
  isHoverStateDisabled,
  state,
}: InputBorderStyleProps) => {
  const borderRadiusVar = mapBorderRadiusToCSSVar(borderRadius);

  const borderStyles: Record<StateVariant, string> = {
    success: styles.success,
    invalid: styles.invalid,
    selected: styles.selected,
  };

  const inputBorderStyles: CSSProperties = {
    borderRadius: `var(${borderRadiusVar})`,
  };

  return {
    inputBorderStyles,
    className: cn(
      isHoverStateDisabled ? "" : styles.inputBorderHoverState,
      state && borderStyles[state]
    ),
  };
};
