import cn from "classnames";
import { useRef } from "react";

import { getBorderRadiusProps } from "@/components/BorderRadius/BorderRadius";
import { getBoxProps } from "@/components/Box/Box";
import { Stack } from "@/components/Stack/Stack";
import { useTransitionControl } from "@/contexts/TransitionContext";
import { useScrollIntoView } from "@/hooks/useScrollIntoView";
import { mergeProps } from "@/utils/reactExtensions";

import { useProgress } from "./DotStack.hooks";
import styles from "./DotStack.module.scss";

export const PAUSED_TRANSITION_DURATION = 200;
export type CellVerticalAlign = "top" | "center" | "bottom";

export type DotStackProps = React.HTMLAttributes<HTMLDivElement> & {
  /*
   * The total number of dots.
   */
  dotCount: number;

  /*
   * The selected dot.
   */
  selectedDot?: number;

  /*
   * Whether the dot stack has padding.
   */
  hasPadding?: boolean;
};

/**
 * `DotStack` component that displays a stack of dots with a selected dot.
 * If there is no enough space for the dots and if some of them are scrolled to right or left,
 * there will be an overlay on the left and right side of the dot stack, and the selected dot will be centered.
 *
 * `DotStack` component needs to be wrapped with `TransitionControlProvider` in the hierarchy appropriately to control the transition duration and pause the transition.
 *
 * ## Usage
 *
 * ```tsx
 * <TransitionControlProvider transitionDuration={7000} isTransitionPaused={false}>
 *   <DotStack dotCount={5} selectedDot={2} hasPadding={true} />
 * </TransitionControlProvider>
 * ```
 */
export const DotStack: React.FC<DotStackProps> = ({
  dotCount,
  selectedDot = 1,
  hasPadding = true,
}) => {
  const { transitionDuration, isTransitionPaused } = useTransitionControl() || {
    isTransitionPaused: true,
    transitionDuration: PAUSED_TRANSITION_DURATION,
  };
  const selectedDotRef = useRef<HTMLDivElement>(null);

  const progress = useProgress({
    transitionDuration,
    isTransitionPaused,
    selectedDot,
  });

  useScrollIntoView({ ref: selectedDotRef, dependency: selectedDot });

  return (
    <div className={styles.dotStackContainer}>
      <div className={styles.leftOverlay} />
      <Stack
        spacing="3xs"
        {...mergeProps(getBoxProps({ px: hasPadding ? "xs" : "none" }), {
          className: styles.dotStack,
        })}
      >
        {Array.from({ length: dotCount }).map((_, index) => (
          <div
            ref={index === selectedDot - 1 ? selectedDotRef : null}
            role="status"
            key={index}
            {...mergeProps(getBorderRadiusProps("rounded"), {
              className: cn(styles.dot, {
                [styles.selectedDot]: index === selectedDot - 1,
              }),
            })}
          >
            <div
              className={cn(styles.selectedFakeDot, {
                [styles.shrinking]: index !== selectedDot - 1,
              })}
              style={{
                width: index === selectedDot - 1 ? `${progress}%` : "0",
              }}
            />
          </div>
        ))}
      </Stack>
      <div className={styles.rightOverlay} />
    </div>
  );
};
