import React, { useEffect } from "react";
import { postRefreshSuccessMessage, postRefreshFailureMessage } from "Helpers/routes";
import { AccountManager } from "lexus-style-guide/Components/Account/Providers/AccountManager";

const Refresh: React.FC = () => {
    useEffect(() => {
        const fetchGuest = async () => {
            const guest = await AccountManager.current.getGuestAsync(false);
            if (guest) {
                postRefreshSuccessMessage(guest);
                return;
            }

            postRefreshFailureMessage();
        };

        try {
            fetchGuest();
        } catch (e) {
            postRefreshFailureMessage();
            throw e;
        }
    }, []);

    return null;
};

export { Refresh };
