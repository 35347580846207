import { TooltipPointerPosition } from "../Tooltip/TooltipPopup/TooltipPopup";
import { ContainerCustomProps } from "../types";

export enum TooltipLocation {
  TOP_RIGHT = "topRight",
  BOTTOM_RIGHT = "bottomRight",
  BOTTOM_LEFT = "bottomLeft",
}

export type TooltipLocationType = `${TooltipLocation}`;

export const getCustomCssProps = ({ aspectRatio }: ContainerCustomProps): React.CSSProperties => ({
  ["--image-with-tooltip-aspect-ratio"]:
    typeof aspectRatio === "number" && aspectRatio > 0 ? aspectRatio : undefined,
});

export const getToolTipPosition = (
  toolTipPosition: TooltipLocationType
): TooltipPointerPosition => {
  const positionMap: Record<TooltipLocationType, TooltipPointerPosition> = {
    topRight: "middle-top-right",
    bottomRight: "middle-bottom-right",
    bottomLeft: "middle-bottom-left",
  };

  return positionMap[toolTipPosition] || "middle-top-right";
};
