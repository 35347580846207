import cn from "classnames";
import { Tab as PrimitiveTab } from "react-aria-components";

import { getBorderRadiusProps } from "@/components/BorderRadius/BorderRadius";
import { getFocusOutlineProps } from "@/components/FocusOutline/FocusOutline";
import { Typography } from "@/components/Typography/Typography";
import { mergeProps } from "@/utils/reactExtensions";

import { DisableHandler, getTabsTabProps } from "./HeadlessComponents";
import styles from "./Tabs.module.scss";
import { TabOrientations, useCustomTabsContext } from "./Tabs.utils";

export interface TabsTabProps {
  children: React.ReactNode;

  /**
   * The unique id for each Tab, which is used to connect Tab and Tab Panel.
   */
  id: string;

  /**
   * Whether the Tab is disabled or not.
   */
  isDisabled?: boolean;
}

const ProcessedFocusOutline: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { variant, orientation } = useCustomTabsContext();
  const isDefaultTab = variant === "default";

  return (
    <div
      {...mergeProps(
        getFocusOutlineProps({
          outlineOffSet: isDefaultTab ? "smallest" : "default",
        }),
        getBorderRadiusProps(isDefaultTab ? "none" : "small"),
        {
          className: cn(styles.processedFocusOutline, {
            [styles.truncation]: orientation === TabOrientations.VERTICAL,
          }),
        }
      )}
    >
      {children}
    </div>
  );
};

export const TabsTab: React.FC<TabsTabProps> = ({ children, id, isDisabled = false }) => {
  const { variant, orientation } = useCustomTabsContext();
  const TabsTabProps = getTabsTabProps({ orientation, variant });

  return (
    <PrimitiveTab id={id} isDisabled={isDisabled} {...TabsTabProps}>
      <DisableHandler id={id} isDisabled={isDisabled} />
      <ProcessedFocusOutline>
        <Typography variant="l1">{children}</Typography>
      </ProcessedFocusOutline>
    </PrimitiveTab>
  );
};
