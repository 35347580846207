import { Guest } from "lexus-style-guide/Components/Account/Guest";
import { Location } from "react-router-dom";
import { identity, takeWhile } from "lodash";
import { compose } from "redux";
import { getOauth2CallbackUrl } from "../apiHref";

export enum RouteSection {
    LexusMainSite = "//www.lexus.com.au",
    TrailingWildcard = "*",
    SignIn = "signin",
    SignOut = "sign-out",
    SilentSignOut = "silentsignout",
    Refresh = "refresh",
    Register = "register",
    TermsAndConditions = "terms-and-conditions",
    TermsOfUse = "terms-of-use",
    PrivacyPolicy = "privacy-policy",
    ForgotPassword = "forgot-password",
    Dashboard = "dashboard",
    Encore = "encore",
    Vehicle = "vehicle",
    Settings = "settings",
    GuestRegistrationError = "guest-registration-error",
    MagicLinkError = "magic-link-error",
    LinkExpiredError = "link-expired-error",
    Email = "email",
    Success = "success",
    Add = "add",
    Update = "update",
    Confirm = "confirm",
    Error = "error",
    FuelDiscount = "fuel-offer",
    Communications = "communications",
    PaymentMethods = "payment-methods",
    Valet = "valet",
    WheresMyVin = "where-is-my-vin",
    Redemptions = "redemptions",
    Locations = "locations",
    HowDoesItWork = "how-does-it-work",
    Providers = "providers",
    ChangePassword = "change-password",
    ProviderParameter = ":provider",
    LocationParameter = ":location",
    OnDemand = "on-demand",
    WhatsOnDemand = "whats-on-demand",
    Bookings = "bookings",
    BookingReferenceParameter = ":bookingReference",
    Cancel = "cancel",
    FAQ = "faq",
    FeesAndCharges = "fees-and-charges",
    Driver = "driver",
    Licence = "licence",
    TimeDate = "time-date",
    Vehicles = "vehicles",
    VehicleParameter = ":vehicle",
    Complete = "complete",
    Notify = "notify",
    OnDemandTsAndCs = "on-demand-terms-and-conditions",
    ValetRedemptionBalanceError = "valet-redemption-balance-error",
    OnDemandRedemptionBalanceError = "on-demand-redemption-balance-error",
    OnDemandError = "on-demand-error",
    OnDemandVehicleAlreadyBookedError = "on-demand-vehicle-already-booked-error",
    NonRecoverableError = "error",
    MaintenanceError = "maintenance",
    CancelBookingError = "cancel-booking-error",
    BookVehicleClearProgressError = "clear-progress-error",
    ToastParam = ":toastParam",
    SelectPayment = "select-payment",
    AddPaymentCard = "add-card",
    AddPaymentCardError = "add-card-error",
    VehicleUnavailable = "vehicle-unavailable",
    DiscardUpdateBooking = "discard-update-booking",
    MobileAddPaymentCardIFrame = "mobile-add-payment-card-iframe",
    FlightDetails = "flight-details",
    TokenParamOptional = ":token?",
    EncoreRewards = "encore-rewards",
    EncoreInterest = "encore-interest",
    PlatinumBenefitsExpiring = "platinum-benefits-expiring",
    // Owners Routes
    Owners = "owners",
    Benefits = "benefits",
    VehicleBenefits = "benefits/vehicle-benefits",
    HotelPartnerships = "benefits/hotel-partnerships",
    Jackalope = "#jackalope",
    Raes = "#raes",
    OneOnly = "#one-only",
    CappedPriceServicing = "#capped-price-servicing",
    ServiceLoanCar = "#service-loan-car",
    Drivecare = "#drivecare",
    // Contact
    Contact = "contact",
    FindADealer = "find-a-dealer",
    // Account Setting
    AccountSettings = "account-settings",
    AccountDetails = "account-details",
    AccountRequestDelete = "account-request-delete",
    EditDetails = "edit-details",
    // Online Service Booking
    OnlineServiceBooking = "online-service-booking",
    OnlineServiceBookingBack = "online-service-booking-back",
    SearchForADealer = "search-for-a-dealer",
    WhatsBookAService = "whats-book-a-service",
    //Charging Location Finder
    ChargingLocationFinder = "charging-location-finder",
    //PWA Opening Screen
    Welcome = "welcome",
}

export const baseRoutes = [
    RouteSection.SignIn,
    RouteSection.SignOut,
    RouteSection.Register,
    RouteSection.ForgotPassword,
    RouteSection.ChangePassword,
    RouteSection.Dashboard,
    RouteSection.Encore,
    RouteSection.Vehicle,
    RouteSection.PlatinumBenefitsExpiring,
    RouteSection.AccountSettings,
    RouteSection.Settings,
    RouteSection.GuestRegistrationError,
    RouteSection.LinkExpiredError,
    RouteSection.Refresh,
    RouteSection.SilentSignOut,
];

export const valetTabs = {
    left: RouteSection.Redemptions,
    right: RouteSection.Locations,
};

export const routeString = (...route: RouteSection[]): string => `/${route.join("/")}`;

export const routeStringFromBase =
    (base: string): { (...sections: RouteSection[]): string } =>
    (...sections: RouteSection[]) =>
        routeString(...(base.split("/").filter(identity) as RouteSection[]), ...sections);

export const valetStateTab = (location: Location<{ tab?: string }>): RouteSection =>
    location.state && location.state.tab === valetTabs.right ? valetTabs.right : valetTabs.left;

type ValetModalRoute = {
    pathname: string;
    state: {
        tab: RouteSection;
    };
};

export const valetModalRoute = (location: Location<{ tab?: string }>, path: string): ValetModalRoute => ({
    pathname: path,
    state: { tab: valetStateTab(location) },
});

export const defaultLoggedInRoute = routeString(RouteSection.Dashboard);

// The first and last elements of the array can be empty strings if
// there is leading or trailing slashes in the url, remove the empty
// strings.
export const getParentRoute = (route: string): string => {
    const urlSegments = route.split("/").filter(elem => elem);
    urlSegments.pop();
    const nextUrl = routeString(...(urlSegments as RouteSection[]));
    return nextUrl;
};

const takeRouteSectionsUntilToast = (sections: RouteSection[]) =>
    takeWhile(sections, (str: string) => str !== RouteSection.Notify) as RouteSection[];

const getRouteSections = (path: string) => path.split("/").filter(identity) as RouteSection[];

const getRouteSectionsWithoutToast = compose(takeRouteSectionsUntilToast, getRouteSections);

export const closeToastUrl = (path: string): string => routeString(...getRouteSectionsWithoutToast(path));

export const toastPath = (toastName: RouteSection, ...baseArg: RouteSection[]): string => {
    const base = baseArg.length > 0 ? baseArg : [RouteSection.TrailingWildcard];
    return routeString(...base, RouteSection.Notify, toastName);
};

export const toastUrlFromPath = (toastName: RouteSection, path: string, toastParam?: string): string =>
    toastParam
        ? routeString(...getRouteSectionsWithoutToast(path), RouteSection.Notify, toastName, toastParam as RouteSection)
        : routeString(...getRouteSectionsWithoutToast(path), RouteSection.Notify, toastName);

export const toastUrl = (toastName: RouteSection, ...base: RouteSection[]): string =>
    routeString(...takeRouteSectionsUntilToast(base), RouteSection.Notify, toastName);

export const makeBookingRoute = routeString(RouteSection.Encore, RouteSection.OnDemand, RouteSection.Bookings);

export const isNativeAppRequest = (search: string): boolean => new URLSearchParams(search).get("type") === "app";

export const isIFrameRequest = (search: string): boolean => new URLSearchParams(search).get("type") === "iframe";

export const redirectToApp = async (guest: LXS.Guest): Promise<void> => {
    const appCallbackUrl = await getOauth2CallbackUrl();
    if (typeof window !== "undefined") {
        window.location.href = `${appCallbackUrl}?id_token=${guest.idToken}&refresh_token=${guest.refreshToken}&scope=tmca&type=${guest.type}`;
    }
};

// TODO: Once LexusKit is important into lexus-app-spa, import
// these interfaces from lexus-kit. They live in useWindowMessage.ts
interface MessageWithType<T extends string> {
    type: T;
}

interface SuccessLogin extends MessageWithType<"login-success"> {
    message: string;
}

interface RefreshLoginSuccess extends MessageWithType<"refresh-success"> {
    message: string;
}

export const postLoginMessage = (guest: LXS.Guest): void => {
    const data: SuccessLogin = {
        type: "login-success",
        message: guest.idToken,
    };

    window.parent.postMessage(data, "*");
};

export const postRefreshSuccessMessage = (guest: Guest): void => {
    const data: RefreshLoginSuccess = {
        type: "refresh-success",
        message: guest.idToken,
    };

    window.parent.postMessage(data, "*");
};

export const postRefreshFailureMessage = (): void => {
    const data: MessageWithType<"refresh-failure"> = {
        type: "refresh-failure",
    };

    window.parent.postMessage(data, "*");
};

export const postLogoutSuccessMessage = (): void => {
    const data: MessageWithType<"logout-success"> = {
        type: "logout-success",
    };

    window.parent.postMessage(data, "*");
};

export const postLogoutFailureMessage = (): void => {
    const data: MessageWithType<"logout-failure"> = {
        type: "logout-failure",
    };

    window.parent.postMessage(data, "*");
};
