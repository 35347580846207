import { useCallback, useContext, useRef } from "react";

import { mergeProps } from "@/utils/reactExtensions";

import { SCROLLING_TRACKING_STEPS } from "./VerticalScroll.constants";
import { useHandleIntersectionChange, useHandleResize } from "./VerticalScroll.hooks";
import styles from "./VerticalScroll.module.scss";
import {
  VerticalScrollSlideResizeEvent,
  type VerticalScrollSlideIntersectionEvent,
} from "./VerticalScroll.types";
import { ensureScrollItemByKey } from "./VerticalScroll.utils";
import { VerticalScrollContext } from "./VerticalScrollContext";
import { getFocusOutlineProps } from "../FocusOutline/FocusOutline";

interface Props {
  children: React.ReactElement[];
  onScrollVisibilityChange: () => void;
}

const rangeFrom0To1 = Array.from(
  { length: SCROLLING_TRACKING_STEPS + 1 },
  (_, i) => i / SCROLLING_TRACKING_STEPS
);

export const VerticalScrollSlides: React.FC<Props> = ({ children, onScrollVisibilityChange }) => (
  <ul>
    {children.map((child, index) => (
      <SlideWrapper
        index={index}
        key={`li${child.key || index}`}
        onScrollVisibilityChange={onScrollVisibilityChange}
      >
        {child}
      </SlideWrapper>
    ))}
  </ul>
);

const SlideWrapper: React.FC<
  React.PropsWithChildren<{
    index: number;
    onScrollVisibilityChange: () => void;
  }>
> = ({ children, index, onScrollVisibilityChange }) => {
  const { itemsRef, slidesCount } = useContext(VerticalScrollContext);

  const observerRef = useRef<IntersectionObserver | null>(null);
  const resizeRef = useRef<ResizeObserver | null>(null);

  const handleIntersection = useHandleIntersectionChange(slidesCount, onScrollVisibilityChange);

  const handleResize = useHandleResize();

  const refHandler = useCallback(
    (el: HTMLLIElement) => {
      ensureScrollItemByKey(itemsRef, index).slideWrapper = el;

      if (el) {
        observerRef.current = createIntersectionObserver(handleIntersection, index);
        resizeRef.current = createResizeObserver(handleResize, index);

        observerRef.current?.observe(el);
        resizeRef.current?.observe(el);
      } else {
        observerRef.current?.disconnect();
        resizeRef.current?.disconnect();
      }
    },
    [handleIntersection, handleResize, index, itemsRef]
  );

  return (
    <li
      tabIndex={-1}
      ref={refHandler}
      data-testid="lk-vertical-scroll-slide"
      {...mergeProps(getFocusOutlineProps({ outlineOffSet: "smallest" }), {
        className: styles.slide,
      })}
    >
      {children}
    </li>
  );
};

/**
 * Creates an IntersectionObserver for the slide
 *
 * NOTE: If browser does not support IntersectionObserver it will return null
 */
const createIntersectionObserver = (
  onIntersection: (event: VerticalScrollSlideIntersectionEvent) => void,
  index: number
): IntersectionObserver | null =>
  typeof IntersectionObserver !== "undefined"
    ? new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.target instanceof HTMLLIElement) {
              const heightRatio =
                entry.boundingClientRect.height > 0
                  ? 1 - entry.intersectionRect.height / entry.boundingClientRect.height
                  : 1;
              const direction = entry.boundingClientRect.y > 0 ? -1 : 1;

              onIntersection({
                index,
                isActive: heightRatio < 0.5,
                ratio: (Math.round(heightRatio * 100) / 100) * direction,
              });
            }
          });
        },
        {
          threshold: rangeFrom0To1,
        }
      )
    : null;

/**
 * Creates an ResizeObserver for the slide
 *
 * NOTE: If browser does not support ResizeObserver it will return null
 */
const createResizeObserver = (
  onResize: (event: VerticalScrollSlideResizeEvent) => void,
  index: number
): ResizeObserver | null =>
  typeof ResizeObserver !== "undefined"
    ? new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          onResize(
            entry.borderBoxSize?.length > 0
              ? {
                  index,
                  height: entry.borderBoxSize[0].blockSize,
                  heightRatio:
                    entry.borderBoxSize[0].blockSize /
                    Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
                }
              : {
                  index,
                  height: 0,
                  heightRatio: 0,
                }
          );
        });
      })
    : null;
