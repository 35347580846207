const SUBTITLE_VARIANTS = ["s1", "s2"] as const;
const BODY_TEXT_VARIANTS = ["b1", "b2"] as const;
const CAPTION_VARIANTS = ["c1"] as const;
const DISCLAIMER_VARIANTS = ["d1"] as const;
const PRICE_VARIANTS = ["p1"] as const;
const LABEL_VARIANTS = ["l1", "l2"] as const;
const SPECIAL_TEXT_TREATMENT_VARIANTS = ["superscript", "textLink"] as const;
export const HEADING_VARIANTS = ["h1", "h2", "h3", "h4", "h5", "h6"] as const;
export const VARIANTS = [
  ...HEADING_VARIANTS,
  ...SUBTITLE_VARIANTS,
  ...BODY_TEXT_VARIANTS,
  ...CAPTION_VARIANTS,
  ...DISCLAIMER_VARIANTS,
  ...PRICE_VARIANTS,
  ...LABEL_VARIANTS,
  ...SPECIAL_TEXT_TREATMENT_VARIANTS,
] as const;
export type Variant = (typeof VARIANTS)[number];

export const HEADING_TAGS = ["h1", "h2", "h3", "h4", "h5", "h6"] as const;
export type HeadingTag = (typeof HEADING_TAGS)[number];

const TYPOGRAPHY_TAGS = ["span", "p", "div", "a", "strong", "b", "label"] as const;

export const COMPONENTS = [...HEADING_TAGS, ...TYPOGRAPHY_TAGS] as const;
export type Component = (typeof COMPONENTS)[number];

export const FONT_WEIGHTS = ["book", "regular", "bold"] as const;

type TypographyTextProps = React.PropsWithChildren<{
  className?: string;
  /**
   * The base component that will be rendered. Ie. a h1 component will render a h1 tag
   */
  component?: (typeof COMPONENTS)[number];
  /**
   * The variant class to set to the base component. Ie. a s1 variant on a p component will render a subTitle 1 in a p tag.
   */
  variant?: (typeof VARIANTS)[number];
  /**
   * Force large typography sizing, even on small devices.
   */
  isAlwaysLarge?: boolean;
  /**
   * Disables auto-formatting of special text, ie. [disclaimer]
   */
  isSpecialTextDisabled?: boolean;
  /**
   * Allow users to set any fontWeight on any screen
   */
  fontWeight?: (typeof FONT_WEIGHTS)[number];
  /*
   * Prefixes the text with a decorative line
   */
  hasSenkeiLine?: boolean;
  /*
   * Adds left indentation to the text
   */
  isIndented?: boolean;
}>;

export type TypographyLinkProps = TypographyTextProps & {
  component: "a";
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

export type TypographyProps = TypographyTextProps | TypographyLinkProps;

/**
 * Output from plugin
 */
export interface TypographyPluginResult {
  /**
   * Indicates that next plugin should not be processed
   */
  stopProcessing: boolean;

  /**
   * Result from processing (React Node(s))
   */
  result: React.ReactNode;
}

/**
 * A Typography plugin interface
 */
export type TypographyPlugin = {
  /**
   * Detect order when the plugin should be called, e.g. 0 - will be called first. If not provided
   * then plugin with order prop will be prioritized first.
   */
  order?: number;

  exec: (
    /**
     *Children of plugin calling typography element
     */
    children: React.ReactNode,
    /**
     * Component type of element which is calling plugin. Usually this will be LexusKit Typography component.
     * It is passed to plugins to avoid dependency loops.
     */
    Typography: React.ComponentType<TypographyProps>,
    /**
     * Original props which were passed to the plugin calling component
     */
    props: TypographyProps
  ) => TypographyPluginResult;
};
