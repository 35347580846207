import styles from "./SVGScrollIndicatorAnimated.module.scss";
import { SVGIconWrapper } from "../SVGIconWrapper";
import { SVGIconElementProps, SVGIconSizes } from "../types";

const SVGScrollIndicatorAnimated: React.FC<SVGIconElementProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  <SVGIconWrapper {...rest}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 76 120"
      preserveAspectRatio="xMidYMid meet"
      height={height}
      width={width}
    >
      <g fill="none" fillRule="evenodd">
        <rect
          width="70"
          height="118"
          x="1.5"
          y="1.5"
          stroke="currentColor"
          strokeWidth="3"
          rx="36"
        />
        <circle
          className={styles.scrollSvgCircle}
          cx="36.5"
          cy="31.5"
          r="4.5"
          stroke="currentColor"
        />
      </g>
    </svg>
  </SVGIconWrapper>
);

export { SVGScrollIndicatorAnimated };
