import cn from "classnames";

import { getSurfaceProps } from "@/components/Surface/Surface";
import { ThemeVariantScope } from "@/components/ThemeVariantScope/ThemeVariantScope";
import { ThemeSurfaceShade, ThemeVariant } from "@/theming/themingTypes";

import styles from "./Section.module.scss";

export type SectionPadding = "none" | "more" | "default" | "less";

export type SectionContainer = "inner" | "outer";

type SectionProps = React.PropsWithChildren<{
  /**
   * Section padding. This is automatically managed by DLS tokens
   * to switch between desktop and mobile.
   * Default: `"default"`.
   */
  p?: SectionPadding;

  /**
   * Theme variant which should be established as context.
   * Default: `"default"`.
   */
  variant?: ThemeVariant;

  /**
   * Canvas defines section background color.
   * Default: `"default"`.
   */
  canvas?: ThemeSurfaceShade;

  /**
   * If set section will provide a CSS **inline** container scope named `section`.
   * `inner` will provide a container inside the section, so it will exclude padding.
   * `outer` will provide a container outside the section, so it will include padding and
   * will be equal to 100% occupied space.
   *
   * Default: `undefined`.
   *
   * Child components may target this container to apply specific styles.
   * To use units like `cqw` in your child components you need to ensure that there is no
   * other CSS container defined between your component and Section.
   */
  container?: SectionContainer;

  /**
   * Class name(s) to customise component if required.
   */
  className?: string;
}>;

/**
 * Section component is a starting point for building page layout.
 * Section is designed to be used with Section Content Container, but
 * can be used without it.
 *
 * Section defines current contextual Variant and specifies background
 * color (canvas).
 *
 * NOTE 1: Section will apply CSS `contain` property set to `content` to
 * isolate and optimize content rendering. This will block any `absolute`
 * or `fixed` positioned elements from rendering outside of the section
 * boundaries defined by static content.
 *
 * NOTE 2: Section provides public CSS custom props which could be used
 * by child components to apply specific styles. These are:
 * --lk-c-section-padding-v - applied vertical padding
 * --lk-c-section-padding-h - applied horizontal padding
 * --lk-c-section-gap - applied gap between child elements
 */
export const Section: React.FC<SectionProps> = ({
  className,
  variant = "default",
  canvas = "default",
  p = "default",
  container,
  children,
}) => (
  <ThemeVariantScope variant={variant}>
    {(variantClassName) => (
      <section
        className={cn(
          styles.section,
          getSurfaceProps("canvas", canvas).className,
          styles[`${p}Padding`],
          variantClassName,
          className,
          { [styles[`container`]]: container === "outer" }
        )}
      >
        <div className={cn(styles.sectionBox, { [styles[`container`]]: container === "inner" })}>
          {children}
        </div>
      </section>
    )}
  </ThemeVariantScope>
);
