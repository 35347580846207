import "./App.scss";

import * as React from "react";

import {
    AuthSection,
    attemptAutoLoginThunk,
    encoreTierSelector,
    salesforceAccountIdSelector,
    setAuthSection,
} from "ReduxSlices/user";
import { useEffect, useMemo } from "react";
import { RouteSection, isNativeAppRequest, isIFrameRequest } from "Helpers/routes";
import {
    getPrimaryNavigationDataThunk,
    isIFrame as isIFrameAction,
    isNativeApp,
    loadFontsThunk,
} from "ReduxSlices/general";

import { IENotSupported } from "Pages/IENotSupported/IENotSupported";
import Routes from "../route/Routes/Routes";
import TagManager from "react-gtm-module";
import { ToastContext } from "lexus-style-guide/Components/Toast/Toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "Hooks/thunk";
import { darkTheme, GlobalStylesScope, LinkContextProvider } from "@tmca/lexus-kit/css-in-js";

const tagManagerArgs = {
    gtmId: "GTM-532XZXM",
};

TagManager.initialize(tagManagerArgs);

const SpaLink: React.FC<React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({ children, href, ...props }) => {
    return !href ? null : (
        <Link {...props} to={href}>
            {children}
        </Link>
    );
};

const App: React.FC = () => {
    const dispatch = useThunkDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const isIE = typeof window !== "undefined" && !!window.MSInputMethodContext && !!document.documentMode;
    const isIFrame = isIFrameRequest(location.search);
    const userTier = useSelector(encoreTierSelector);
    const accountId = useSelector(salesforceAccountIdSelector);

    useEffect(() => {
        if (!location.pathname.includes(RouteSection.SignOut)) {
            if (isNativeAppRequest(location.search)) {
                // Native app only uses `/signin` page and we need to show it
                dispatch(isNativeApp(true));
                dispatch(setAuthSection(AuthSection.Account));
            } else {
                if (isIFrame) {
                    dispatch(isIFrameAction(true));
                }

                dispatch(attemptAutoLoginThunk);
            }
        }
        dispatch(loadFontsThunk);
        if (!isIFrame) {
            dispatch(getPrimaryNavigationDataThunk);
        }
    }, []);

    useEffect(() => {
        const pageName = location.pathname.replace(/^\//, "");

        const sfCustomEvent = new CustomEvent("encore-spa-change", { detail: { pageName } });
        window.document.dispatchEvent(sfCustomEvent);
    }, [location]);

    useEffect(() => {
        if (userTier && accountId) {
            TagManager.dataLayer({ dataLayer: { userTier, accountId } });
        } else {
            TagManager.dataLayer({ dataLayer: { userTier: undefined, accountId: undefined } });
        }
    }, [userTier, accountId]);

    useEffect(() => {
        // Check if the URL has a trailing slash and it's not the root URL
        if (location.pathname.endsWith("/") && location.pathname !== "/") {
            // Remove the trailing slash
            navigate(location.pathname.slice(0, -1) + location.search, { replace: true });
        }
    }, [location.pathname, location.search, navigate]);

    const toastContextValue = useMemo(() => {
        const root = document.querySelector("#root");
        if (root instanceof HTMLElement) {
            return { appElement: root };
        }
        return { appElement: undefined };
    }, []);

    return !isIE ? (
        <ToastContext.Provider value={toastContextValue}>
            <LinkContextProvider linkComponent={SpaLink}>
                <GlobalStylesScope themeDefinition={darkTheme}>
                    <Routes />
                </GlobalStylesScope>
            </LinkContextProvider>
        </ToastContext.Provider>
    ) : (
        <IENotSupported />
    );
};

export default App;
