import cn from "classnames";

import { Box } from "@/components/Box/Box";

import { PrimaryNavCloseButtonBackground } from "./PrimaryNavCloseButtonBackground";
import styles from "./PrimaryNavMobilePage.module.scss";
import { PrimaryNavSearchPopover } from "./PrimaryNavSearchPopover";

type PrimaryNavMobilePageProps = React.PropsWithChildren<{
  closeButton: React.ReactNode;
  variant?: "level1" | "level2";
  headerChildren?: React.ReactNode;
  footerChildren?: React.ReactNode;
  disableFlexGrow?: boolean;
  isSearchEnabled?: boolean;
}>;

const PrimaryNavMobilePage: React.FC<PrimaryNavMobilePageProps> = ({
  closeButton,
  variant = "level1",
  children,
  headerChildren,
  footerChildren,
  disableFlexGrow,
  isSearchEnabled,
}) => {
  return (
    <div className={cn(styles.primaryNavMobilePage, { [styles.level2]: variant === "level2" })}>
      <div className={styles.closeButtonContainer}>
        <PrimaryNavCloseButtonBackground />
        {closeButton}
      </div>

      {isSearchEnabled && (
        <Box px="l" pb="l">
          <PrimaryNavSearchPopover isMobile={true} />
        </Box>
      )}

      {headerChildren && <div className={styles.header}>{headerChildren}</div>}
      <div className={styles.childrenAndFooter}>
        <div
          className={cn(styles.children, {
            [styles.childrenNoFlexGrow]: disableFlexGrow,
          })}
        >
          {children}
        </div>
        <div className={styles.footer}>{footerChildren}</div>
      </div>
    </div>
  );
};

export { PrimaryNavMobilePage };
