import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGYoutube: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    {...rest}
  >
    <path
      fill="currentColor"
      d="M20.555 3.268c.515.051 1.428.142 2.251 1.034.72.753.954 2.463.954 2.463s.24 2.009.24 4.016v1.883c0 2.01-.24 4.017-.24 4.017s-.235 1.71-.954 2.463c-.822.89-1.734.983-2.25 1.035a7.325 7.325 0 0 0-.155.017c-3.358.25-8.401.258-8.401.258s-6.24-.058-8.16-.25a5.302 5.302 0 0 0-.326-.046c-.608-.075-1.563-.194-2.32-1.014C.473 18.39.24 16.68.24 16.68S0 14.673 0 12.664v-1.883c0-2.007.24-4.016.24-4.016s.234-1.71.953-2.463c.824-.892 1.737-.983 2.252-1.034L3.6 3.25C6.957 3 11.995 3 12.005 3c0 0 5.038 0 8.396.251l.154.017ZM9.52 7.973l.001 6.973 6.484-3.474L9.52 7.973Z"
    />
  </svg>

  /* eslint-enable max-len */
);
SVGYoutube = memo(SVGYoutube);

export { SVGYoutube };
