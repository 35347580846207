import { SVGAdd } from "./SVGAdd";
import { SVGArrowDown } from "./SVGArrowDown";
import { SVGBrochure } from "./SVGBrochure";
import { SVGCalendar } from "./SVGCalendar";
import { SVGCart } from "./SVGCart";
import { SVGCheckMark } from "./SVGCheckMark";
import { SVGChevronDown } from "./SVGChevronDown";
import { SVGChevronLeft } from "./SVGChevronLeft";
import { SVGChevronRight } from "./SVGChevronRight";
import { SVGChevronUp } from "./SVGChevronUp";
import { SVGCross } from "./SVGCross";
import { SVGDialog } from "./SVGDialog";
import { SVGEncore } from "./SVGEncore";
import { SVGEncoreElectrified } from "./SVGEncoreElectrified";
import { SVGEncoreElevate } from "./SVGEncoreElevate";
import { SVGEncorePlatinum } from "./SVGEncorePlatinum";
import { SVGFacebook } from "./SVGFacebook";
import { SVGGrille } from "./SVGGrille";
import { SVGInfo } from "./SVGInfo";
import { SVGInfoCircle } from "./SVGInfoCircle";
import { SVGInstagram } from "./SVGInstagram";
import { SVGKey } from "./SVGKey";
import { SVGLocation } from "./SVGLocation";
import { SVGLocatorPin } from "./SVGLocatorPin";
import { SVGLock } from "./SVGLock";
import { SVGMail } from "./SVGMail";
import { SVGMapAnchor } from "./SVGMapAnchor";
import { SVGQuestionMark } from "./SVGQuestionMark";
import { SVGSearch } from "./SVGSearch";
import { SVGSearchThin } from "./SVGSearchThin";
import { SVGService } from "./SVGService";
import { SVGSort } from "./SVGSort";
import { SVGSteeringWheel } from "./SVGSteeringWheel";
import { SVGSubtract } from "./SVGSubtract";
import { SVGTelephone } from "./SVGTelephone";
import { SVGTime } from "./SVGTime";
import { SVGVideoPause } from "./SVGVideoPause";
import { SVGVideoPlay } from "./SVGVideoPlay";
import { SVGWarning } from "./SVGWarning";
import { SVGYoutube } from "./SVGYoutube";
import { SVGStaticProps } from "../types";

// https://toyotaau.atlassian.net/wiki/spaces/DIG/pages/2863301321/Icons
// Please follow the guide above to add a new icon. :)
export const SVGSelector = {
  add: SVGAdd,
  arrowDown: SVGArrowDown,
  brochure: SVGBrochure,
  calendar: SVGCalendar,
  checkMark: SVGCheckMark,
  chevronDown: SVGChevronDown,
  chevronLeft: SVGChevronLeft,
  chevronRight: SVGChevronRight,
  chevronUp: SVGChevronUp,
  cross: SVGCross,
  dialog: SVGDialog,
  grille: SVGGrille,
  infoCircle: SVGInfoCircle,
  info: SVGInfo,
  key: SVGKey,
  locatorPin: SVGLocatorPin,
  location: SVGLocation,
  steeringWheel: SVGSteeringWheel,
  subtract: SVGSubtract,
  mapAnchor: SVGMapAnchor,
  mail: SVGMail,
  questionMark: SVGQuestionMark,
  telephone: SVGTelephone,
  time: SVGTime,
  facebook: SVGFacebook,
  instagram: SVGInstagram,
  youtube: SVGYoutube,
  warning: SVGWarning,
  search: SVGSearch,
  searchThin: SVGSearchThin,
  service: SVGService,
  lock: SVGLock,
  cart: SVGCart,
  encore: SVGEncore,
  encoreElevate: SVGEncoreElevate,
  encorePlatinum: SVGEncorePlatinum,
  encoreElectrified: SVGEncoreElectrified,
  sort: SVGSort,
  videoPause: SVGVideoPause,
  videoPlay: SVGVideoPlay,
} as const;

export type AvailableIcons = keyof typeof SVGSelector;

export function isValidIcon(icon?: string): icon is AvailableIcons {
  return icon !== undefined && Object.keys(SVGSelector).includes(icon);
}

export const iconRenderer = (icon?: AvailableIcons | string, props?: SVGStaticProps) => {
  if (!isValidIcon(icon)) return null;
  const SvgComponent = SVGSelector[icon];
  return <SvgComponent {...props} />;
};
