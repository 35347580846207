import { useEffect, useRef, useState } from "react";

const INITIAL_SELECTED_DOT_PROGRESS_RATE = 20;
const FINAL_SELECTED_DOT_PROGRESS_RATE = 100;

interface UseProgressProps {
  transitionDuration: number;
  isTransitionPaused: boolean;
  selectedDot: number;
}

/**
 * Custom hook to manage progress state and timer logic.
 *
 * @param {UseProgressProps} props - The properties for the hook.
 * @param {number} props.transitionDuration - The duration of the transition.
 * @param {boolean} props.isTransitionPaused - Whether the transition is paused.
 * @param {number} props.selectedDot - The index of the selected dot.
 * @returns {number} The current progress value.
 */
export const useProgress = ({
  transitionDuration,
  isTransitionPaused,
  selectedDot,
}: UseProgressProps): number => {
  const [progress, setProgress] = useState(INITIAL_SELECTED_DOT_PROGRESS_RATE);
  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    if (isTransitionPaused) {
      clearTimeout(timerRef.current);
      return;
    }

    timerRef.current = setTimeout(() => {
      setProgress((prev) => prev + 1);
    }, transitionDuration / (FINAL_SELECTED_DOT_PROGRESS_RATE - INITIAL_SELECTED_DOT_PROGRESS_RATE));

    return () => clearTimeout(timerRef.current);
  }, [progress, transitionDuration, isTransitionPaused]);

  useEffect(() => {
    isTransitionPaused
      ? setProgress(FINAL_SELECTED_DOT_PROGRESS_RATE)
      : setProgress(INITIAL_SELECTED_DOT_PROGRESS_RATE);
  }, [selectedDot, isTransitionPaused]);

  return progress;
};
