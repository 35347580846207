import "./RegistrationForm.scss";

import { AuthenticationError, AuthenticationErrorType } from "../Errors/AuthenticationError";
import {
    PasswordValidator,
    isEmailValid,
    isNameValid,
    isNameLengthOk,
    isNameCharactersOk,
    phoneNumberPattern,
    usePasswordCheck,
} from "../../../utilities/validations";
import React, { memo, useCallback, useEffect, useState } from "react";

import { AccountToast } from "../SharedComponents/AccountToast";
import { Button } from "../../../buttons/Button";
import { MobileNumberField } from "../../Form/MobileNumberField/MobileNumberField";
import { PasswordValidationBlock } from "../SharedComponents/PasswordValidationBlock";
import { TextField } from "../../Form";
import { createBemFn } from "../../../utilities/bem";

const bem = createBemFn("lxs-registration-form");

const strings = {
    requiredField: "This field is required",
    improperName: "Please use a valid name",
    longName: "Name must not exceed 40 characters. Please try again.",
    invalidChar: "Invalid characters. Please try again.",
    improperEmail: "Please use a valid email address",
    loginError: "Wrong email and password combination",
    genericErrorTitle: "Something went wrong",
    genericErrorMessage: "We can't contact our servers right now. Please try signing in later.",
    buttonText: "Okay",
    phoneNotUnique: "Mobile number already exists",
    phoneInvalid: "This phone number is invalid. Please enter a new number and try again.",
    emailNotUnique: "The email already exists. Please try to ",
    emailInvalid: "This email is invalid. Please enter a new email and try again.",
    // eslint-disable-next-line quotes
    improperPassword: 'Your password must not include special characters \\ or "',
} as const;

type AnyConstString = typeof strings[keyof typeof strings];

interface RegistrationFormProps {
    onSubmit: (email: string, password: string, phoneNumber: string, firstName: string, lastName: string) => void;
    isError?: boolean;
    error?: Error;
    setError: (err?: Error) => void;
    onSignInRequired?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    signInHref?: string;
    initialFirstName?: string;
    initialLastName?: string;
    initialEmail?: string;
    initialMobile?: string;
    lockKnownFields?: boolean;
    guestEmailRef?: React.MutableRefObject<string | undefined>;
}

interface ValidTextProps {
    label: string;
    value: string;
    setValue: (value: string) => void;
    validator: (value: string) => AnyConstString | undefined;
    disabled?: boolean;
}

interface ValidTextWithErrorProps extends ValidTextProps {
    error?: React.ReactNode;
    setError: React.Dispatch<React.SetStateAction<React.ReactNode | undefined>>;
}

const getNameError = (
    name: string,
): undefined | typeof strings.improperName | typeof strings.invalidChar | typeof strings.longName =>
    !name
        ? strings.improperName
        : !isNameCharactersOk(name)
        ? strings.invalidChar
        : !isNameLengthOk(name)
        ? strings.longName
        : undefined;

const getEmailError = (email: string): undefined | typeof strings.improperEmail =>
    !isEmailValid(email) ? strings.improperEmail : undefined;

const ValidTextWithError: React.FC<ValidTextWithErrorProps> = memo(
    ({ label, value, setValue, validator, error, setError, disabled }) => {
        const handleBlur = () => setError(!value ? strings.requiredField : validator(value));
        const handleFocus = () => setError(undefined);

        return (
            <TextField
                label={label}
                value={value}
                isError={!!error}
                errorText={error}
                onChange={setValue}
                disabled={disabled}
                className={bem("text-field")}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
        );
    },
);

const ValidText: React.FC<ValidTextProps> = memo(({ label, value, setValue, validator, disabled }) => {
    const [errorText, setErrorText] = useState<React.ReactNode>();

    return (
        <ValidTextWithError
            label={label}
            value={value}
            setValue={setValue}
            validator={validator}
            error={errorText}
            setError={setErrorText}
            disabled={disabled}
        />
    );
});

interface PasswordBlockProps {
    password: PasswordValidator;
    setPassword: React.Dispatch<React.SetStateAction<string>>;
}

const PasswordBlock: React.FC<PasswordBlockProps> = memo(({ password, setPassword }) => {
    const [passwordError, setPasswordError] = useState<boolean>();
    const passwordFocus = () => !password.isPasswordValid && setPasswordError(false);
    const passwordBlur = () => !password.isPasswordValid && setPasswordError(true);
    const invalidCharsError = !password.isNoForbiddenCharacterOk ? strings.improperPassword : undefined;
    const isError = !!(passwordError || invalidCharsError);

    return (
        <>
            <TextField
                label="set password*"
                type="password"
                value={password.password}
                onChange={setPassword}
                isValid={password.isPasswordValid}
                isError={isError}
                errorText={invalidCharsError}
                onFocus={passwordFocus}
                onBlur={passwordBlur}
                showSuccessTick={true}
                className={bem("text-field")}
            />
            <PasswordValidationBlock validator={password} />
        </>
    );
});

const nonDigitRegex = /[^0-9]/g;
const filterPhoneNumber = (value?: string | null) => value?.replace(nonDigitRegex, "");

const RegistrationForm: React.FC<RegistrationFormProps> = ({
    onSubmit,
    error,
    setError,
    onSignInRequired,
    signInHref,
    initialFirstName,
    initialLastName,
    initialEmail,
    initialMobile,
    lockKnownFields,
    guestEmailRef,
}) => {
    const [firstName, setFirstName] = useState(initialFirstName || "");
    const [lastName, setLastName] = useState(initialLastName || "");
    const [password, setPassword] = usePasswordCheck("");
    const [mobile, setMobile] = useState(filterPhoneNumber(initialMobile) || "");
    const [mobileError, setMobileError] = useState<typeof strings["phoneNotUnique" | "phoneInvalid"]>();
    const [mobileDisabled, setMobileDisabled] = useState(lockKnownFields && !!initialMobile);
    const [email, setEmail] = useState(initialEmail || guestEmailRef?.current || "");
    const [emailError, setEmailError] = useState<React.ReactNode>();

    const errorWillBeHandled =
        error instanceof AuthenticationError &&
        error.hasAnyType([
            AuthenticationErrorType.PhoneNotUnique,
            AuthenticationErrorType.EmailNotUnique,
            AuthenticationErrorType.EmailInvalid,
            AuthenticationErrorType.PhoneInvalid,
        ]);

    useEffect(() => {
        if (error instanceof AuthenticationError) {
            if (error.hasType(AuthenticationErrorType.PhoneNotUnique)) {
                setMobileError(strings.phoneNotUnique);
                setMobileDisabled(false);
            }
            if (error.hasType(AuthenticationErrorType.EmailNotUnique)) {
                setEmailError(
                    <>
                        {strings.emailNotUnique}
                        <a className={bem("header-link")} href={signInHref} onClick={onSignInRequired}>
                            login
                        </a>
                    </>,
                );
            }
            if (error.hasType(AuthenticationErrorType.EmailInvalid)) {
                setEmailError(strings.emailInvalid);
            }
            if (error.hasType(AuthenticationErrorType.PhoneInvalid)) {
                setMobileError(strings.phoneInvalid);
            }
        }
    }, [error]);

    const submitDisabled =
        !phoneNumberPattern.test(mobile) ||
        !password.isPasswordValid ||
        !isNameValid(firstName) ||
        !isNameValid(lastName) ||
        !isEmailValid(email);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        guestEmailRef && (guestEmailRef.current = email);
        onSubmit(email, password.password, mobile, firstName, lastName);
    };

    const handleToastOnClick = useCallback(() => setError(undefined), []);

    const handlePhoneNumberChange = useCallback(({ value }: { value: string }) => {
        setMobile(value);
    }, []);

    const handleSetEmail = useCallback((email: string) => {
        guestEmailRef && (guestEmailRef.current = isEmailValid(email) ? email : undefined);
        setEmail(email);
    }, []);

    return (
        <form className={bem()} onSubmit={handleSubmit}>
            <h2 className={bem("title", "no-senkei")}>Registration</h2>
            {(signInHref || onSignInRequired) && (
                <p className={bem("header-text")}>
                    Already have a Lexus account?&nbsp;
                    <a className={bem("header-link")} href={signInHref || "#"} onClick={onSignInRequired}>
                        Log in
                    </a>
                </p>
            )}
            <div className={bem("name")}>
                <ValidText
                    label={"first name*"}
                    value={firstName}
                    setValue={setFirstName}
                    validator={getNameError}
                    disabled={lockKnownFields && !!initialFirstName}
                />
                <ValidText
                    label={"last name*"}
                    value={lastName}
                    setValue={setLastName}
                    validator={getNameError}
                    disabled={lockKnownFields && !!initialLastName}
                />
            </div>
            <ValidTextWithError
                label={"email*"}
                value={email}
                error={emailError}
                setValue={handleSetEmail}
                setError={setEmailError}
                validator={getEmailError}
                disabled={lockKnownFields && !!initialEmail}
            />
            <MobileNumberField
                mobileNumber={mobile}
                setPhoneInput={handlePhoneNumberChange}
                className={bem("text-field")}
                externalError={mobileError}
                disabled={mobileDisabled}
            />
            <PasswordBlock password={password} setPassword={setPassword} />
            {error && !errorWillBeHandled && (
                <AccountToast
                    title={strings.genericErrorTitle}
                    message={strings.genericErrorMessage}
                    isOpen={!!error}
                    onClose={handleToastOnClick}
                    buttonText={strings.buttonText}
                />
            )}
            <Button className={bem("button")} type="submit" disabled={submitDisabled}>
                Continue
            </Button>
        </form>
    );
};

export { RegistrationForm };
