import cn from "classnames";

import { isInjectable } from "@/utils/typeGuards";

import styles from "./SVGInfoCircleAnimated.module.scss";
import { SVGInfoCircle } from "../static/SVGInfoCircle";
import { SVGIconWrapper } from "../SVGIconWrapper";
import { SVGIconElementProps, SVGIconSizes } from "../types";

const SVGInfoCircleAnimated: React.FC<SVGIconElementProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => {
  if (isInjectable(styles)) {
    styles.inject();
  }
  return (
    <SVGIconWrapper
      {...rest}
      className={styles.infoCircleAnimated}
      activeClassName={cn(styles.active, rest.activeClassName)}
    >
      <SVGInfoCircle width={width} height={height} />
    </SVGIconWrapper>
  );
};

export { SVGInfoCircleAnimated };
