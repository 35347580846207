import {
  autoUpdate,
  offset as offsetMiddleware,
  flip,
  shift,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs,
} from "@floating-ui/react";
import { type RefObject, useContext, useMemo } from "react";
import { createPortal } from "react-dom";

import { FocusCatcher } from "@/components/FocusCatcher/FocusCatcher";
import { GlobalStylesScope } from "@/components/GlobalStylesScope/GlobalStylesScope";
import { ThemeVariantScope } from "@/components/ThemeVariantScope/ThemeVariantScope";
import { useElementWidth } from "@/hooks/useElementWidth";
import { ThemeContext } from "@/theming/ThemeContext";
import { mergeProps } from "@/utils/reactExtensions";

import styles from "./Popover.module.scss";
import { PopoverLayout, type PopoverLayoutProps } from "./PopoverLayout";

const MIN_POPOVER_HEIGHT = 160;

interface PopoverBaseProps {
  popoverRef?:
    | React.RefCallback<Element | null>
    | React.MutableRefObject<Element | null>
    | undefined;

  /**
   * Reference to the element that will trigger the popover.
   */
  triggerRef?: RefObject<Element | null>;

  /**
   * Custom popover id
   */
  id?: string;

  /**
   * Popover offset from the trigger element.
   */
  offset?: number;

  /**
   * State of the popover.
   */
  open?: boolean;

  /**
   * Callback when the popover state changes internally.
   */
  onOpenChange?: (open: boolean) => void;
}

interface PopoverProps extends React.PropsWithChildren<PopoverLayoutProps>, PopoverBaseProps {
  /**
   * Callback when user tries to leave popover using keyboard.
   * This is used to catch focus and prevent user from leaving the popover or to focus on next/previous element.
   *
   * ## Note
   *
   * Consumer should handle the focus logic and MUST focus on something else to not hold the focus on focus
   * catching elements.
   *
   * @param direction - Direction of the attempt, `backward` will be triggered on Shift+Tab and `forward` on Tab.
   */
  onBlurAttempt?: (direction: "forward" | "backward") => void;

  /**
   * Reference to the element that will be used to size the popover.
   */
  rectTriggerRef?: RefObject<Element | null>;
}

interface PopoverPortalProps extends PopoverBaseProps {
  width: string | undefined;
}

const PortalPopover: React.FC<React.PropsWithChildren<PopoverPortalProps>> = ({
  id,
  width,
  popoverRef,
  triggerRef,
  offset,
  open,
  onOpenChange,
  children,
}) => {
  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom-end",
    open,
    onOpenChange,
    strategy: "absolute",
    middleware: [
      offsetMiddleware(offset),
      flip({ fallbackAxisSideDirection: "end" }),
      shift(),
      size({
        apply({ availableWidth, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${Math.max(MIN_POPOVER_HEIGHT, availableWidth)}px`,
            maxHeight: `${Math.max(MIN_POPOVER_HEIGHT, availableHeight)}px`,
          });
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
    elements: {
      reference: triggerRef?.current,
    },
  });

  const dismiss = useDismiss(context);
  const { getFloatingProps } = useInteractions([dismiss]);

  const popoverMergedRef = useMergeRefs([popoverRef, refs.setFloating]);

  return !open
    ? null
    : createPortal(
        <div
          id={id}
          {...mergeProps(
            {
              style: {
                ...floatingStyles,
                width,
              },
            },
            getFloatingProps(),
            { className: styles.popoverPortal }
          )}
          ref={popoverMergedRef}
        >
          {children}
        </div>,
        document.body
      );
};

/**
 * Popover allows you to display content on top of other content in a styled box
 * with borders and shadow.
 *
 * ## Usage
 *
 * You need to manage state of popover and provide a trigger element to calculate
 * the position and width of the popover.
 *
 * ```tsx
 * const triggerRef = useRef<HTMLButtonElement>(null);
 * //You have to manage state of the popover
 * const [open, setOpen] = useState<boolean>(false);
 * ...
 * return (<>
 *    <button ref={triggerRef} onClick={() => setOpen((prev) => !prev)}>
 *      Anchor, click here to toggle
 *    </button>
 *    <Popover
 *      open={open}
 *      onOpenChange={setOpen}
 *      triggerRef={triggerRef} // This is required to calculate position of the popover
 *      rectTriggerRef={triggerRef} // This is required to calculate width of the popover
 *    >
 *      <a href="#">Focusable element</a>
 *    </Popover>
 *  </>);
 * ```
 */
export const Popover: React.FC<PopoverProps> = ({
  rectTriggerRef,
  useTriggerWidth,
  shade,
  onBlurAttempt,
  children,
  ...rest
}) => {
  const themeDefinition = useContext(ThemeContext);

  const width = useElementWidth(rectTriggerRef);

  const [handleForward, handleBackward] = useMemo(
    () => [() => onBlurAttempt?.("forward"), () => onBlurAttempt?.("backward")],
    [onBlurAttempt]
  );

  return (
    <PortalPopover width={width ? `${width}px` : undefined} {...rest}>
      <GlobalStylesScope themeDefinition={themeDefinition.currentTheme}>
        <ThemeVariantScope isContents variant={undefined}>
          {onBlurAttempt && <FocusCatcher onFocus={handleBackward} />}
          <PopoverLayout
            scrollable
            useTriggerWidth={useTriggerWidth}
            shade={shade}
            style={{ width }}
          >
            {children}
          </PopoverLayout>
          {onBlurAttempt && <FocusCatcher onFocus={handleForward} />}
        </ThemeVariantScope>
      </GlobalStylesScope>
    </PortalPopover>
  );
};
