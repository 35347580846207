import { useContext } from "react";

import { AuthenticationContext } from "./authenticationContext";

const SilentAuthentication: React.FC = () => {
  const { config, current } = useContext(AuthenticationContext);
  const isLoggingOut = current.isLoggingOut;
  const isRefreshTokenRequired = current.isRefreshTokenRequired;

  if (!config || config.isAuthenticationHost) {
    return null;
  }

  const iframeStyle: React.CSSProperties = {
    position: "absolute",
    height: 0,
    width: 0,
    border: 0,
    visibility: "hidden",
  };

  return isLoggingOut ? (
    <iframe style={iframeStyle} src={config.logoutUrl} sandbox="allow-scripts allow-same-origin" />
  ) : isRefreshTokenRequired ? (
    <iframe style={iframeStyle} src={config.refreshUrl} sandbox="allow-scripts allow-same-origin" />
  ) : null;
};

export { SilentAuthentication };
