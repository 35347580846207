"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Determines whether the current execution context is server-side
 * @returns true if executing server-side
 */
function isServer() {
    return !(typeof window !== 'undefined' && window.document);
}
exports.default = isServer;
