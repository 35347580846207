import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGGrille: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    {...rest}
  >
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M1.926 3.68a.652.652 0 0 1 .564-.33h11.02c.233 0 .447.127.564.33.116.203.12.453.008.659l-.964 1.784c.452.456 1.057 1.092 1.589 1.869C15.396 8.998 16 10.29 16 11.812a.66.66 0 0 1-.654.667H.654A.66.66 0 0 1 0 11.812C0 10.27.749 8.81 1.485 7.748a12.22 12.22 0 0 1 1.382-1.653l-.95-1.756a.679.679 0 0 1 .01-.66Zm1.671 1.004.67 1.238c.162.3.074.68-.206.873a5.21 5.21 0 0 0-.542.523c-.286.307-.632.719-.966 1.2-.546.788-1.02 1.708-1.184 2.627h13.272c-.135-.884-.524-1.685-1.006-2.39-.537-.783-1.16-1.406-1.624-1.871l-.165-.166a.677.677 0 0 1-.112-.796l.669-1.238H3.597ZM8 7.587c.813 0 1.472-.44 1.472-.982S8.813 5.624 8 5.624s-1.472.44-1.472.981c0 .542.659.982 1.472.982Z"
      clip-rule="evenodd"
    />
  </svg>
);
SVGGrille = memo(SVGGrille);

export { SVGGrille };
