import { camelCase, pascalCase } from "change-case";

type KebabToPascal<T extends string> = T extends `${infer A}-${infer B}`
  ? `${Capitalize<Lowercase<KebabToPascal<A>>>}${KebabToPascal<B>}`
  : Capitalize<Lowercase<T>>;

/**
 * Typesafe function that converts a string from kebab-case to PascalCase.
 *
 * NOTE: Since Template Literal Types not super advanced now, this function
 * only works with strings that have a simple snake cases with dash. Use it only
 * for static known conversions like converting a prop value to a CSS class name.
 * Function is not safe to use with undefined or null values.
 *
 * @param str Kebab-case string
 * @returns PascalCase string
 */
export const kebabToPascalCase = <T extends string | "">(str: T): KebabToPascal<T> =>
  pascalCase(str) as KebabToPascal<T>;

export const kebabToCamelCase = <T extends string | "">(str: T): Uncapitalize<KebabToPascal<T>> =>
  camelCase(str) as Uncapitalize<KebabToPascal<T>>;
