import { useState, Children, useRef, useImperativeHandle, useCallback, useEffect } from "react";

import { FocusBlocker } from "@/components/FocusBlocker/FocusBlocker";

import {
  useAutoSlideTransition,
  useDebouncedResizeHandler,
  useDebouncedScrollHandler,
  useDragAndSwipe,
  useSlideDurations,
  useSyncVideoSlides,
} from "./SlideShow.hooks";
import styles from "./SlideShow.module.scss";
import { SlideshowProps, useChildRefs } from "./SlideShow.utils";

/**
 * `SlideShow` component that automatically transitions between slides, and the transition can be controlled
 * from outside of the component.
 * Each slide supports any content, including images, videos, and text. It supports looping, and the default
 * transition interval is 7s.
 *
 * `SlideShow` component needs to be wrapped with `TransitionControlProvider` in the hierarchy appropriately
 * to control the transition duration and pause the transition.
 *
 * ## Note
 * `SlideShow` component don't not support adding or removing slides dynamically at the moment.
 * // TODO: https://toyotaau.atlassian.net/browse/LTL-57
 *
 * ## Usage
 *
 * ```tsx
 * <SlideShow onSlideChange={(currentSlide) => console.log(currentSlide)}>
 *   <div>Slide 1</div>
 *   <div>Slide 2</div>
 *   <div>Slide 3</div>
 * </SlideShow>
 * ```
 */
export const SlideShow: React.FC<React.PropsWithChildren<SlideshowProps>> = ({
  children,
  onSlideChange,
  slideShowRef,
}) => {
  const [currentSlide, setCurrentSlide] = useState<number>(1);
  const [slideDurations, setSlideDurations] = useState<number[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const childArray = Children.toArray(children);
  const extendedChildren = [childArray[childArray.length - 1], ...childArray, childArray[0]];
  const slideCount = extendedChildren.length;
  const childRefs = useChildRefs(extendedChildren);
  const timeoutRef = useRef<number | null>(null);

  const nextSlide = useCallback(() => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: containerRef.current.offsetWidth,
        behavior: "smooth",
      });
    }
  }, []);

  const prevSlide = useCallback(() => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -containerRef.current.offsetWidth,
        behavior: "smooth",
      });
    }
  }, []);

  const {
    isDragging,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  } = useDragAndSwipe(nextSlide, prevSlide);

  useSyncVideoSlides({ childRefs });

  useImperativeHandle(slideShowRef, () => ({
    nextSlide,
    prevSlide,
    slideCount: childArray.length,
  }));

  const handleSlideChange = useCallback(
    (newSlide: number) => {
      setCurrentSlide(newSlide);
      onSlideChange?.(newSlide);
    },
    [onSlideChange]
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const container = containerRef.current;

    if (!container) return;

    if (event.key === "Tab") {
      container.style.scrollBehavior = "smooth";

      timeoutRef.current && clearTimeout(timeoutRef.current);

      timeoutRef.current = window.setTimeout(() => {
        container.style.scrollBehavior = "auto";
      }, 300);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useDebouncedScrollHandler({
    containerRef,
    slideCount,
    onSlideChange: handleSlideChange,
  });

  useDebouncedResizeHandler({
    containerRef,
    currentSlide,
  });

  useAutoSlideTransition({ nextSlide, currentSlide, slideDurations });
  useSlideDurations({ childRefs, slideCount, setSlideDurations });

  // TODO: Solve this to show customer real first slide while JS still loading
  // Ticket: https://toyotaau.atlassian.net/browse/LTL-58
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    container.scrollTo({ left: container.offsetWidth, behavior: "auto" });
  }, [containerRef]);

  return (
    <div
      ref={containerRef}
      className={styles.slideShowInner}
      style={{
        cursor: isDragging ? "grabbing" : "grab",
      }}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onTouchEnd={handleTouchEnd}
      onTouchCancel={handleTouchEnd}
      onMouseMove={handleMouseMove}
      onTouchMove={handleTouchMove}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
      onKeyDown={handleKeyDown}
    >
      {Children.map(extendedChildren, (child, index) => (
        <div key={index} className={styles.slideShowInnerContent} ref={childRefs.current[index]}>
          {index === 0 || index === slideCount - 1 ? (
            <FocusBlocker className={styles.slideShowInnerContent}>{child}</FocusBlocker>
          ) : (
            child
          )}
        </div>
      ))}
    </div>
  );
};
