import { RefObject, useEffect } from "react";

export const useOnClickOutside = (refArray: RefObject<HTMLElement>[], handler: () => void) => {
  useEffect(() => {
    const listener = (event: TouchEvent | MouseEvent) => {
      // Do nothing if clicking any of ref's element or descendent elements
      const isClickInside = refArray.some((ref) => ref.current?.contains(event.target as Node));
      if (isClickInside) return;
      handler();
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [refArray, handler]);
};
