import { isHTMLElement } from "./typeGuards";

/**
 * Get mouse position relative to an element and element's with and height value.
 */
export const getEleInfo = (e: React.MouseEvent) => {
  const rect = e.currentTarget.getBoundingClientRect();
  const mouseX = `calc( ${e.clientX - rect.left}px - (${rect.width}px - 100%) / 2 )`;
  const mouseY = `calc( ${e.clientY - rect.top}px - (${rect.height}px - 100%) / 2 )`;
  const width = rect.width;
  const height = rect.height;
  return [mouseX, mouseY, width, height];
};

/**
 * Set custom css variables for elements to update their styles
 */
export const setCustomVariablesForEle = (name: string, value: string) => {
  const root = document.documentElement;
  root.style.setProperty(name, value);
};

interface GetAllFocusableOptions {
  /**
   * Root element to search in. Default is `document`.
   */
  root?: Element | null;

  /**
   * Reference element to start from. Reference element MUST be focusable.
   */
  reference?: Element | null;

  /**
   * Direction to get elements in relation to the reference element
   * "forward" - get elements after the reference element, order will be maintained as in the document.
   * "backward" - get elements before the reference element, order will be maintained as in the document, so if
   * you need to find previous element, you need to reverse the array or pick the last.
   */
  direction?: "forward" | "backward";

  /**
   * Include elements with `data-is-lk-focus-catcher` attribute. Default is `false`.
   */
  includeLkFocusCatchers?: boolean;
}

interface GetFocusableOptions extends GetAllFocusableOptions {
  /**
   * Loop to the first/last element in the document if next element is not found. If found is the same as the
   * reference element this function will return `undefined`.
   */
  loop?: boolean;
}

const focusableSelectors: string[] = [
  "a[href]",
  "area[href]",
  "input:not([disabled]):not([type=hidden])",
  "select:not([disabled])",
  "textarea:not([disabled])",
  "button:not([disabled])",
  "summary",
  "iframe",
  "object",
  "embed",
  "audio[controls]",
  "video[controls]",
  "[contenteditable]:not([contenteditable^='false'])",
  "[tabindex]",
];

const negativeTabIndexFilter = ":not([tabindex^='-'])";
const lkFocusCatcherFilter = ":not([data-is-lk-focus-catcher])";

/**
 * Get all focusable elements in the document.
 *
 * ## Note
 *
 * When using this method you need to take in account that your reference element may be the last or first element
 * in the document, so you need to handle this case in your code. By default, browser will focus on toolbar or
 * address bar
 */
export const getAllFocusableElements = (options?: GetAllFocusableOptions): HTMLElement[] => {
  const { root, reference, direction, includeLkFocusCatchers } = options || {};

  const all = Array.from(
    // Could be done with `:is` selector, but it is not supported in Jest tests
    (root || document).querySelectorAll(
      focusableSelectors
        .map(
          (selector) =>
            `${selector}${negativeTabIndexFilter}${
              !includeLkFocusCatchers ? lkFocusCatcherFilter : ""
            }`
        )
        .join(", ")
    )
  )
    .filter(isHTMLElement)
    .filter((el) => el.offsetParent);

  if (reference) {
    const position = all.findIndex((el) => el === reference);
    return position !== -1
      ? direction === "backward"
        ? all.slice(0, position)
        : all.slice(position + 1)
      : all;
  }

  return all;
};

/**
 * Get next focusable element in the document.
 *
 * ## Note
 *
 * When using this method you need to take in account that your reference element may be the last or first element
 * in the document, so you need to handle this case in your code. By default, browser will focus on toolbar or
 * address bar. You can use `loop` parameter to loop to the first/last element if next element is not found.
 */
export const getNextFocusableElement = (options?: GetFocusableOptions): HTMLElement | undefined => {
  const { loop, ...restOptions } = options || {};
  const all = getAllFocusableElements(restOptions);
  const { reference, direction } = restOptions;
  let foundElement = all.length ? all[direction === "backward" ? all.length - 1 : 0] : undefined;

  if (loop && !foundElement) {
    const allDoc = getAllFocusableElements({ ...restOptions, reference: null });
    const nextPicked = allDoc[direction === "backward" ? allDoc.length - 1 : 0];
    if (!reference || nextPicked !== reference) {
      foundElement = nextPicked;
    }
  }
  return foundElement;
};
