import { MarkerClusterer, Renderer } from "@googlemaps/markerclusterer";
import difference from "lodash/difference";
import {
  Dispatch,
  MutableRefObject,
  RefObject,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
} from "react";
import * as ReactDOM from "react-dom";
import type { Root } from "react-dom/client";

import { isDefinedObject } from "@/utils/typeGuards";

import GoogleWrappers from "./GoogleWrappers";
import { getMarkersBounds, lexusToGoogleCoordinates, markerIcon } from "./LexusMapHelpers";
import { mapStyles } from "./mapStyles";
import {
  LexusMapCoordinates,
  LexusMapDirectionsWithIcons,
  LexusMapStyle,
  MapDetails,
  MapState,
  Marker,
} from "./types";
import { MapTheme } from "./types";

const DEFAULT_ZOOM_LEVEL = 15;

const LEXUS_POLYLINE_STYLE = {
  strokeColor: "var(--ld-color-legacy-senkei-inari)",
  strokeOpacity: 0.75,
  strokeWeight: 5,
};

export const getDirectionRendererOptions = (
  map: google.maps.Map,
  mapStyle?: LexusMapStyle
): google.maps.DirectionsRendererOptions => ({
  map,
  suppressMarkers: true,
  polylineOptions: mapStyle === "lexus" ? LEXUS_POLYLINE_STYLE : undefined,
});

export const useCreateGoogleMap = (
  containerRef: RefObject<HTMLDivElement>,
  setMapState: Dispatch<SetStateAction<MapState | undefined>>,
  mapDetails?: MapDetails,
  mapStyle?: LexusMapStyle
) => {
  useEffect(() => {
    if (containerRef.current && window.google) {
      const map = new google.maps.Map(containerRef.current, {
        center: mapDetails?.center,
        zoom: mapDetails?.zoom || DEFAULT_ZOOM_LEVEL,
      });

      const directionRenderer = new google.maps.DirectionsRenderer(
        getDirectionRendererOptions(map, mapStyle)
      );

      setMapState({ map, directionRenderer });
    }
  }, [containerRef, mapDetails?.center, mapDetails?.zoom, mapStyle, setMapState]);
};

export const useMarkersWithDirectionPoints = (
  markers: Marker[],
  directions?: LexusMapDirectionsWithIcons
) => {
  const originMarker = useMemo(
    () =>
      directions?.origin
        ? {
            lat: directions.origin.lat,
            lng: directions.origin.lng,
            icon: directions.originIcon,
            neverClustered: true,
          }
        : undefined,
    [directions?.origin, directions?.originIcon]
  );

  const destinationMarker = useMemo(
    () =>
      directions?.destination
        ? {
            lat: directions.destination.lat,
            lng: directions.destination.lng,
            icon: directions.destinationIcon,
            neverClustered: true,
          }
        : undefined,
    [directions?.destination, directions?.destinationIcon]
  );

  const allMarkers = useMemo(() => {
    const locations = [...markers];
    if (originMarker) {
      locations.push(originMarker);
    }
    if (destinationMarker) {
      locations.push(destinationMarker);
    }

    return locations;
  }, [markers, originMarker, destinationMarker]);
  return allMarkers;
};

export const useSyncMapCenter = (
  hasMapRef: MutableRefObject<boolean>,
  mapState: MapState | undefined,
  center?: LexusMapCoordinates
) => {
  useEffect(() => {
    if (hasMapRef.current && center) {
      mapState?.map.setCenter(center);
    }
  }, [mapState, center, hasMapRef]);
};

export const useSyncMapZoom = (
  hasMapRef: MutableRefObject<boolean>,
  mapState: MapState | undefined,
  zoom: number | undefined
) => {
  useEffect(() => {
    if (hasMapRef.current) {
      mapState?.map.setZoom(zoom || DEFAULT_ZOOM_LEVEL);
    }
  }, [mapState, zoom, hasMapRef]);
};

export const useSyncMapStyle = (
  mapState: MapState | undefined,
  mapStyle: LexusMapStyle | undefined,
  hasMapRef: MutableRefObject<boolean>,
  theme?: MapTheme
) => {
  useEffect(() => {
    if (!mapState?.map) {
      return;
    }

    mapState.map.setOptions({
      styles: mapStyle === "lexus" ? mapStyles(theme) : undefined,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    });
    if (hasMapRef.current) {
      mapState?.directionRenderer.setOptions(getDirectionRendererOptions(mapState.map, mapStyle));
    }
  }, [mapState, mapStyle, hasMapRef, theme]);
};

export const useSyncClusterStyle = (
  mapState: MapState | undefined,
  setClusterState: Dispatch<SetStateAction<MarkerClusterer | undefined>>,
  clusterStyles?: Renderer
) => {
  useEffect(() => {
    if (!mapState?.map || !clusterStyles) {
      return;
    }

    const clusterer = new GoogleWrappers.MarkerClusterer({
      map: mapState.map,
      renderer: clusterStyles,
    });

    setClusterState(clusterer);
  }, [mapState, clusterStyles, setClusterState]);
};

export const useSyncDirections = (
  mapState: MapState | undefined,
  directions: google.maps.DirectionsResult | undefined,
  hasMapRef: MutableRefObject<boolean>
) => {
  useEffect(() => {
    if (!mapState?.map) {
      return;
    }
    if (directions) {
      mapState.directionRenderer.setDirections(directions);
      mapState.directionRenderer.setMap(mapState?.map);
    } else if (hasMapRef.current) {
      mapState.directionRenderer.setMap(null);
    }
  }, [mapState, directions, hasMapRef]);
};

export const removeOldMarkers = (
  markerMap: Map<Marker, google.maps.Marker>,
  newMarkers: Marker[],
  clusterer?: MarkerClusterer
) => {
  const markersToRemove = difference(Array.from(markerMap.keys()), newMarkers);
  const gMarkers = markersToRemove.map((marker) => markerMap.get(marker)).filter(isDefinedObject);
  gMarkers.forEach((gMarker) => gMarker.setMap(null));
  clusterer?.removeMarkers(gMarkers);
  markersToRemove.forEach((marker) => markerMap.delete(marker));
};

export const createGoogleMarker = (position: Marker, map: google.maps.Map) =>
  new google.maps.Marker({
    position: lexusToGoogleCoordinates(position),
    title: position.icon?.iconTitle || "",
    map,
    icon: position.icon?.default || markerIcon("default", position.icon),
    optimized: false,
    label: position.label,
  });

export const useSyncMarkers = (
  map: google.maps.Map | undefined,
  clusterer: MarkerClusterer | undefined,
  allMarkers: Marker[],
  markerCallback: ((value: boolean) => void) | undefined,
  shouldResize?: boolean
) => {
  const markerMap = useRef(new Map<Marker, google.maps.Marker>());
  const infoWindowElement = useRef(
    typeof document !== "undefined" && document.createElement("div")
  );

  useEffect(() => {
    if (!map) {
      return;
    }

    let root: Root | undefined;
    const infoWindow = new google.maps.InfoWindow();

    infoWindow.addListener("closeclick", () => {
      if (infoWindowElement.current) {
        if (root && root.unmount) {
          root.unmount();
        } else {
          //React 16 approach
          // eslint-disable-next-line react/no-deprecated
          ReactDOM.unmountComponentAtNode?.(infoWindowElement.current);
        }
      }

      markerCallback?.(false);
    });

    removeOldMarkers(markerMap.current, allMarkers, clusterer);

    const googleMarkers: google.maps.Marker[] = [];

    const syncToGoogleMarker = (position: Marker) => {
      const existingGoogleMarker = markerMap.current.get(position);
      if (existingGoogleMarker) {
        return existingGoogleMarker;
      }

      const icon = position.icon;

      const googleMarker = createGoogleMarker(position, map);

      googleMarker.addListener("click", async () => {
        const pos = googleMarker?.getPosition();
        if (position.getMarkerContent && infoWindowElement.current) {
          const reactClient = await import("react-dom/client");
          if (typeof reactClient?.createRoot === "function") {
            root = reactClient.createRoot(infoWindowElement.current);
            root.render(position.getMarkerContent());
          } else {
            //React 16 approach
            // eslint-disable-next-line react/no-deprecated
            ReactDOM.render(position.getMarkerContent(), infoWindowElement.current);
          }

          infoWindow.setContent(infoWindowElement.current);
          infoWindow.open(map, googleMarker);
          markerCallback?.(true);
        } else if (pos) {
          map.panTo(pos);
        }

        googleMarker.setIcon(icon?.default || markerIcon("clicked", icon) || null);
        position.clickHandler?.();
      });

      markerMap.current.set(position, googleMarker);

      if (!position.neverClustered) {
        googleMarkers.push(googleMarker);
      }

      return googleMarker;
    };
    allMarkers.forEach(syncToGoogleMarker);
    clusterer?.addMarkers(googleMarkers);

    const boundsMarkers = allMarkers
      .filter((marker) => !marker.ignoreWhenCalcResizeBounds)
      .map((marker) => markerMap.current.get(marker))
      .filter(isDefinedObject);

    if (shouldResize) {
      const bounds = getMarkersBounds(boundsMarkers);
      // auto-zoom
      if (boundsMarkers && boundsMarkers.length > 1) {
        map.fitBounds(bounds); // Only fit bounds if more than 1 marker to avoid excessive zoom
      } else {
        const mapPosition = boundsMarkers[0]?.getPosition();
        mapPosition && map.setCenter(mapPosition);
      }
      // auto-center
      map.panToBounds(bounds);
    }
  }, [map, allMarkers, markerCallback, shouldResize, clusterer]);
};
