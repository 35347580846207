import cn from "classnames";
import { useContext } from "react";

import { Button, ButtonProps } from "@/components/Button/Button";
import { ThemeContext } from "@/theming/ThemeContext";
import { useThemeVariant } from "@/theming/VariantContext";
import {
  Address,
  DealerConfig,
  DealerLocation,
  ServiceType,
  type ServiceTypes,
} from "@/types/dealer";
import { DealerContext } from "@/utils/DealerContext";

import styles from "./DealerService.module.scss";
import { DealerServiceLine } from "./DealerServiceLine";
import { DealerServicePublicHolidays } from "./DealerServicePublicHolidays";
import { DealerBranchProps } from "../DealerBranch/DealerBranch";
import { DealerOpeningHours } from "../DealerOpeningHours/DealerOpeningHours";

export type DealerServiceProps = {
  serviceType: ServiceTypes;
} & DealerBranchProps;

const buildAddress = (address: Address) => {
  const newline =
    address.streetAddress && (address.suburb || address.state || address.postCode) ? <br /> : null;
  return (
    address && (
      <span>
        {address.streetAddress}
        {newline}
        {[address.suburb, address.state?.toUpperCase(), address.postCode]
          .filter((n) => n?.trim())
          .join(", ")}
      </span>
    )
  );
};

const buildButtonProps = (
  serviceType: ServiceTypes,
  dealerConfig: DealerConfig,
  dealerId: string
): ButtonProps => {
  const bookServiceUrl = dealerConfig.bookServiceUrl?.value?.replace("{dealerId}", dealerId);
  const bookTestDriveUrl = dealerConfig.bookTestDriveUrl?.value?.replace("{dealerId}", dealerId);

  switch (serviceType.toLowerCase()) {
    case ServiceType.Service:
      return {
        href: bookServiceUrl,
        children: dealerConfig.bookServiceLabel?.value,
        component: "a",
      };
    // TODO: what happens when the service provided is main or new?
    default:
    case ServiceType.Sales:
      return {
        href: bookTestDriveUrl,
        children: dealerConfig.bookTestDriveLabel?.value,
        component: "a",
      };
  }
};

const getBranchConfig = (dealerConfig: DealerConfig, dealerLocation: DealerLocation) =>
  dealerConfig.branches?.results.find(
    (dealer) => dealer.branchCode?.value && dealer.branchCode?.value === dealerLocation.branchCode
  );

const DealerService: React.FC<DealerServiceProps> = ({ dealerLocation, serviceType }) => {
  const { dealerConfig } = useContext(DealerContext);
  const { getVariantClassNameOrDefault } = useContext(ThemeContext);
  const contextualThemeVariant = useThemeVariant();

  if (!dealerConfig) {
    return null;
  }

  const openingHours = dealerLocation.serviceTypes.find(
    (s) => s.name === serviceType
  )?.openingHours;

  const branchConfig = getBranchConfig(dealerConfig, dealerLocation);

  return (
    <div className={cn(getVariantClassNameOrDefault("alt" || contextualThemeVariant))}>
      <Button
        themeVariant="alt"
        {...buildButtonProps(serviceType, dealerConfig, dealerLocation.dealerId)}
        className={styles.dealerServiceButton}
      />
      <DealerServiceLine
        icon={dealerConfig.addressIcon?.value}
        content={buildAddress(dealerLocation.address)}
        className={styles.dealerServiceAddress}
        iconClassName={styles.dealerServiceIconContainerAddress}
      />
      <DealerServiceLine
        icon={dealerConfig.phoneIcon?.value}
        content={dealerLocation.phone[serviceType]}
        linkReference={`tel:+61${dealerLocation.phone[serviceType]}`}
      />
      <DealerServiceLine
        icon={dealerConfig.emailIcon?.value}
        content={dealerLocation.email[serviceType]}
        linkReference={`mailto:${dealerLocation.email[serviceType]}`}
        iconClassName={styles.dealerServiceIconContainerEmail}
      />
      {dealerConfig.openingHoursLabel?.value && openingHours && (
        <DealerOpeningHours openingHours={openingHours} />
      )}
      {dealerConfig.publicHolidayHoursLabel?.value && branchConfig && (
        <DealerServicePublicHolidays
          publicHolidayDetails={
            serviceType === ServiceType.Sales
              ? branchConfig.salesPublicHolidayDetails?.value
              : branchConfig.servicePublicHolidayDetails?.value
          }
        />
      )}
    </div>
  );
};

export { DealerService };
