import { useContext } from "react";

import { Box } from "@/components/Box/Box";
import { Stack } from "@/components/Stack/Stack";
import { TooltipIconColor, TooltipWithIcon } from "@/components/TooltipWithIcon/TooltipWithIcon";
import { Typography } from "@/components/Typography/Typography";
import { DealerContext } from "@/utils/DealerContext";

import styles from "./DealerService.module.scss";

type DealerServicePublicHolidaysProps = {
  publicHolidayDetails?: string;
};

const DealerServicePublicHolidays: React.FC<DealerServicePublicHolidaysProps> = ({
  publicHolidayDetails,
}) => {
  const { dealerConfig } = useContext(DealerContext);

  if (!dealerConfig?.publicHolidayHoursLabel?.value || !publicHolidayDetails) {
    return null;
  }

  return (
    <Stack component={Box} pl="m" alignItems="center" spacing="2xs">
      <span className={styles.dealerServicePublicHolidayText}>
        {dealerConfig.publicHolidayHoursLabel.value}
      </span>
      <TooltipWithIcon
        ariaLabel="tooltip"
        iconColor={TooltipIconColor.ACCENT}
        showPopupOnFocus
        pointerPosition="bottom-middle"
      >
        <Typography component="p" variant="b2">
          {publicHolidayDetails}
        </Typography>
      </TooltipWithIcon>
    </Stack>
  );
};

export { DealerServicePublicHolidays };
