import PropTypes from 'prop-types';
import React from 'react';
export const MissingComponent = (props) => {
    const componentName = props.rendering && props.rendering.componentName
        ? props.rendering.componentName
        : 'Unnamed Component';
    console.log(`Component props for unimplemented '${componentName}' component`, props);
    return (React.createElement("div", { style: {
            background: 'darkorange',
            outline: '5px solid orange',
            padding: '10px',
            color: 'white',
            maxWidth: '500px',
        } },
        React.createElement("h2", null, componentName),
        React.createElement("p", null, "JSS component is missing React implementation. See the developer console for more information.")));
};
MissingComponent.propTypes = {
    rendering: PropTypes.shape({
        componentName: PropTypes.string,
    }),
};
MissingComponent.displayName = 'MissingComponent';
