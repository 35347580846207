import cn from "classnames";
import { useEffect, useRef, useState } from "react";

import { useTooltipContext } from "@/contexts/TooltipContext";

import { getVideoUrls, useVideoAnalytics, useVideoStateManager } from "./helpers";
import styles from "./VideoOrImage.module.scss";

export interface VideoOrImageProps {
  className?: string;

  image?: React.ReactNode;

  /**
   * Multiline text with list of alternative sources and codecs
   * Lines can be split using `\n`, `\r\n` or `<br />`
   *
   * Codec information is required for second and further sources.
   * Use `|` (pipe) after URL on each line to add it. This will be
   * split into `type` parameter of each video `<source />`
   */
  videoInformation: string | undefined;

  /**
   * Root container style
   */
  style?: React.CSSProperties;

  /*
   * Callback to be called when video is loaded
   */
  onVideoLoaded?: () => void;

  /*
   * Whether video is paused by outside controller or not
   */
  isVideoPaused?: boolean;

  /**Disclaimer for video */
  videoDisclaimer?: React.ReactNode;

  /**Disclaimer for image */
  imageDisclaimer?: React.ReactNode;
}

export const VideoOrImage: React.FC<VideoOrImageProps> = ({
  className,
  image,
  onVideoLoaded,
  style,
  videoInformation,
  videoDisclaimer,
  imageDisclaimer,
  isVideoPaused = false,
}) => {
  const videoComponentRef = useRef<HTMLVideoElement>(null);
  const videoOrImageRef = useRef<HTMLDivElement>(null);
  const videoSources = getVideoUrls(videoInformation);

  const [isVideoAvailable, setIsVideoAvailable] = useState(false);
  useVideoAnalytics(videoComponentRef);

  const videoCanPlay = useVideoStateManager(videoComponentRef, isVideoPaused);
  const isVideoPausedRef = useRef<boolean>();
  isVideoPausedRef.current = isVideoPaused;
  const [, setTooltipContent] = useTooltipContext();

  const handleVideoOnLoadedData = (e: React.SyntheticEvent<HTMLVideoElement>) => {
    if (e.currentTarget.currentSrc && onVideoLoaded) {
      onVideoLoaded();
    }
  };

  useEffect(() => {
    if (videoCanPlay && !isVideoAvailable) {
      setIsVideoAvailable(true);
    }
  }, [isVideoAvailable, setTooltipContent, videoCanPlay]);

  useEffect(() => {
    const videoOrImageEl = videoOrImageRef.current;
    if (!videoOrImageEl) {
      return;
    }
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0].target instanceof HTMLDivElement) {
        const isVisible = entries[0].target.clientWidth;
        if (!!isVisible) {
          isVideoAvailable
            ? setTooltipContent(videoDisclaimer)
            : setTooltipContent(imageDisclaimer);
        }
      }
    });
    resizeObserver.observe(videoOrImageEl);

    return () => {
      resizeObserver.disconnect();
    };
  }, [imageDisclaimer, isVideoAvailable, setTooltipContent, videoDisclaimer]);

  return (
    <div ref={videoOrImageRef} style={style} className={cn(className, styles.videoOrImage)}>
      {!videoCanPlay && <div>{image}</div>}
      {videoSources && (
        <video
          className={styles.video}
          muted={true}
          loop={true}
          suppressHydrationWarning={true}
          autoPlay={false}
          preload="none"
          data-preload="metadata"
          playsInline={true}
          ref={videoComponentRef}
          onLoadedData={handleVideoOnLoadedData}
        >
          {videoSources.map((v) => (
            <source src={v.src} type={v.type} key={`${v.src}${v.type || ""}`} />
          ))}
        </video>
      )}
    </div>
  );
};
