import cn from "classnames";

import styles from "./Shadow.module.scss";
import { ShadowVariant, ShadowOverlay } from "./Shadow.types";
import { getCustomCssProps } from "./Shadow.utils";

export type ShadowProps = React.HTMLAttributes<HTMLDivElement> &
  React.PropsWithChildren<{
    /**
     * Variant that changes the opacity of the gradient shadow.
     * @default 0.75 | darker: 0.9
     */
    variant?: ShadowVariant;

    /**
     *  Type of overlay. "Solid" will apply a solid color overlay.
     * @default top
     * */
    overlay?: ShadowOverlay;

    /**
     * Percentage offset for shadow effect.
     */
    shadowEffectPercentOffset?: number;
  }>;

/**
 * A visual component that applies a linear gradient overlay to its preceding sibling element.
 *
 * ## Usage
 * ```tsx
 *  <Layers>
      <BottomComponent />
      <Shadow />
      <TopComponent />
    </Layers>
 * ```
 * In the example above, the `Shadow` component will affect the BottomComponent (_e.g. background images_) and
 * not the TopComponent (_e.g. Typography, text contents_).
 *
 * ### Notes
 * - You should almost always use this within a `Layers` component.
 * - This component does not clip with the content it overlaps - if your background component has transparency or
 *  is not rectangular, the gradient will be shown out of bounds.
 */
export const Shadow: React.FC<ShadowProps> = ({
  variant = "default",
  overlay = "top",
  shadowEffectPercentOffset,
  className,
}) => {
  const customCssProps = getCustomCssProps({ overlay, variant, shadowEffectPercentOffset });
  return (
    <div
      className={cn(
        styles.shadow,
        {
          [styles.solid]: overlay === "solid",
        },
        className
      )}
      style={customCssProps}
      data-testid="lk-shadow"
    />
  );
};
