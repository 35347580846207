import cn from "classnames";
import { createContext, useMemo } from "react";

import styles from "./AccordionGroup.module.scss";
import { Accordion } from "../Accordion/Accordion";
import { Divider } from "../Divider/Divider";

type AccordionGroupProps = {
  /**
   * Whether accordion should be exclusive or can have multiples opened at the same time.
   */
  isItemExclusive?: boolean;

  /**
   * Accordion content
   */
  children: React.ReactElement<typeof Accordion>[];

  /**
   * ariaLabel of the Accordion
   */
  ariaLabel?: string;

  /**
   * Provide custom class for Accordion
   */
  className?: string | undefined;
};

type AccordionGroupContextData = {
  name?: string;
  showDivider?: boolean;
};

export const AccordionGroupContext = createContext<AccordionGroupContextData>({});

/**
 * Accordion Group is recommended to use with `Accordion`. This provides a layout to
 * display a group of Accordion components.
 * ## Usage
 *
 * ```tsx
 * <AccordionGroup> Accordion items here </AccordionGroup>
 * ```
 */
export const AccordionGroup: React.FC<AccordionGroupProps> = ({
  children,
  ariaLabel = "accordionGroup",
  isItemExclusive,
  className,
}) => {
  const contextValue = useMemo(
    () => ({
      name: isItemExclusive ? "accordion item" : undefined,
      showDivider: true,
    }),
    [isItemExclusive]
  );
  return (
    <AccordionGroupContext.Provider value={contextValue}>
      <div aria-label={ariaLabel} className={cn(styles.accordionGroupContainer, className)}>
        <Divider variant="dark" />
        {children}
      </div>
    </AccordionGroupContext.Provider>
  );
};
