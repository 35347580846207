import cn from "classnames";
import { useContext } from "react";

import { getBorderRadiusProps } from "@/components/BorderRadius/BorderRadius";
import { AuthenticationContext } from "@/utils/Authentication/authenticationContext";
import { mergeProps } from "@/utils/reactExtensions";

import styles from "./PrimaryNavNavbarItemAuthenticated.module.scss";
import { Menu } from "./types/PrimaryNav.types";

interface PrimaryNavItemEncoreProps {
  isMenuItemActive?: boolean;
  menuItem: Menu;
  onClick: (modal: Menu) => void;
}

const PrimaryNavNavbarItemAuthenticated: React.FC<PrimaryNavItemEncoreProps> = ({
  menuItem,
  onClick,
  isMenuItemActive,
}) => {
  const { current } = useContext(AuthenticationContext);
  const currentUser = current.user;

  return (
    <button
      className={cn(styles.navbarItemAuthenticated, { [styles.isActive]: isMenuItemActive })}
      onClick={() => onClick(menuItem)}
    >
      <span
        {...mergeProps(getBorderRadiusProps("rounded"), {
          className: styles.label,
        })}
      >
        {currentUser?.givenName?.charAt(0)}
        {currentUser?.familyName?.charAt(0)}
      </span>
    </button>
  );
};

export { PrimaryNavNavbarItemAuthenticated };
