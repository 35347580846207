import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGVideoPause: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 14V2h2v12zM10 14V2h2v12z"
    />
  </svg>
);

SVGVideoPause = memo(SVGVideoPause);

export { SVGVideoPause };
