export const borderRadiusMap = {
  none: "--ld-c-border-radius-none",
  small: "--ld-c-border-radius-small",
  medium: "--ld-c-border-radius-medium",
  "extra-medium": "--ld-c-border-radius-extra-medium",
  large: "--ld-c-border-radius-large",
  "extra-large": "--ld-c-border-radius-extra-large",
  rounded: "--ld-c-border-radius-rounded",
};

export type BorderRadiusScale = keyof typeof borderRadiusMap;

export const mapBorderRadiusToCSSVar = (borderRadius: BorderRadiusScale): string => {
  return borderRadiusMap[borderRadius];
};
