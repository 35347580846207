import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGSort: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.094 2.982h7.012a.432.432 0 1 1 0 .865H6.074a1.385 1.385 0 0 1-2.61 0h-.57a.432.432 0 0 1 0-.865h.55a1.385 1.385 0 0 1 2.65 0m-3.2 4.614a.432.432 0 0 0 0 .865h7.028a1.385 1.385 0 0 0 2.613 0h.571a.432.432 0 1 0 0-.865h-.554a1.385 1.385 0 0 0-2.648 0zm-.432 5.048c0-.239.193-.432.432-.432h2.399a1.385 1.385 0 0 1 2.65 0h5.163a.432.432 0 0 1 0 .864H7.924a1.385 1.385 0 0 1-2.612 0H2.894a.43.43 0 0 1-.432-.432"
    />
  </svg>
);

SVGSort = memo(SVGSort);

export { SVGSort };
