import cn from "classnames";
import { useContext, useEffect, useRef, useState } from "react";
import { Dialog, Modal, ModalOverlay } from "react-aria-components";
import { createPortal } from "react-dom";

import { useOnClickOutside } from "@/hooks/useOnClickOutside";
import { useOnEscKeyDown } from "@/hooks/useOnEscKeyDown";
import { ThemeContext } from "@/theming/ThemeContext";
import { ThemeCanvas, ThemeVariant } from "@/theming/themingTypes";

import styles from "./ModalSheet.module.scss";
import { GlobalStylesScope } from "../GlobalStylesScope/GlobalStylesScope";
import { ThemeVariantScope } from "../ThemeVariantScope/ThemeVariantScope";

export type ModalSheetProps = {
  /**
   * Default position of the modal
   */
  position?: "bottom" | "right";

  /**
   * Whether ModalSheet needs to add extra space to not obstruct page content
   */

  reserveSpace?: boolean;
  /**
   * Variant to override closest theme variant.
   */

  /**
   * Use themeVariant to override current theme
   */
  themeVariant?: ThemeVariant;

  /**
   * Canvas defines section background color.
   * Default: `"default"`.
   */
  canvas?: ThemeCanvas;

  /**
   * Stateless component needs to provide current open state
   */
  isOpen: boolean;

  /**
   * A Modal will render in a different portal attached to screen boundary,
   * Otherwise will render under its parent element
   */
  isModal?: boolean;

  /**
   * Method to be called when open state changes
   */
  onOpenChange?: (isOpen: boolean) => void;

  /**
   * Is dismissable via keyboard or interact with outside element
   */
  isDismissable?: boolean;

  /**
   * Suppress slide in animation
   */
  isAnimationSuppressed?: boolean;

  /**
   * Overlay for modal
   */
  overlay?: "blur" | "shadow" | "blurShadow";

  /**
   * Aria label for modal
   */
  ariaLabel: string;

  /**
   * Modal content
   */
  children: React.ReactNode;

  /**
   * Custom className
   */
  className?: string;
};

type SheetContainerProps = {
  isOpen: boolean;
  children: JSX.Element;
};

const DialogInternal: React.FC<Partial<ModalSheetProps>> = ({
  isModal,
  onOpenChange,
  canvas = "default",
  isDismissable,
  isAnimationSuppressed,
  reserveSpace,
  position,
  themeVariant,
  className,
  ariaLabel,
  children,
}) => {
  const handleOnclose = () => {
    onOpenChange && onOpenChange(false);
  };
  const dialogRef = useRef<HTMLElement>(null);
  useOnEscKeyDown(!!isDismissable, handleOnclose);
  useOnClickOutside([dialogRef], () => {
    isDismissable && handleOnclose();
  });

  const themeDefinition = useContext(ThemeContext);

  return (
    <GlobalStylesScope themeDefinition={themeDefinition.currentTheme}>
      <ThemeVariantScope isContents variant={themeVariant}>
        <Dialog
          className={cn(
            styles.dialogContainer,
            styles[`${canvas}Background`],
            { [styles.reserveSpace]: !isModal && reserveSpace },
            { [styles.slideIn]: !isAnimationSuppressed },
            { [styles.rightVariant]: position === "right" },
            { [styles.rightAnimation]: position === "right" },
            { [styles.bottomAnimation]: position === "bottom" },
            className
          )}
          ref={dialogRef}
          aria-label={ariaLabel}
        >
          {children}
        </Dialog>
      </ThemeVariantScope>
    </GlobalStylesScope>
  );
};

const SheetContainer: React.FC<Partial<ModalSheetProps> & SheetContainerProps> = ({
  isModal,
  children,
  overlay,
  isDismissable,
  isOpen,
  onOpenChange,
}) => {
  const [firstRendering, setFirstRendering] = useState(true);
  useEffect(() => {
    setFirstRendering(false);
  }, []);
  return isModal ? (
    <ModalOverlay
      className={cn(
        styles.overlay,
        { [styles.blur]: overlay === "blur" },
        { [styles.shadow]: overlay === "shadow" }
      )}
      isOpen={isOpen}
      isDismissable={isDismissable}
      onOpenChange={(isModalOpen) => onOpenChange && onOpenChange(isModalOpen)}
      isKeyboardDismissDisabled={!isDismissable}
    >
      <Modal>{children}</Modal>
    </ModalOverlay>
  ) : firstRendering ? null : (
    createPortal(children, document.body)
  );
};

/**
 * Modal Sheet can be used to display important content that prevent user interaction until dismissed. It supports animation
 * and stacked dialogs. Modal Sheet used to display cookie consent or filter from the right edge of screen with position `bottom` or `right`.
 *
 * Use `canvas` to have different background color. Options are `default | lighter | darker`.
 *
 *
 * `isDismissable` can be set to false to prevent user closing modal on click outside of Modal Sheet.
 *
 * It supports modal (with overlay) or dialog (no overlay and consumer can still interact with the underlay elements).
 * We can reserve space at the bottom of page container with `reserveSpace` property to ensure
 * main content are not obscured by Modal Sheet.
 *
 * Usage:
 *
 * ```tsx
 * <ModalSheet
 *  isOpen
 *  ariaLabel="modalSheet"
 * >
 *  {children}
 * </ModalSheet>
 * ```
 */
export const ModalSheet: React.FC<ModalSheetProps> = ({
  position = "bottom",
  reserveSpace,
  isOpen,
  isModal,
  isAnimationSuppressed,
  onOpenChange,
  themeVariant = "default",
  canvas = "default",
  overlay,
  isDismissable,
  ariaLabel,
  children,
  className,
}) =>
  !isOpen ? null : (
    <SheetContainer
      isOpen={isOpen}
      isModal={isModal}
      overlay={overlay}
      isDismissable={isDismissable}
      onOpenChange={onOpenChange}
    >
      <DialogInternal
        isDismissable={isDismissable}
        onOpenChange={onOpenChange}
        isModal={isModal}
        canvas={canvas}
        isAnimationSuppressed={isAnimationSuppressed}
        themeVariant={themeVariant}
        position={position}
        reserveSpace={reserveSpace}
        ariaLabel={ariaLabel}
        className={className}
      >
        {children}
      </DialogInternal>
    </SheetContainer>
  );
