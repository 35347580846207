import { createContext, useContext, useMemo } from "react";

interface TooltipContextValue {
  tooltip?: React.ReactNode;
  setTooltip: (tooltip?: React.ReactNode) => void;
}

const TooltipContext = createContext<TooltipContextValue>({
  setTooltip() {
    throw new Error("You must define TooltipContextProvider to set tooltip");
  },
});

export const TooltipContextProvider: React.FC<React.PropsWithChildren<TooltipContextValue>> = ({
  children,
  tooltip,
  setTooltip,
}) => {
  const contextValue = useMemo(
    () => ({
      tooltip,
      setTooltip,
    }),
    [tooltip, setTooltip]
  );
  return <TooltipContext.Provider value={contextValue}>{children}</TooltipContext.Provider>;
};

export const useTooltipContext = () => {
  const tooltipContext = useContext(TooltipContext);

  return [tooltipContext?.tooltip, tooltipContext?.setTooltip] as const;
};
