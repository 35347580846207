import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGVideoPlay: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="m4 1.957 8 6.286-8 5.714zm1.5 3.086v6l4-2.857z"
    />
  </svg>
);

SVGVideoPlay = memo(SVGVideoPlay);

export { SVGVideoPlay };
