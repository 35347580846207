import cn from "classnames";

import { mergeProps } from "@/utils/reactExtensions";

import { LinkStyleProps } from "./GenericLink";
import styles from "./GenericLink.module.scss";
import { getBorderRadiusProps } from "../BorderRadius/BorderRadius";
import { getFocusOutlineProps } from "../FocusOutline/FocusOutline";

export const getLinkStyles = ({ variant, hideExternalIcon }: LinkStyleProps) =>
  mergeProps(getFocusOutlineProps({}), getBorderRadiusProps("small"), {
    className: cn(styles.genericLink, {
      [variant ? styles[variant] : ""]: variant,
      [styles.externalLink]: hideExternalIcon,
    }),
  });
