import { useEffect } from "react";

export const useOnEscKeyDown = (isDismissable: boolean, onClose: () => void) => {
  useEffect(() => {
    if (isDismissable) {
      // Function to handle the 'Esc' key press
      const handleEscKey: (event: KeyboardEvent) => void = (event: KeyboardEvent) => {
        if (event.key === "Escape") onClose();
      };

      // Attach the event listener when the component mounts
      window.addEventListener("keydown", handleEscKey);

      // Cleanup the event listener when the component unmounts
      return () => {
        window.removeEventListener("keydown", handleEscKey);
      };
    }
  }, [onClose, isDismissable]);
};
