import cn from "classnames";
import { memo, useContext } from "react";

import { getBorderRadiusProps } from "@/components/BorderRadius/BorderRadius";
import { getBoxProps } from "@/components/Box/Box";
import { getFocusOutlineProps } from "@/components/FocusOutline/FocusOutline";
import { ThemeVariantScope } from "@/components/ThemeVariantScope/ThemeVariantScope";
import { mergeProps } from "@/utils/reactExtensions";

import { useSlideScrolling } from "./VerticalScroll.hooks";
import styles from "./VerticalScroll.module.scss";
import { ensureScrollItemByKey } from "./VerticalScroll.utils";
import { VerticalScrollContext } from "./VerticalScrollContext";

export const VerticalScrollNav: React.FC<{
  hidden: boolean;
  count: number;
}> = memo(function ScrollNavigation({ hidden, count }) {
  const {
    options: { scrollNavAriaLabel, noMobileNav },
    hiddenSlideIndexes,
  } = useContext(VerticalScrollContext);

  const slideNavItems = Array.from({ length: count }, (_, i) => i);

  return (
    <nav
      className={cn(styles.nav, {
        [styles.navHidden]: hidden,
        [styles.navHiddenMobile]: hidden || noMobileNav,
      })}
      aria-label={scrollNavAriaLabel}
      data-testid="lk-vertical-scroll-nav"
    >
      <ThemeVariantScope variant="alt" isContents>
        {slideNavItems
          .filter((key) => !hiddenSlideIndexes.has(key))
          .map((key, index) => (
            <ScrollNavItemMemoized key={key} index={key} visibleIndex={index} />
          ))}
      </ThemeVariantScope>
    </nav>
  );
});

const ScrollNavItemMemoized: React.FC<{
  index: number;
  visibleIndex: number;
}> = memo(function ScrollNavItem({ index, visibleIndex }) {
  const {
    itemsRef,
    options: { scrollNavButtonsAriaLabelFactory },
  } = useContext(VerticalScrollContext);
  const { handleScrollToSlide } = useSlideScrolling();

  return (
    <button
      key={index}
      onClick={() => handleScrollToSlide(index)}
      aria-label={scrollNavButtonsAriaLabelFactory?.(visibleIndex)}
      data-testid="lk-vertical-scroll-nav-item"
      ref={(node: HTMLButtonElement | null) => {
        const current = ensureScrollItemByKey(itemsRef, index);
        current.navItem = node;
      }}
      {...mergeProps(
        getBoxProps({ py: "3xs" }),
        getFocusOutlineProps({}),
        getBorderRadiusProps("medium"),
        {
          className: styles.navItemButton,
        }
      )}
    >
      <span className={styles.navItemBar} />
    </button>
  );
});
