import cn from "classnames";

import { getBorderRadiusProps } from "@/components/BorderRadius/BorderRadius";
import { getBoxProps } from "@/components/Box/Box";
import { getSurfaceProps } from "@/components/Surface/Surface";
import { ThemeSurfaceShade } from "@/theming/themingTypes";
import { mergeProps } from "@/utils/reactExtensions";

import styles from "./PopoverLayout.module.scss";

export interface PopoverLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Ref to root styled popover element.
   */
  popoverContentRef?:
    | React.MutableRefObject<HTMLDivElement | null>
    | React.RefCallback<HTMLElement>;

  /**
   * Respect `--trigger-width` token from ancestor element.
   *
   * ## Note
   * `rectTriggerRef` will be prioritised over this prop.
   */
  useTriggerWidth?: boolean;

  /**
   * Elevation shade of the popover.
   */
  shade?: ThemeSurfaceShade;

  /*
   * If the content is scrollable.
   */
  scrollable?: boolean;
}

/**
 * Popover Layout renders consistent base popover layout with borders and shadow.
 *
 * ## Usage
 *
 * ```tsx
 * const themeDefinition = useContext(ThemeContext);
 * // ...
 * <Popover ...>
 *   <GlobalStylesScope themeDefinition={themeDefinition.currentTheme}>
 *     // ...
 *     <PopoverLayout>{children}}</PopoverLayout>
 *     // ...
 *   </GlobalStylesScope>
 * </Popover>
 * ```
 */
export const PopoverLayout: React.FC<React.PropsWithChildren<PopoverLayoutProps>> = ({
  popoverContentRef,
  useTriggerWidth,
  shade = "default",
  scrollable,
  children,
  ...rest
}) => (
  <div
    ref={popoverContentRef}
    {...mergeProps(
      rest,
      getBorderRadiusProps("medium"),
      getBoxProps({ py: "3xs" }),
      getSurfaceProps("elevation-raised", shade),
      {
        className: cn(styles.popover, {
          [styles.useTriggerWidth]: useTriggerWidth,
          [styles.scrollable]: scrollable,
        }),
      }
    )}
  >
    {children}
  </div>
);
