import "./ToolTip.scss";

import * as React from "react";

import { SVGAnimationEvents, SVGIcon } from "../SVGIcons/SVGIcon";

import { SVGInfoCircleAnimated } from "../SVGIcons/icons/animated/SVGInfoCircleAnimated";
import classnames from "classnames";
import { lxColor } from "../../colors/variables";

const blockName = "lxs-tool-tip";

enum ToolTipState {
    CLOSED,
    SOFT_OPENED,
    HARD_OPENED,
}

export enum TooltipColor {
    SMOKE_DARK,
    ERROR,
}

export enum ToolTipPosition {
    TOP = "top",
    RIGHT = "right",
    BOTTOM = "bottom",
    LEFT = "left",
    TOP_RIGHT = "top-right",
    TOP_LEFT = "top-left",
    BOTTOM_RIGHT = "bottom-right",
    BOTTOM_LEFT = "bottom-left",
}

interface ToolTipProps {
    label?: string;
    width?: number;
    position?: ToolTipPosition;
    className?: string;
    color?: TooltipColor;
    delay?: number;
    addShadow?: boolean;
}

const getColorName = (color: TooltipColor) => {
    switch (color) {
        case TooltipColor.ERROR:
            return lxColor("error");
        case TooltipColor.SMOKE_DARK:
            return lxColor("smoke", "dark");
    }
};

const defaultToolTipWidth = 242;

let ToolTip: React.FC<React.PropsWithChildren<ToolTipProps>> = ({
    label,
    children,
    position = ToolTipPosition.BOTTOM,
    width = defaultToolTipWidth,
    className,
    color = TooltipColor.SMOKE_DARK,
    addShadow,
}) => {
    const cssColor = getColorName(color);
    const [open, setOpen] = React.useState(ToolTipState.CLOSED);
    const softOpen = () => setOpen((isOpen) => (isOpen === ToolTipState.CLOSED ? ToolTipState.SOFT_OPENED : isOpen));
    const softClose = () => setOpen((isOpen) => (isOpen === ToolTipState.SOFT_OPENED ? ToolTipState.CLOSED : isOpen));
    const hardToggle = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();
        setOpen((isOpen) => (isOpen === ToolTipState.HARD_OPENED ? ToolTipState.CLOSED : ToolTipState.HARD_OPENED));
    };
    const hardClose = () => setOpen(ToolTipState.CLOSED);

    const toolTipRef = React.useRef<HTMLDivElement>(null);
    const toolTipIconRef = React.useRef<HTMLDivElement>(null);

    return (
        <div
            ref={toolTipRef}
            className={classnames(
                blockName,
                `${blockName}--${position}`,
                { [`${blockName}--soft-open`]: open === ToolTipState.SOFT_OPENED },
                { [`${blockName}--hard-open`]: open === ToolTipState.HARD_OPENED },
                className,
                { "lxs-tool-tip__tool-tip--shadow": addShadow },
            )}
            tabIndex={1}
        >
            {label && (
                <p className="lxs-tool-tip__toggle-text" style={{ color: cssColor }}>
                    {label}
                </p>
            )}
            <div className="lxs-tool-tip__tool-tip">
                <div
                    className="lxs-tool-tip__toggle"
                    onClick={hardToggle}
                    onFocus={softOpen}
                    onBlur={hardClose}
                    onMouseEnter={softOpen}
                    onMouseLeave={softClose}
                    ref={toolTipIconRef}
                >
                    <SVGIcon
                        className="lxs-tool-tip__toggle-icon"
                        animationEvent={SVGAnimationEvents.CLICK}
                        containerRef={toolTipIconRef}
                        hasBackground={false}
                    >
                        <SVGInfoCircleAnimated color={cssColor} width={16} height={16} />
                    </SVGIcon>
                </div>
                {children !== undefined && (
                    <div
                        className={classnames("lxs-tool-tip__popup", { "lxs-tool-tip__popup-left": !label })}
                        style={{ width }}
                    >
                        <div className="lxs-tool-tip__popup__container">
                            <svg
                                className={classnames("lxs-tool-tip__popup-triangle", {
                                    "lxs-tool-tip__popup-triangle-left": !label,
                                })}
                                viewBox="0 0 100 100"
                            >
                                <polygon points="50, 50 100, 0 0, 0" />
                            </svg>
                            <div className="lxs-tool-tip__popup-content">{children}</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

ToolTip = React.memo(ToolTip);

export default ToolTip;
