import cn from "classnames";
import {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
  MouseEvent,
  Children,
} from "react";

import { GlobalStylesScope } from "@/components/GlobalStylesScope/GlobalStylesScope";
import { IconButton } from "@/components/IconButton/IconButton";
import { SVGInfo } from "@/components/SVGIcon/static/SVGInfo";
import { SVGIconSizes } from "@/components/SVGIcon/types";
import { Tooltip, TooltipPlacementProps } from "@/components/Tooltip/Tooltip";
import {
  TooltipPointerPosition,
  TooltipPopup,
} from "@/components/Tooltip/TooltipPopup/TooltipPopup";
import { useTooltipContext } from "@/contexts/TooltipContext";
import { useOnClickOutside } from "@/hooks/useOnClickOutside";
import { useOnEscKeyDown } from "@/hooks/useOnEscKeyDown";
import { ThemeContext } from "@/theming/ThemeContext";

import styles from "./TooltipWithIcon.module.scss";
import { FocusCatcher } from "../FocusCatcher/FocusCatcher";

export type TooltipWithIconProps = React.PropsWithChildren<{
  /**
   * Pointer position of the tooltip.
   */
  pointerPosition?: TooltipPointerPosition;

  /**
   * Set className for IconButton, default is empty.
   */
  className?: string;

  /**
   * The icon to display
   */
  icon?: React.ReactNode;

  /**
   * Icon color
   */
  iconColor?: TooltipIconColor;

  /**
   * Determines if the component has a shadow. Defaults to `true`.
   */
  addShadow?: boolean;

  /**
   * Determines if tooltip should show popup when onFocus (on tab via keyboard).
   */
  showPopupOnFocus?: boolean;

  /**
   * Determines if the popup is open/close on rendered. Default to close.
   */
  initialState?: TooltipState;

  /**
   * Allow tooltip to re-size on screen change
   */
  isPopupFixed?: boolean;

  /**
   * aria-label for the tooltip with icon.
   */
  ariaLabel: string;

  /**
   * Increase clickable area to assist accessibility
   */
  hasClickableArea?: boolean;
}>;

export enum TooltipState {
  CLOSED,
  SOFT_OPENED,
  HARD_OPENED,
}

export enum TooltipIconColor {
  DEFAULT,
  ACCENT,
}

const FOCUS_OUTLINE_OFFSET = 4;

const useOpenState = (initialState: TooltipState) => {
  const [open, setOpen] = useState(initialState);
  return [
    open,
    useMemo(
      () => ({
        softOpen: () =>
          setOpen((isOpen) => (isOpen === TooltipState.CLOSED ? TooltipState.SOFT_OPENED : isOpen)),
        softClose: () =>
          setOpen((isOpen) => (isOpen === TooltipState.SOFT_OPENED ? TooltipState.CLOSED : isOpen)),
        hardClose: () => setOpen(TooltipState.CLOSED),
        handleToggle: () =>
          setOpen((isOpen) =>
            isOpen === TooltipState.HARD_OPENED ? TooltipState.CLOSED : TooltipState.HARD_OPENED
          ),
      }),
      []
    ),
  ] as const;
};

const getTooltipPlacement = (
  position: TooltipPointerPosition,
  triggerEl: HTMLButtonElement,
  expandedTriggerEl?: HTMLDivElement
): TooltipPlacementProps => {
  const triggerWidthInHalf = triggerEl.getBoundingClientRect().width / 2;
  const expandedTriggerWidthInHalf =
    expandedTriggerEl && expandedTriggerEl.getBoundingClientRect().width / 2;

  const crossOffset = expandedTriggerWidthInHalf ? expandedTriggerWidthInHalf : triggerWidthInHalf;

  const offset = expandedTriggerWidthInHalf
    ? -(expandedTriggerWidthInHalf - (triggerWidthInHalf + FOCUS_OUTLINE_OFFSET))
    : FOCUS_OUTLINE_OFFSET;
  switch (position) {
    case "top-middle":
      return {
        placement: "bottom",
        offset,
      };
    case "top-left":
      return {
        placement: "bottom-start",
        offset,
        crossOffset: expandedTriggerWidthInHalf && crossOffset - triggerWidthInHalf,
      };
    case "top-right":
      return {
        placement: "bottom-end",
        offset,
        crossOffset: expandedTriggerWidthInHalf && -crossOffset + triggerWidthInHalf,
      };
    case "bottom-middle":
      return {
        placement: "top",
        offset,
      };
    case "bottom-left":
      return {
        placement: "top-start",
        offset,
        crossOffset: expandedTriggerWidthInHalf && crossOffset - triggerWidthInHalf,
      };
    case "bottom-right":
      return {
        placement: "top-end",
        offset,
        crossOffset: expandedTriggerWidthInHalf && -crossOffset + triggerWidthInHalf,
      };
    case "middle-left":
      return {
        placement: "right",
        offset,
      };
    case "middle-top-left":
      return {
        placement: "right-start",
        offset,
        crossOffset: crossOffset,
      };
    case "middle-bottom-left":
      return {
        placement: "right-end",
        offset,
        crossOffset: -crossOffset,
      };
    case "middle-right":
      return {
        placement: "left",
        offset,
      };
    case "middle-top-right":
      return {
        placement: "left-start",
        offset,
        crossOffset: crossOffset,
      };
    case "middle-bottom-right":
      return {
        placement: "left-end",
        offset,
        crossOffset: -crossOffset,
      };
    default:
      return { placement: "top" };
  }
};

/**
 * `TooltipWithIcon` renders an icon that when click will open/close a `TooltipPopup`.
 * It is recommended to use Lexus Kit Icon component for better scalability and performance.
 *
 * Usage:
 *
 * ```tsx
 * <TooltipWithIcon icon={<SVGIcon />}>
 *   {children}
 * </TooltipWithIcon>
 * ```
 */

let TooltipWithIcon: React.FC<TooltipWithIconProps> = ({
  children,
  icon = <SVGInfo width={SVGIconSizes.SMALL} height={SVGIconSizes.SMALL} />,
  iconColor,
  pointerPosition = "top-middle",
  showPopupOnFocus,
  hasClickableArea,
  isPopupFixed,
  className,
  addShadow = true,
  ariaLabel,
  initialState = TooltipState.CLOSED,
}) => {
  const popupContainerRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const expandedAreaRef = useRef<HTMLDivElement>(null);
  const [open, { hardClose, softOpen, softClose, handleToggle }] = useOpenState(initialState);
  const [toolTipPlacement, setTooltipPlacement] = useState<TooltipPlacementProps>(
    {} as TooltipPlacementProps
  );

  const themeDefinition = useContext(ThemeContext);

  useOnEscKeyDown(!!open, hardClose);

  useOnClickOutside([popupContainerRef, hasClickableArea ? expandedAreaRef : buttonRef], () => {
    if (open) hardClose();
  });

  const [tooltip] = useTooltipContext();
  const tooltipContent = children || tooltip;
  useEffect(() => {
    const buttonEl = buttonRef.current;
    const expandedAreaEl = expandedAreaRef.current;
    if (buttonEl) {
      setTooltipPlacement(
        getTooltipPlacement(pointerPosition, buttonEl, expandedAreaEl ? expandedAreaEl : undefined)
      );
    }
  }, [hasClickableArea, pointerPosition, tooltipContent]);

  // listen for container resize events and hide popup
  useEffect(() => {
    const buttonEl = buttonRef.current;
    if (!buttonEl) {
      return;
    }
    const resizeObserver = new ResizeObserver((entries) => {
      const parentVisible = (entries[0].target as HTMLDivElement).offsetParent;
      if (!parentVisible && popupContainerRef.current) {
        popupContainerRef.current.style.display = "none";
        hardClose();
      }
    });
    resizeObserver.observe(buttonEl);

    return () => {
      resizeObserver.disconnect();
    };
  }, [buttonRef, hardClose]);

  useEffect(() => {
    if (
      [TooltipState.SOFT_OPENED, TooltipState.HARD_OPENED].includes(open) &&
      popupContainerRef.current
    ) {
      if (open) {
        const focusableElements = popupContainerRef.current.querySelectorAll<HTMLElement>(
          'a, button, input, textarea, select, [tabindex]:not([tabindex^="-"])'
        );
        if (focusableElements.length > 2) {
          focusableElements.item(1).focus();
        }
      }
    }
  }, [open]);

  const handleBlur = useCallback(
    (event: React.FocusEvent<Element>) => {
      if (popupContainerRef.current?.contains(event.relatedTarget as Node)) {
        return;
      }
      showPopupOnFocus && softClose();
    },
    [showPopupOnFocus, softClose]
  );

  const handleTooltipToggle = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      // prevent tooltip from propagating on click to its parent element e.g. used within GenericTile
      e.preventDefault();
      e.stopPropagation();
      handleToggle();
    },
    [handleToggle]
  );

  const handleFocusCatch = useCallback(() => {
    buttonRef.current?.focus();
    hardClose();
  }, [hardClose]);

  const IconButtonInternal = () => (
    <IconButton
      aria-label={ariaLabel}
      aria-controls={`${ariaLabel} popup`}
      aria-owns={`${ariaLabel} popup`}
      data-testid={`lk-tooltipWithIcon-${pointerPosition}`}
      isShadowed={addShadow}
      className={cn(styles.toolTipTrigger, {
        [`${styles.iconColorAccent}`]: iconColor === TooltipIconColor.ACCENT,
        [`${styles.isShadow}`]: addShadow,
        [`${styles.clickableArea}`]: hasClickableArea,
      })}
      icon={icon}
      onClick={handleTooltipToggle}
      onFocus={showPopupOnFocus ? softOpen : undefined}
      onBlur={handleBlur}
      onMouseEnter={showPopupOnFocus ? softOpen : undefined}
      onMouseLeave={showPopupOnFocus ? softClose : undefined}
      variant="bare"
      buttonRef={buttonRef}
      isRounded={true}
    />
  );
  return !!Children.count(tooltipContent) ? (
    <Tooltip
      open={!!open}
      {...toolTipPlacement}
      isPopupFixed={isPopupFixed}
      className={cn(styles.tooltipWithIcon, className)}
    >
      <Tooltip.Anchor>
        {hasClickableArea ? (
          <div ref={expandedAreaRef} onClick={handleTooltipToggle} className={styles.clickableArea}>
            <IconButtonInternal />
          </div>
        ) : (
          <IconButtonInternal />
        )}
      </Tooltip.Anchor>
      <Tooltip.Overlay>
        <GlobalStylesScope themeDefinition={themeDefinition.currentTheme}>
          <div ref={popupContainerRef}>
            {/* hidden button acts as a return point to icon */}
            <FocusCatcher onFocus={handleFocusCatch} />
            <TooltipPopup ariaLabel={`${ariaLabel} popup`} pointerPosition={pointerPosition}>
              {tooltipContent}
            </TooltipPopup>
            <FocusCatcher onFocus={handleFocusCatch} />
          </div>
        </GlobalStylesScope>
      </Tooltip.Overlay>
    </Tooltip>
  ) : null;
};

TooltipWithIcon = memo(TooltipWithIcon);

export { TooltipWithIcon };
