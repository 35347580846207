import cn from "classnames";

import { Box } from "@/components/Box/Box";
import { CardTopPaddings } from "@/components/Card/Card.types";

import styles from "./Card.module.scss";

export type CardType = "topElevate" | "topEncore" | "topPlatinum" | "topElectrified";

export type CardTopProps = {
  /**
   * header background will be transparent if omitted
   */
  background?: CardType;
  /**
   * Default is has vertical and horizontal paddings, none has no padding at all.
   */
  padding?: CardTopPaddings;
  /**
   * Set className, default is empty.
   */
  className?: string;
};

export const CardTop: React.FC<React.PropsWithChildren<CardTopProps>> = ({
  background,
  children,
  padding,
  className,
}) => (
  <Box
    px={padding === "none" ? "none" : "s"}
    py={padding === "none" ? "none" : "l"}
    className={cn(className, styles.cardTop, background && styles[background])}
  >
    {children}
  </Box>
);
