import { createContext } from "react";

import { TypographyPlugin } from "./typographyTypes";
import { disclaimerReplacer } from "../Disclaimer/plugins/disclaimersReplacer";
import { childrenWrapper } from "../Typography/plugins/childrenWrapper";
import { lineBreakReplacer } from "../Typography/plugins/lineBreakReplacer";

interface TypographyContextProps {
  /**
   * List of Typography processors/plugins.
   * IMPORTANT: To support full Typography functionality always consider inclusion of  {@link importantFinalPlugins}
   * at the end of your list when you set {@link skipFinalPlugins}.
   * Use {@link defaultTypographyPlugins} to include standard list of Lexus-Kit plugins.
   */
  plugins: Array<TypographyPlugin>;

  /**
   * Do not add any "must-have" plugins. If set to `true` you have to include {@link importantFinalPlugins} yourself
   * in correct order, ideally at the end of {@link plugins}
   */
  skipFinalPlugins?: boolean;
}

const importantFinalPlugins: TypographyPlugin[] = [{ order: 10000, exec: childrenWrapper }];

const defaultTypographyPlugins: TypographyPlugin[] = [lineBreakReplacer, disclaimerReplacer];

const TypographyContext = createContext<TypographyContextProps>({
  plugins: defaultTypographyPlugins,
});

export { TypographyContext, importantFinalPlugins, defaultTypographyPlugins };
