import { useState } from "react";

import { useResizeObserver } from "./useResizeObserver";

/**
 * useElementWidth tracks the width changes of an element
 *
 * ## Note
 * On server side in SSR it will return undefined
 *
 * @param rectRef - React ref to the element
 * @returns - Width of the element
 */
export const useElementWidth = (rectRef: React.RefObject<Element | null> | undefined) => {
  const [width, setWidth] = useState<number | undefined>(undefined);

  useResizeObserver(rectRef || { current: null }, (rect) => {
    rect?.width && setWidth(rect.width);
  });

  return width;
};
