import styles from "./SectionContentContainer.module.scss";
import { Container } from "../Container/Container";

export type SectionContentContainerWidth = "full" | "6col" | "8col" | "10col" | "12col";

type SectionContentContainerProps = React.PropsWithChildren<
  React.HTMLAttributes<HTMLDivElement> & {
    /**
     * Width limitation for inner content.
     */
    width?: SectionContentContainerWidth;

    /**
     * Class name(s) to customise component if required.
     */
    className?: string;

    /**
     * Ref to inner Container `div` element.
     */
    divRef?: React.Ref<HTMLDivElement>;
  }
>;

/**
 * Section Content Container is a component which is designed to apply certain
 * constraints on inner section content. It limits maximum content width and ensures that
 * content always fit to the screen size without horizontal scrolling effects.
 *
 * SectionContentContainer is based on `Container`, which is based on `div` element and supports
 * all default attributes for `div` element. (className will be merged with internal classes)
 *
 * Usage:
 *
 * ```tsx
 * import { Section, SectionContentContainer } from "lexus-kit";
 * ...
 * <Section>
 *  <SectionContentContainer width="6col" role="...">
 *   <h1>I'm content in a narrow section!</h1>
 *  </SectionContentContainer>
 * </Section>
 * ```
 */
export const SectionContentContainer: React.FC<SectionContentContainerProps> = ({
  width = "12col",
  children,
  divRef,
  ...rest
}) => (
  <Container
    maxWidth={width === "full" ? "none" : width}
    {...rest}
    divRef={divRef}
    className={styles.fullWidthContainer}
  >
    {children}
  </Container>
);
