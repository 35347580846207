import cn from "classnames";
import { useRef } from "react";

import { Chip } from "@/components/Chip";
import { Divider } from "@/components/Divider";
import { Responsive } from "@/components/Responsive/Responsive";
import { Stack } from "@/components/Stack/Stack";
import { SVGCheckMark } from "@/components/SVGIcon/static/SVGCheckMark";
import { Typography } from "@/components/Typography/Typography";
import { useScrollIntoView } from "@/hooks/useScrollIntoView";

import styles from "./ProgressStepper.module.scss";

type ProgressStepperProps = React.HTMLAttributes<HTMLDivElement>;

/**
 * A component used to indicate progress in a process/experience with multiple steps.
 *
 * ```tsx
 * <ProgressStepper>
 *    {progressSteps.map(({ children, ...restOf }, index) => (
 *      <ProgressStep key={index} {...restOf}>{children}</ProgressStep>
 *    ))}
 * </ProgressStepper>
 * ```
 */
const ProgressStepper: React.FC<ProgressStepperProps> = ({ children, className, ...rest }) => {
  return (
    <Stack
      direction="row"
      spacing="none"
      className={cn(styles.progressStepper, className)}
      {...rest}
    >
      {children}
    </Stack>
  );
};

export type SuccessMarkType = "default" | "checkmark";

export type ProgressStepProps = React.HTMLAttributes<HTMLDivElement> & {
  step?: number;
  selected?: boolean;
  success?: boolean;
  successMark?: SuccessMarkType;
};

interface ChipContentProps extends Omit<ProgressStepProps, "successMark"> {
  size?: "small" | "large";
}

const ChipContent: React.FC<React.PropsWithChildren<ChipContentProps>> = ({
  success,
  selected,
  children,
  size = "large",
}) => (
  <Chip
    state={success ? "success" : selected ? "selected" : "default"}
    size={size}
    variant="ghost"
    className={styles.chip}
    isInteractive
  >
    {children}
  </Chip>
);

export const ProgressStep: React.FC<ProgressStepProps> = ({
  children,
  className,
  step,
  selected,
  success,
  successMark = "default",
  ...rest
}) => {
  const selectedStepRef = useRef<HTMLDivElement>(null);

  useScrollIntoView({ ref: selectedStepRef, dependency: selected });

  return (
    <>
      <Stack
        className={cn(styles.progressStep, className)}
        direction="column"
        spacing="4xs"
        {...rest}
      >
        <Stack
          spacing="4xs"
          justifyContent="center"
          alignItems="center"
          className={styles.progressStepperInner}
        >
          <div className={styles.chipContainer} ref={selected ? selectedStepRef : null}>
            {successMark === "checkmark" ? (
              <>
                <Responsive breakpoint="small">
                  <ChipContent success={success} selected={selected} size="small">
                    {success ? <SVGCheckMark width={16} height={16} /> : step}
                  </ChipContent>
                </Responsive>
                <Responsive breakpoint="large">
                  <ChipContent success={success} selected={selected}>
                    {success ? <SVGCheckMark width={16} height={16} /> : step}
                  </ChipContent>
                </Responsive>
              </>
            ) : (
              <ChipContent success={success} selected={selected}>
                {step}
              </ChipContent>
            )}
          </div>
        </Stack>

        <div className={cn(styles.text, { [styles.disabled]: !selected })}>
          <Typography variant="l1" className={styles.text}>
            {children}
          </Typography>
        </div>
      </Stack>
      <div className={styles.divider}>
        <Divider variant="dark" />
      </div>
    </>
  );
};

export { ProgressStepper };
