import cn from "classnames";

import { Box } from "@/components/Box/Box";
import { GenericLink } from "@/components/GenericLink/GenericLink";
import { IconLinkProps } from "@/components/IconLink/IconLink.types";
import { Stack } from "@/components/Stack/Stack";
import { SVGChevronRight } from "@/components/SVGIcon/static/SVGChevronRight";
import { Typography } from "@/components/Typography/Typography";
import { mergeProps } from "@/utils/reactExtensions";

import styles from "./IconLink.module.scss";

/**
 * A component used as a tertiary call-out button.
 *
 * ## Usage
 *
 * ```tsx
 * <IconLink href="#" target="_blank">Link Text</IconLink>
 * ```
 *
 * ## Note
 * "end" and "start" positions will not have any hover state,
 * consumer must ensure to apply some background or other highlight
 */
const IconLink: React.FC<IconLinkProps> = ({
  children,
  icon,
  variant,
  iconPosition = "animated",
  ...rest
}) => (
  <GenericLink
    variant="quiet"
    hideExternalIcon
    {...mergeProps(rest, { className: styles.iconLink })}
  >
    <Box py={variant === "spaced" ? "3xs" : "none"}>
      <Stack
        spacing="4xs"
        className={cn(styles.container, {
          [styles.disabled]: rest.disabled,
          [styles.animated]: iconPosition === "animated",
        })}
      >
        {["animated", "start"].includes(iconPosition) && (
          <span className={cn(styles.icon, styles.iconLeft)} aria-hidden="true">
            {icon || <SVGChevronRight />}
          </span>
        )}
        <Typography variant="l1" className={styles.text}>
          {children}
        </Typography>
        {["animated", "end"].includes(iconPosition) && (
          <span className={cn(styles.icon, styles.iconRight)} aria-hidden="true">
            {icon || <SVGChevronRight />}
          </span>
        )}
      </Stack>
    </Box>
  </GenericLink>
);

export { IconLink };
