import classnames from "classnames";
import { createBemFn } from "lexus-style-guide/utilities/bem";
import * as React from "react";
import { useSelector } from "react-redux";
import { CollapseState, mobileSecondaryNavigationOpenSelector } from "ReduxSlices/general";
import { guestDetailsSelector, guestErrorSelector } from "ReduxSlices/user";
import { ClassNameProp } from "Types/general";
import Spinner from "../Spinner/Spinner";
import "./Page.scss";
import PWANavigation from "Components/PWANavigation/PWANavigation";

const bem = createBemFn("page");

interface Props extends ClassNameProp {
    title: string;
    lockScroll?: boolean;
}

const Page: React.FC<React.PropsWithChildren<Props>> = ({
    children,
    title,
    lockScroll: lockScrollProp = false,
    className,
}) => {
    //#region hooks
    const guestDetails = useSelector(guestDetailsSelector);
    const guestDetailsError = useSelector(guestErrorSelector);
    const mobileSecondaryNavigationOpen = useSelector(mobileSecondaryNavigationOpenSelector) !== CollapseState.Closed;
    React.useEffect(() => {
        document.title = `Lexus - ${title}`;
    }, [title]);
    //#endregion

    const isReadyToRender = !!guestDetails || !!guestDetailsError;
    const lockScroll = lockScrollProp || mobileSecondaryNavigationOpen;

    return !isReadyToRender ? (
        <Spinner />
    ) : (
        <div className={classnames(bem(), { [bem(undefined, "lock-scroll")]: lockScroll }, className)}>
            <div className={bem("content")}>{children}</div>
            <PWANavigation />
        </div>
    );
};

export default Page;
