import type { CSSProperties } from "react";

import styles from "./BorderRadius.module.scss";
import { BorderRadiusScale, mapBorderRadiusToCSSVar } from "./BorderRadius.utils";

/**
 * A Headless component that generates the border radius styles based on the provided border radius scale.
 *
 * @param {Object} params - The parameters for the function.
 * @param {BorderRadiusScale} [params.borderRadius='extra-medium'] - The border radius scale.
 * @returns {Object} The border radius styles.
 * @returns {CSSProperties} return.style - The CSS properties for the border radius.
 *
 * @example
 * const borderRadiusProps = getBorderRadiusProps({ borderRadius: 'large' });
 * Output: { style: { borderRadius: 'var(--border-radius-large)' } }
 */

export const getBorderRadiusProps = (borderRadius: BorderRadiusScale) => {
  const borderRadiusVar = mapBorderRadiusToCSSVar(borderRadius);

  const borderRadiusStyles: CSSProperties = {
    borderRadius: `var(${borderRadiusVar})`,
  };

  return {
    style: borderRadiusStyles,
    className: styles.borderRadius,
  };
};
