import cn from "classnames";

import { Box } from "@/components/Box/Box";
import { Stack } from "@/components/Stack/Stack";

import styles from "./Cell.module.scss";
import { JustifyContentValue } from "../types";

export type CellVerticalAlign = "top" | "center" | "bottom";

export type CellProps = React.HTMLAttributes<HTMLDivElement> & {
  /**
   * When true, cell will have border radius.
   */
  hasCellBorderRadius?: boolean;

  /**
   * Class name(s) to customize component if required.
   */
  className?: string;

  /**
   * Cell padding. Available options: "none" | "default". Default will be "even-more".
   */
  cellPadding?: "none" | "default";

  /**
   * Cell gap. Available options: "none" | "default". Default will be "l".
   */
  cellGap?: "none" | "default";

  /**
   * Cell vertical align. Available options: "top" | "center" | "bottom".
   */
  verticalAlign?: CellVerticalAlign;

  /**
   * Tab index for cell.
   */
  tabIndex?: number | undefined;
};

const alignmentMap: { [key in CellVerticalAlign]: JustifyContentValue } = {
  center: "center",
  top: "flex-start",
  bottom: "flex-end",
};

/**
 * `Cell` component should be used inside `Row` component. It is intended to be used as a grid item.
 * Consumers can put any content inside a `Cell` component.
 */
export const Cell: React.FC<CellProps> = ({
  hasCellBorderRadius,
  children,
  className,
  cellGap = "default",
  cellPadding = "default",
  verticalAlign = "center",
  tabIndex = 0,
  ...rest
}) => (
  <Stack
    component={Box}
    spacing={cellGap === "default" ? "l" : "none"}
    direction="column"
    justifyContent={alignmentMap[verticalAlign]}
    className={cn(styles.cell, className, {
      [styles.cellBorderRadius]: hasCellBorderRadius,
    })}
    p={cellPadding === "default" ? "even-more" : "none"}
    tabIndex={tabIndex}
    {...rest}
  >
    {children}
  </Stack>
);
