import { useEffect, useRef } from "react";

interface UseScrollIntoViewProps<T> {
  ref: React.RefObject<HTMLElement>;
  dependency: T;
  options?: IntersectionObserverInit;
  scrollOptions?: ScrollIntoViewOptions;
}

export const useScrollIntoView = <T>({ ref, dependency }: UseScrollIntoViewProps<T>) => {
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          ref.current?.scrollIntoView({ inline: "center", block: "nearest" });
        }
      },
      { threshold: 0 }
    );

    observerRef.current = observer;

    if (ref.current && ref.current?.parentElement) {
      observer.observe(ref.current.parentElement);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [ref, dependency]);
};
