import { Children } from "react";
import {
  withGroupedChildren,
  type WithGroupedChildrenComponent,
  type GroupedChildrenProps,
} from "react-grouped-children";

import styles from "./Oriented.module.scss";

export type OrientationType = "landscape" | "portrait";

export type OrientedContainerProps = React.PropsWithChildren<{
  /**
   * The specified default orientation will be shown by default and hidden only
   * with when the opposite container works. Since not all mobile browsers support
   * container queries prefer mobile-like orientation
   */
  defaultOrientation?: OrientationType;

  /**
   * Use this property to display all children and ignore orientation rules.
   * Helpful when you need to display component in editable mode
   */
  showAll?: boolean;
}>;

const OrientedContainer: React.FC<
  OrientedContainerProps & GroupedChildrenProps<typeof childrenSpec>
> = ({ landscape, portrait, defaultOrientation, showAll }) => {
  const landscapeChildren = Children.toArray(landscape);
  const portraitChildren = Children.toArray(portrait);

  const getClass = (type: "landscape" | "portrait"): string => {
    return showAll
      ? styles.all
      : defaultOrientation === type
      ? styles[`${type}Default`]
      : styles[type];
  };

  return (
    <>
      {!!landscapeChildren.length && (
        <div className={getClass("landscape")}>{landscapeChildren}</div>
      )}
      {!!portraitChildren.length && <div className={getClass("portrait")}>{portraitChildren}</div>}
    </>
  );
};

const childrenSpec = {
  Landscape: null,
  Portrait: null,
} as const;

const childrenMapperAndValidator = (children: React.ReactNode | React.ReactNode[]) => {
  const uniqueSet = new Set();
  const filtered = Children.toArray(children).filter((child) => {
    if (
      typeof child === "object" &&
      "type" in child &&
      (child.type === Oriented.Landscape || child.type === Oriented.Portrait) &&
      !uniqueSet.has(child.type)
    ) {
      uniqueSet.add(child.type);
      return true;
    } else {
      console.error(
        `Oriented component just accepts single instance of Oriented.Landscape or Oriented.Portrait as children! Unexpected:`,
        child
      );
      return false;
    }
  });
  return filtered;
};
const Component: WithGroupedChildrenComponent<typeof childrenSpec, OrientedContainerProps> =
  withGroupedChildren({
    childrenSpec,
    childrenToArray: childrenMapperAndValidator,
  })(OrientedContainer);

/**
 * The Oriented component renders its children based on the specified orientation of it's container.
 * If the does not support container queries, it will render its children based on the orientation of the viewport.
 * It supports both landscape and portrait orientations.
 *
 * ## Usage
 *
 * ```tsx
 * import { Oriented } from "lexus-kit";
 *
 * <Oriented orientation="landscape">
 *   <Oriented.Landscape>
 *     <img alt="landscape" src="path/to/landscape.jpg" />
 *   </Oriented.Landscape>
 *   <Oriented.Portrait>
 *     <img alt="portrait" src="path/to/portrait.jpg"/>
 *   </Oriented.Portrait>
 * </Oriented>
 * ```
 */
export const Oriented = Component;
