import { useCallback, useContext, useState, useEffect, useRef } from "react";
import {
  ComboBoxProps as AutocompletePropsPrimitiveProps,
  ComboBox as AutocompletePrimitive,
  Popover as PopoverPrimitive,
  ListBox as ListBoxPrimitive,
  Button,
  ComboBoxStateContext,
} from "react-aria-components";

import { getBorderRadiusProps } from "@/components/BorderRadius/BorderRadius";
import { FormContainer } from "@/components/FormContainer/FormContainer";
import { FormHelperText } from "@/components/FormHelperText/FormHelperText";
import { GlobalStylesScope } from "@/components/GlobalStylesScope/GlobalStylesScope";
import { InputLabel } from "@/components/InputLabel/InputLabel";
import { Stack } from "@/components/Stack/Stack";
import { TextInput, TextInputProps } from "@/components/TextInput/TextInput";
import { useElementWidth } from "@/hooks/useElementWidth";
import { ThemeContext } from "@/theming/ThemeContext";
import { mergeProps } from "@/utils/reactExtensions";

import styles from "./Autocomplete.module.scss";
import { PopoverLayout } from "../Popover/PopoverLayout";

export interface AutocompleteProps<T extends object>
  extends Omit<AutocompletePropsPrimitiveProps<T>, "children"> {
  /**
   * The content to display as the label.
   */
  label?: React.ReactNode;
  /**
   * A description for the field. Provides a hint such as specific requirements for what to choose.
   */
  description?: React.ReactNode;
  /**
   * An error message for the field.
   */
  errorMessage?: React.ReactNode;
  children: React.ReactNode | ((item: T) => React.ReactNode);
  /**
   * The list of ComboBox items (uncontrolled).
   */
  defaultItems?: AutocompletePropsPrimitiveProps<T>["defaultItems"];
  /**
   * The list of ComboBox items (controlled).
   */
  items?: AutocompletePropsPrimitiveProps<T>["items"];
  /**
   * The value of the ComboBox input (controlled).
   */
  inputValue?: AutocompletePropsPrimitiveProps<T>["inputValue"];

  /**
   * The currently selected key in the collection (controlled).
   */
  selectedKey?: AutocompletePropsPrimitiveProps<T>["selectedKey"];
  /**
   * The initial selected key in the collection (uncontrolled).
   */
  defaultSelectedKey?: AutocompletePropsPrimitiveProps<T>["defaultSelectedKey"];
  /**
   * Whether the input is disabled.
   */
  isDisabled?: AutocompletePropsPrimitiveProps<T>["isDisabled"];
  /**
   * Whether the input can be selected but not changed by the user.
   */
  isReadOnly?: AutocompletePropsPrimitiveProps<T>["isReadOnly"];
  /**
   * The short hint displayed in the input before the user enters a value.
   */
  placeholder?: string;
  /**
   * Whether the autocomplete is fetching data
   */
  isLoading?: boolean;

  /**
   * Prefix icon to display in the input field
   */
  prefixIcon?: React.ReactNode;

  /**
   * Enable block editing
   */
  isNonEditable?: boolean;
}

const TextInputInternal: React.FC<
  TextInputProps & {
    isLoading?: boolean;
    isNonEditable?: boolean;
    onInputChange?: (e: string) => void;
  }
> = ({
  onClearInput,
  onInputChange,
  isLoading,
  placeholder: defaultPlaceholder,
  isNonEditable = true,
  ...rest
}) => {
  const [textInputValues, setTextInputValues] = useState<{
    value: string;
    currSelectedKey: string | null;
    placeholder: string | undefined;
  }>({
    value: "",
    currSelectedKey: null,
    placeholder: defaultPlaceholder,
  });
  const autocompleteState = useContext(ComboBoxStateContext);
  const { selectedItem, selectedKey, isOpen, inputValue } = autocompleteState || {};

  useEffect(() => {
    if (!isOpen && inputValue === selectedItem?.textValue)
      setTextInputValues((prev) => ({
        ...prev,
        value: isNonEditable ? "" : inputValue,
        placeholder: isNonEditable ? selectedItem?.textValue : undefined,
        currSelectedKey: String(selectedKey),
      }));
  }, [
    selectedItem?.textValue,
    selectedKey,
    selectedItem,
    textInputValues.currSelectedKey,
    isNonEditable,
    isOpen,
    inputValue,
  ]);

  const handleOnChange = (e: string) => {
    onInputChange?.(e);
    setTextInputValues((prev) => ({ ...prev, value: e }));
  };

  const handleClearInput = useCallback(() => {
    onClearInput?.();
    autocompleteState?.setSelectedKey(null);
    setTextInputValues((prev) => ({
      ...prev,
      value: "",
      placeholder: isNonEditable ? undefined : defaultPlaceholder,
    }));
  }, [autocompleteState, isNonEditable, onClearInput, defaultPlaceholder]);

  const handleOnBlur = () => {
    if (!isNonEditable) return;
    setTextInputValues((prev) => ({
      ...prev,
      value: "",
    }));
  };

  return (
    <TextInput
      value={textInputValues.value}
      clearable={!!textInputValues.value || !!selectedItem || !!selectedKey}
      onClearInput={handleClearInput}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      placeholder={textInputValues.placeholder || defaultPlaceholder}
      isLoading={isLoading}
      className={selectedKey ? styles.isSolidPlaceholder : undefined}
      type="search"
      {...rest}
    />
  );
};

/**
 * The Autocomplete combines a text input with a listbox, allowing users to filter a list of options to items matching a query.
 * On select, text will be cleared and selected option will be shown as placeholder
 *
 * ## State
 * ### Uncontrolled
 * The Autocomplete's `inputValue` is empty by default, but an initial, uncontrolled, a default selected option can be provided using the `defaultSelectedKey` prop.
 *
 * ```tsx
 * <Autocomplete defaultSelectedKey="Hello" />
 * ```
 *
 * ### Controlled
 * The `inputValue` prop can be used to make the `inputValue` controlled. The `onInputChange` event is fired when the user edits the text, and receives the new `inputValue`.
 *
 */
export function Autocomplete<T extends object>({
  children,
  label,
  description,
  errorMessage,
  placeholder,
  isLoading,
  onInputChange,
  onSelectionChange,
  prefixIcon,
  isNonEditable,
  ...props
}: AutocompleteProps<T>) {
  const rectTriggerRef = useRef(null);
  const themeDefinition = useContext(ThemeContext);
  const hasDescription = Boolean(description);
  const showErrorMessage = Boolean(errorMessage);
  const showDescription = hasDescription && !showErrorMessage;

  const width = useElementWidth(rectTriggerRef);
  const widthPx = width ? `${width}px` : undefined;

  return (
    <AutocompletePrimitive
      onSelectionChange={onSelectionChange}
      {...props}
      className={styles.autocomplete}
    >
      <Stack direction="column" spacing="3xs">
        {label && <InputLabel>{label}</InputLabel>}
        <div ref={rectTriggerRef}>
          <TextInputInternal
            onInputChange={onInputChange}
            placeholder={placeholder}
            isInvalid={props.isInvalid}
            isLoading={isLoading}
            prefixIcon={prefixIcon}
            isNonEditable={isNonEditable}
            isDisabled={props.isDisabled}
          />
        </div>
        {/* Hidden button - do not remove. React Aria Component requires this button to be present for full autocomplete
        functionality. Position set to absolute so it doesn't interfere with the document flow  */}
        <Button style={{ position: "absolute" }} />

        <PopoverPrimitive style={{ width: widthPx }} triggerRef={rectTriggerRef}>
          <GlobalStylesScope themeDefinition={themeDefinition.currentTheme}>
            <FormContainer>
              <PopoverLayout useTriggerWidth style={{ width: widthPx }}>
                <ListBoxPrimitive
                  {...mergeProps(getBorderRadiusProps("small"), {
                    className: styles.listbox,
                  })}
                >
                  {children}
                </ListBoxPrimitive>
              </PopoverLayout>
            </FormContainer>
          </GlobalStylesScope>
        </PopoverPrimitive>

        {showDescription && <FormHelperText>{description}</FormHelperText>}
        {showErrorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
      </Stack>
    </AutocompletePrimitive>
  );
}
