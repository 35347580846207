import React from 'react';
import { ComponentFactoryReactContext } from '../components/SitecoreContext';
import { useContext } from 'react';
/**
 * @param {React.ComponentClass<T> | React.FC<T>} Component
 */
export function withComponentFactory(Component) {
    /**
     * @param {T} props - props to pass to the wrapped component
     * @returns {JSX.Element} - the rendered component
     */
    function WithComponentFactory(props) {
        const context = useContext(ComponentFactoryReactContext);
        return React.createElement(Component, Object.assign({}, props, { componentFactory: props.componentFactory || context }));
    }
    WithComponentFactory.displayName = `withComponentFactory(${Component.displayName ||
        Component.name ||
        'Anonymous'})`;
    return WithComponentFactory;
}
