import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGSearchThin: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6.62a6.62 6.62 0 1 1 11.968 3.907l-.172.234L16 14.966 14.966 16l-4.205-4.204-.234.172A6.62 6.62 0 0 1 0 6.62m6.62-5.157a5.158 5.158 0 1 0 0 10.316 5.158 5.158 0 0 0 0-10.316"
    />
  </svg>
);

SVGSearchThin = memo(SVGSearchThin);

export { SVGSearchThin };
