import { Stack } from "@/components/Stack/Stack";

interface RadioGroupItemsProps {
  orientation: "vertical" | "horizontal";
}

export const RadioGroupItems: React.FC<React.PropsWithChildren<RadioGroupItemsProps>> = ({
  orientation,
  children,
}) => {
  const isVertical = orientation === "vertical";
  return (
    <Stack
      spacing="3xs"
      flexWrap="wrap"
      direction={isVertical ? "column" : "row"}
      {...(isVertical && { alignItems: "stretch" })}
    >
      {children}
    </Stack>
  );
};
