import cn from "classnames";

import { Box } from "@/components/Box/Box";

import styles from "./TopBanner.module.scss";
import { Typography } from "../Typography/Typography";

export type TopBannerProps = React.HTMLAttributes<HTMLDivElement>;

/**
 * A non-sticky type of banner which will sit at the top of the page on the [Lexus](http://lexus.com.au/) site.
 *
 * ## Usage
 *
 * ```tsx
 *  <TopBanner>Your content here</TopBanner>
 * ```
 */
const TopBanner: React.FC<TopBannerProps> = ({ children, className, ...rest }) => (
  <Box p="even-less" className={cn(styles.topBanner, className)} {...rest}>
    <Typography variant="b1">{children}</Typography>
  </Box>
);

export { TopBanner };
