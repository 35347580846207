import cn from "classnames";

import { ThemeSurfaceShade, ThemeSurfaceType } from "@/theming/themingTypes";
import { kebabToCamelCase, kebabToPascalCase } from "@/utils/stringUtils";

import styles from "./Surface.module.scss";

type HeadlessSurfaceProps = {
  className: string;
};

const TYPES_WITH_SHADES = ["canvas", "elevation-inset", "elevation-raised"] as const;

type TypesWithShades = (typeof TYPES_WITH_SHADES)[number];

const isTypeWithShade = (type: ThemeSurfaceType | undefined): type is TypesWithShades =>
  !!TYPES_WITH_SHADES.find((t) => t === type);

const getShadedClassName = (
  type: TypesWithShades,
  shade: ThemeSurfaceShade | undefined = "default"
) => `${kebabToCamelCase(type)}${kebabToPascalCase(shade)}` as const;

interface SurfaceOptions {
  /**
   * Type defines type of surface. Default: "canvas".
   */
  type?: ThemeSurfaceType;

  /**
   * Shade defines shade of background color. Default: "default".
   */
  shade?: ThemeSurfaceShade;

  /**
   * If true, surface will highlight on hover. Default: false.
   */
  highlightOnHover?: boolean;

  /**
   * If true, only custom props will be assigned, so consumer can apply
   * `--lk-surface-color` conditionally when needed. Default: false.
   */
  customPropsOnly?: boolean;
}

/**
 * A Headless component that provides solid surface to other elements and sets default foreground color.
 * @param {SurfaceOptions} options - Options to define surface
 * @returns A class name that sets surface background and fore color
 *
 * ## Usage
 * ```tsx
 * const surfaceProps = getSurfaceProps();
 * // OR
 * const surfaceProps = getSurfaceProps({type: "elevation-inset", shade: "lighter"});
 * // OR
 * const surfaceProps = getSurfaceProps({ type: "highlight", customPropsOnly: true }),
 *
 * return <div {...surfaceProps}>Content</div>;
 * ```
 *
 * ## Usage with `customPropsOnly`
 * ```css
 * &[data-focused] {
 *   /* stylelint-disable-next-line csstools/value-no-unknown-custom-properties * /
 *   background-color: var(--lk-surface-color);
 * }
 * ```
 *
 * ## Note
 * `shade` is only applicable for `canvas`, `elevation-inset` and `elevation-raised`
 * types. Use `default` or `undefined` for other types.
 *
 * ## Tips
 *  - You can use `--lk-surface-color` CSS custom property in your components. This is helpful if you want to match
 *  the surface color in a gradient background.
 */
export function getSurfaceProps(options?: SurfaceOptions): HeadlessSurfaceProps;
/**
 * @deprecated Use `getSurfaceProps(options?: SurfaceOptions)` instead.
 * A Headless component that provides solid surface to other elements and sets default foreground color.
 * @param {ThemeSurfaceType} type - Type defines type of surface. Default: "canvas".
 * @param {ThemeSurfaceShade} shade - Shade defines shade of background color. Default: "default".
 * @param {boolean} highlightOnHover - If true, surface will highlight on hover. Default: false.
 * @param {boolean} customPropsOnly - If true, only custom props will be assigned, so consumer can apply
 *                          `--lk-surface-color` conditionally when needed. Default: false.
 * @returns A class name that sets surface background and fore color
 *
 * ## Usage
 * ```tsx
 * const surfaceProps = getSurfaceProps();
 * // OR
 * // const surfaceProps = getSurfaceProps("elevation-inset", "lighter");
 *
 * return <div {...surfaceProps}>Content</div>;
 * ```
 *
 * ## Usage with `customPropsOnly`
 * ```css
 * &[data-focused] {
 *   /* stylelint-disable-next-line csstools/value-no-unknown-custom-properties * /
 *   background-color: var(--lk-surface-color);
 * }
 * ```
 *
 * ## Note
 * `shade` is only applicable for `canvas`, `elevation-inset` and `elevation-raised`
 * types. Use `default` or `undefined` for other types.
 *
 * ## Tips
 *  - You can use `--lk-surface-color` CSS custom property in your components. This is helpful if you want to match
 *  the surface color in a gradient background.
 */
export function getSurfaceProps(
  type?: ThemeSurfaceType,
  shade?: ThemeSurfaceShade,
  highlightOnHover?: boolean,
  customPropsOnly?: boolean
): HeadlessSurfaceProps;
export function getSurfaceProps(
  typeOrOptions: SurfaceOptions | ThemeSurfaceType = "canvas",
  shade: ThemeSurfaceShade = "default",
  highlightOnHover = false,
  customPropsOnly = false
): HeadlessSurfaceProps {
  const opt =
    typeof typeOrOptions === "string"
      ? {
          type: typeOrOptions,
          shade,
          highlightOnHover,
          customPropsOnly,
        }
      : { type: typeOrOptions.type ?? "canvas", ...typeOrOptions };

  return {
    className: cn(
      styles.surface,
      styles[isTypeWithShade(opt.type) ? getShadedClassName(opt.type, opt.shade) : opt.type],
      { [styles.highlightOnHover]: opt.highlightOnHover, [styles.omitStyles]: opt.customPropsOnly }
    ),
  };
}

/**
 * @deprecated Use `getSurfaceProps("canvas", canvas)` instead.
 *
 * A Headless component that provides canvas to other elements.
 * @param canvas - Canvas defines shade of background color. Default: "default".
 * @returns A class name that has canvas background color
 *
 * ## Usage
 * ```tsx
 * const canvasProps = getCanvasProps();
 *
 * return <div {...canvasProps}>Content</div>;
 * ```
 *
 * ## Tips
 *  - You can use `--lk-canvas-color` CSS custom property in your components. This is helpful if you want to match
 *  the canvas color in a gradient background.
 */
export const getCanvasProps = (canvas: ThemeSurfaceShade = "default"): HeadlessSurfaceProps =>
  getSurfaceProps("canvas", canvas);
