import cn from "classnames";
import { memo } from "react";

import { getBorderRadiusProps } from "@/components/BorderRadius/BorderRadius";
import { getFocusOutlineProps } from "@/components/FocusOutline/FocusOutline";
import { SVGScrollIndicatorAnimated } from "@/components/SVGIcon/animated/SVGScrollIndicatorAnimated";
import { SVGVerticalScroll } from "@/components/SVGIcon/static/SVGVerticalScroll";
import { mergeProps } from "@/utils/reactExtensions";

import styles from "./ScrollIndicator.module.scss";

type ScrollIndicatorProps = {
  isVisible?: boolean;
  onClick?: () => void;
  swipeIconOnMobile?: boolean;
  className?: string;
  mobileDescription?: string;
  ariaLabel?: string;
};

export const ScrollIndicator: React.FC<ScrollIndicatorProps> = memo(function ScrollIndicator({
  className,
  onClick,
  isVisible = true,
  swipeIconOnMobile = false,
  mobileDescription = "Swipe to view more",
  ariaLabel,
}) {
  return (
    <div className={cn(styles.scrollIndicator, className, { [styles.scrollShow]: isVisible })}>
      <button
        {...mergeProps(getFocusOutlineProps({}), getBorderRadiusProps("medium"), {
          className: styles.scrollLink,
        })}
        onClick={onClick}
        aria-label={ariaLabel}
        // Needed to prevent submit action in some browsers if used in a form context
        type="button"
      >
        {!!swipeIconOnMobile && (
          <div className={styles.swipeIcon}>
            <SVGVerticalScroll width={29} height={30} />
            {mobileDescription}
          </div>
        )}
        <SVGScrollIndicatorAnimated height={40} width={25} />
      </button>
      <div className={styles.scrollGradient} />
    </div>
  );
});
