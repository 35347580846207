import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGArrowLeft: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={width}
    height={height}
    fill="currentColor"
    {...rest}
  >
    <path
      fill="currentColor"
      d="M7.91474 14.6738L9.32895 13.2596L5.82813 9.75879L13.999 9.75879L13.999 7.76092L5.82765 7.76092L9.32896 4.25962L7.91474 2.8454L2.00053 8.75961L7.91474 14.6738Z"
    />
  </svg>

  /* eslint-enable max-len */
);
SVGArrowLeft = memo(SVGArrowLeft);

export { SVGArrowLeft };
