import { getAppMode } from "Helpers/general";
import { UserEncoreStatus, UserEncoreTiers } from "Helpers/users";
import { MakeBookingRequest, UpdateBookingRequest } from "ReduxSlices/onDemand/onDemandInterfaces";

import TagManager from "react-gtm-module";

//#region enum
enum GTMEvents {
    FORM_FIELD_CHANGED = "formFieldChanged",
    FORM_SUBMITTED = "formSubmitted",
    ACCOUNT_CREATED = "accountCreated",
    VEHICLE_ASSOCIATED = "vehicleAssociated",
    ACCOUNT_SIGNED_IN_BY_FORM = "signedIn",
    BOOKING_CREATED = "bookingCreated",
    BOOKING_UPDATED = "bookingUpdated",
    EXTERNAL_LINK_CLICKED = "externalLinkClicked",
    COMMUNICATION_PREFERENCE_UPDATED = "communicationPreferenceUpdated",
    ON_CLICK = "gtm.click",
    REWARD_CARD_CLICKED = "rewardTileClicked",
    ENCORE_ELEVATE_INTEREST = "encoreElevateInterest",
    ENCORE_BANNER_BUTTON_CLICK = "bannerButtonClick",
    ENCORE_ELEVATE_PURCHASE = "elevatePurchase",
}

export enum GTMForms {
    REGISTRATION = "Registration",
    CHANGE_PASSWORD = "Change Password",
    CURRENT_PASSWORD = "Current Password",
    NEW_PASSWORD = "New Password",
    REPEAT_NEW_PASSWORD = "Repeat New Password",

    ADD_PAYMENT_CARD = "Add Payment Card Details",
    CARDHOLDER_NAME = "Card Holder Name",
    CARD_NUMBER = "Card Number",

    ADDRESS = "Address",
    MOBILE_NUMBER = "Mobile Number",
    BOOK_DRIVER_DETAILS = "Book Vehicle Driver Details",
    BOOK_FLIGHT_DETAILS = "Book Flight Details",
    VIN_OR_REGISTRATION = "Vin Or Registration",
    VIN = "Vin",
}

enum GTMSteps {
    personalDetail = "Personal Info",
    acceptTC = "Accept Term & Conditions",
}
//#endregion

/**
 * push a FormFieldCompleteEvent to data layer
 */
const pushToGTM = (
    event: GTMEvents,
    userTier = UserEncoreTiers.NONE.toLocaleUpperCase(),
    accountId: string,
    { ...payload }
) =>
    TagManager.dataLayer({
        dataLayer: { event, userTier: userTier.toLocaleUpperCase(), accountId, appMode: getAppMode(), ...payload },
    });

export const pushToGTMTermsAndConditionFormSubmitted = (userTier: string, accountId: string): void =>
    pushToGTM(GTMEvents.FORM_SUBMITTED, userTier, accountId, {
        formName: GTMForms.REGISTRATION,
        stepCompleted: GTMSteps.acceptTC,
    });

export const pushToGTMSignUpFormSubmitted = (userTier: string, accountId: string): void =>
    pushToGTM(GTMEvents.FORM_SUBMITTED, userTier, accountId, {
        formName: GTMForms.REGISTRATION,
        stepCompleted: GTMSteps.personalDetail,
    });

export const pushToGTMSignUpFormFieldChanged = (
    fieldName: string,
    valid: boolean,
    userTier: string,
    accountId: string
): void => pushToGTM(GTMEvents.FORM_FIELD_CHANGED, userTier, accountId, { fieldName, valid });

export const pushToGTMFormFieldChanged = (fieldName: string, userTier: string, accountId: string): void =>
    pushToGTM(GTMEvents.FORM_FIELD_CHANGED, userTier, accountId, { fieldName });

export const pushToGTMChangePasswordFormFieldSubmitted = (userTier: string, accountId: string): void =>
    pushToGTM(GTMEvents.FORM_SUBMITTED, userTier, accountId, { formName: GTMForms.CHANGE_PASSWORD });

export const pushToGTMAddPaymentFormFieldSubmitted = (userTier: string, accountId: string): void =>
    pushToGTM(GTMEvents.FORM_SUBMITTED, userTier, accountId, { formName: GTMForms.ADD_PAYMENT_CARD });

export const pushToGTMBookVehicleDriverDetailsFormFieldSubmitted = (userTier: string, accountId: string): void =>
    pushToGTM(GTMEvents.FORM_SUBMITTED, userTier, accountId, { formName: GTMForms.BOOK_DRIVER_DETAILS });

export const pushToGTMBookFlightDriverDetailsFormFieldSubmitted = (userTier: string, accountId: string): void =>
    pushToGTM(GTMEvents.FORM_SUBMITTED, userTier, accountId, { formName: GTMForms.BOOK_FLIGHT_DETAILS });

export const pushToGTMVinOrRegistration = (userTier: string, accountId: string): void =>
    pushToGTM(GTMEvents.FORM_SUBMITTED, userTier, accountId, { formName: GTMForms.VIN_OR_REGISTRATION });

export const pushToGTMAccountCreation = (source: string, userTier: string, accountId: string): void =>
    pushToGTM(GTMEvents.ACCOUNT_CREATED, userTier, accountId, { registrationSource: source });

export const pushToGTMFormSignInSuccess = (userTier: string, accountId: string): void =>
    pushToGTM(GTMEvents.ACCOUNT_SIGNED_IN_BY_FORM, userTier, accountId, {});

export const pushToGTMVehicleAssociation = (
    vehicleOwnershipId: string,
    verificationStatus: boolean,
    userTier: string,
    accountId: string
): void => pushToGTM(GTMEvents.VEHICLE_ASSOCIATED, userTier, accountId, { vehicleOwnershipId, verificationStatus });

export const pushToGTMBookingCreated = (
    makeBookingRequest: MakeBookingRequest,
    userTier: string,
    accountId: string
): void => pushToGTM(GTMEvents.BOOKING_CREATED, userTier, accountId, { ...makeBookingRequest });

export const pushToGTMBookingUpdated = (
    updateBookingRequest: UpdateBookingRequest,
    userTier: string,
    accountId: string
): void => pushToGTM(GTMEvents.BOOKING_UPDATED, userTier, accountId, { ...updateBookingRequest });

export const pushToGTMExternalURLVisited = (url: string, userTier: string, accountId: string): void =>
    pushToGTM(GTMEvents.EXTERNAL_LINK_CLICKED, userTier, accountId, { target: url });

export const pushToGTMCommunicationPreference = (
    communicationPreferences: LXS.GuestCommunicationPreferences,
    userTier: string,
    accountId: string
): void => pushToGTM(GTMEvents.COMMUNICATION_PREFERENCE_UPDATED, userTier, accountId, { ...communicationPreferences });

export const pushToGTMButtonClicked = (text: string, userTier: string, accountId: string): void =>
    pushToGTM(GTMEvents.ON_CLICK, userTier, accountId, { text, "gtm.element": "BUTTON" });

export const pushToGTMRewardCardClick = (
    dataLayer: { [key: string]: string },
    userTier: string,
    accountId: string
): void => pushToGTM(GTMEvents.REWARD_CARD_CLICKED, userTier, accountId, dataLayer);

export const pushToGTMEncoreButtonClicked = (
    userTier: string,
    accountId: string,
    renewalRequestStatus: string,
    elevateOffer: string,
    buttonText: string
): void =>
    pushToGTM(GTMEvents.ENCORE_ELEVATE_INTEREST, userTier, accountId, {
        renewalRequestStatus: renewalRequestStatus.toLocaleUpperCase(),
        elevateOffer: elevateOffer.toLocaleUpperCase(),
        buttonText,
    });

export const pushToGTMEncoreDashboardButtonClicked = ({
    accountId = "",
    encoreTier,
    bannerTitle,
    buttonText,
    membershipStatus,
}: {
    encoreTier?: string;
    accountId?: string;
    membershipStatus: UserEncoreStatus;
    bannerTitle: string;
    buttonText: string;
}): void =>
    pushToGTM(GTMEvents.ENCORE_BANNER_BUTTON_CLICK, encoreTier, accountId, {
        membershipStatus: membershipStatus.toLocaleUpperCase(),
        bannerTitle: bannerTitle.toLocaleUpperCase(),
        buttonText: buttonText.toLocaleUpperCase(),
        banner: "MEMBERSHIP BANNER",
    });

export const pushToGTMEncoreMembershipCardButtonClicked = ({
    accountId = "",
    encoreTier,
    buttonText,
    membershipStatus,
}: {
    encoreTier?: string;
    accountId?: string;
    membershipStatus: string | undefined;
    buttonText: string;
}): void =>
    pushToGTM(GTMEvents.ENCORE_ELEVATE_PURCHASE, encoreTier, accountId, {
        membershipStatus: membershipStatus?.toLocaleUpperCase(),
        buttonText: buttonText.toLocaleUpperCase(),
    });
