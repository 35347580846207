import cn from "classnames";

import styles from "./Layers.module.scss";

export interface LayersProps {
  children: React.ReactNode;
  className?: string;
}

/**
 * Layers is a layout component that helps organize element in z-axis.
 * It functions like div tag, but extent it with ability to place all children in the same grid area,
 * so you can overlap those children elements with proper position setting to make sure content of
 * those elements do not overlap each other. If there are other elements sitting along side with Layers,
 * the Layers component can adjust it's height to match other element's height if others are taller than it.
 * And Layers component can resize automatically to cover all content inside it.
 */
export const Layers: React.FC<LayersProps> = ({ children, className, ...rest }) => (
  <div {...rest} className={cn(styles.layers, className)}>
    {children}
  </div>
);
