import cn from "classnames";

import { getBorderRadiusProps } from "@/components/BorderRadius/BorderRadius";
import { Box } from "@/components/Box/Box";
import type { SpacingScale } from "@/components/Box/Box.utils";
import { Stack } from "@/components/Stack/Stack";
import { mergeProps } from "@/utils/reactExtensions";
import { kebabToPascalCase } from "@/utils/stringUtils";

import styles from "./TooltipPopup.module.scss";

export type TooltipPointerPosition =
  | "top-left"
  | "top-right"
  | "top-middle"
  | "bottom-left"
  | "bottom-right"
  | "bottom-middle"
  | "middle-left"
  | "middle-top-left"
  | "middle-bottom-left"
  | "middle-right"
  | "middle-top-right"
  | "middle-bottom-right";

type TooltipPopupProps = React.PropsWithChildren<{
  /**
   * Arrow position of the popup.
   */
  pointerPosition?: TooltipPointerPosition;

  /**
   * className for custom styling.
   */
  className?: string;

  /**
   * Provide aria-label for the component.
   */
  ariaLabel: string;
}>;

const getContainerPadding = (position: TooltipPointerPosition): { [key: string]: SpacingScale } => {
  switch (position) {
    case "top-right":
    case "bottom-right":
      return { pr: "4xs" };
    case "top-left":
    case "bottom-left":
      return { pl: "4xs" };
    case "middle-bottom-right":
    case "middle-bottom-left":
      return { pb: "4xs" };
    case "middle-top-right":
    case "middle-top-left":
      return { pt: "4xs" };
    default:
      return { p: "none" };
  }
};

/**
 * `TooltipPopup` component is used to displayed content from a tooltip.
 *
 * Usage:
 *
 * ```tsx
 * <TooltipPopup>your content here</TooltipPopup>
 * ```
 */
const TooltipPopup: React.FC<TooltipPopupProps> = ({
  children,
  pointerPosition = "top-middle",
  ariaLabel,
  className,
  ...rest
}) => (
  <Stack
    aria-label={ariaLabel}
    role="tooltip"
    spacing="none"
    className={cn(
      styles.tooltipPopup,
      styles[`position${kebabToPascalCase(pointerPosition)}`],
      styles[`container${kebabToPascalCase(pointerPosition)}`],
      className
    )}
    {...rest}
    data-testid={`lk-tooltip-popup-${pointerPosition}`}
  >
    <Box
      p="xs"
      {...mergeProps(getBorderRadiusProps("small"), {
        className: styles.popupContent,
      })}
    >
      {children}
    </Box>
    <Box {...getContainerPadding(pointerPosition)}>
      <div
        className={cn(styles.triangle, styles[`pointer${kebabToPascalCase(pointerPosition)}`])}
      ></div>
    </Box>
  </Stack>
);

export { TooltipPopup };
