import cn from "classnames";

import { getBorderRadiusProps } from "@/components/BorderRadius/BorderRadius";
import { mergeProps } from "@/utils/reactExtensions";

import styles from "./LoadingSpinner.module.scss";

export interface LoadingSpinnerProps {
  /**
   * The size (height, width) of the loading spinner
   * @default 24
   */
  size?: number;
  ariaLabel?: string;
  className?: string;
}

/**
 * Allows the provision of feedback to the user that their actions have been registered and that our site is loading.
 */
export const LoadingSpinner: React.FC<LoadingSpinnerProps> = (props) => {
  const { size = 24, ariaLabel = "Loading…", className } = props;
  return (
    <div
      className={cn(styles.loadingSpinnerContainer, className)}
      aria-label={ariaLabel}
      role="status"
      aria-live="polite"
      aria-busy="true"
    >
      <div
        {...mergeProps(getBorderRadiusProps("rounded"), {
          className: styles.loadingSpinner,
          style: { height: size, width: size },
        })}
      />
    </div>
  );
};

export default LoadingSpinner;
