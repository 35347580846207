import cn from "classnames";
import { useEffect, useRef, useState } from "react";

import { getBorderRadiusProps } from "@/components/BorderRadius/BorderRadius";
import { mergeProps } from "@/utils/reactExtensions";

import styles from "./SVGIconWrapper.module.scss";
import { SVGAnimationEvents, SVGIconElementProps } from "./types";

/**
 * A wrapper for SVG icons that adds hover and click animations.
 *
 * ## Static
 * ```tsx
 * <SVGInfoCircle />
 * <SVGInfoCircle width={24} height={24} color="white" />
 * ```
 * Renders a static icon. The default icon size is 12x12 px.
 *
 * ## Animated
 * ```tsx
 * <SVGIconWrapper>
      <SVGInfoCircle />
 * </SVGIconWrapper>
 * ```
 * To animate an icon, wrap it in the SVGIconWrapper component.
 */
export const SVGIconWrapper = ({
  className,
  hasBackground = false,
  containerRef,
  animationEvent = SVGAnimationEvents.CLICK,
  active = false,
  activeClassName = "active",
  ...rest
}: SVGIconElementProps) => {
  const iconWrapperRef = useRef<HTMLSpanElement>(null);
  const [isActive, setIsActive] = useState(active);
  const classes = cn(
    styles.iconWrapper,
    {
      [`${styles.withBackground}`]: hasBackground,
      [`${activeClassName}`]: isActive,
    },
    className
  );

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  useEffect(() => {
    const element =
      containerRef && containerRef.current ? containerRef.current : iconWrapperRef.current;

    if (!element) {
      return;
    }

    const toggleActive = () => {
      setIsActive((currentIsActive) => !currentIsActive);
    };

    switch (animationEvent) {
      case SVGAnimationEvents.HOVER:
        element.addEventListener("mouseenter", toggleActive);
        element.addEventListener("mouseleave", toggleActive);
        break;
      case SVGAnimationEvents.CLICK:
        element.addEventListener("click", toggleActive);
        break;
    }

    return () => {
      if (element) {
        element.removeEventListener("mouseenter", toggleActive);
        element.removeEventListener("mouseleave", toggleActive);
        element.removeEventListener("click", toggleActive);
      }
    };
  }, [isActive, containerRef, animationEvent]);

  return (
    <span
      ref={iconWrapperRef}
      {...mergeProps(rest, hasBackground ? getBorderRadiusProps("rounded") : null, {
        className: classes,
      })}
    />
  );
};
