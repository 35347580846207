import { RefObject, useRef, Children, createRef } from "react";

export interface SlideshowRef {
  nextSlide: () => void;
  prevSlide: () => void;
  slideCount: number;
}
export interface SlideshowProps extends React.HTMLAttributes<HTMLDivElement> {
  onSlideChange?: (index: number) => void;
  slideShowRef?: React.ForwardedRef<SlideshowRef>;
}

export const TRANSITION_INTERVAL = 7000;
export const DRAG_THRESHOLD = 50;
export const DEBOUNCE_TIME = 100;

export const useChildRefs = (
  children: React.ReactNode
): React.MutableRefObject<RefObject<HTMLDivElement>[]> => {
  const childRefs = useRef<RefObject<HTMLDivElement>[]>([]);

  if (childRefs.current.length !== Children.count(children)) {
    childRefs.current = Array(Children.count(children))
      .fill(null)
      .map((_, index) => childRefs.current[index] || createRef());
  }

  return childRefs;
};
