import cn from "classnames";
import { isValidElement } from "react";
import flattenChildren from "react-keyed-flatten-children";

import { getBorderRadiusProps } from "@/components/BorderRadius/BorderRadius";
import { mergeProps } from "@/utils/reactExtensions";

import styles from "./Row.module.scss";
import { Cell } from "../Cell/Cell";

export type RowProps = React.HTMLAttributes<HTMLDivElement> & {
  /**
   * When true, row gap will be applied to the row.
   */
  hasRowGap?: boolean;

  /**
   * When true, row border radius will be applied to the row.
   */
  hasRowBorderRadius?: boolean;

  /**
   * When true, cells will be in reversed order.
   */
  isReversed?: boolean;

  /**
   * Class name(s) to customize component if required.
   */
  className?: string;

  /**
   * Ref to Row component.
   */
  divRef?: React.Ref<HTMLDivElement>;
};

/**
 * `Row` component is intended to be used as grid layout and it should be used inside `SectionContentContainer`. It Will only
 * allow `Cell` and Fragment component as children, others will be ignored.
 * `Row` component allows you to put up to 4 `Cell` components in a row, and cells will be wrap based on cell min-width, row gap and row width.
 * You can add row gap and row border radius to the row, and also cell border radius to the cell and you can reverse
 * the order of cells.
 *
 * If `Row` has gaps, consumers cannot set row border radius but can set border radius for `Cell` component.
 * If `Row` does not have row gap, consumers cannot set cell border radius.
 *
 * Usage:
 *
 * ```tsx
 * <Section container="outer">
 *   <SectionContentContainer width="12col">
 *     <Row>
 *       <Cell>Cell 2</Cell>
 *       <Cell>Cell 2</Cell>
 *     </Row>
 *   </SectionContentContainer>
 * </Section>
 * ```
 */

export const Row: React.FC<RowProps> = ({
  hasRowGap,
  hasRowBorderRadius,
  isReversed = false,
  children,
  divRef,
  ...rest
}) => {
  // Flatten the children and filter out Fragments, keeping only Cell components
  const processedChildren = flattenChildren(children).filter(
    (child) => isValidElement(child) && child.type === Cell
  );
  const cellCount = processedChildren.length;

  return (
    <div
      tabIndex={0}
      ref={divRef}
      {...mergeProps(
        rest,
        hasRowBorderRadius && !hasRowGap ? getBorderRadiusProps("extra-large") : null,
        {
          className: cn(styles.row, styles[`rowWith${cellCount}Cell`] || "", {
            [styles.rowGap]: hasRowGap,
            [styles.reversed]: isReversed,
          }),
        }
      )}
    >
      {processedChildren}
    </div>
  );
};
