import cn from "classnames";
import { useContext } from "react";
import { mergeProps } from "react-aria";

import { getBorderRadiusProps } from "@/components/BorderRadius/BorderRadius";
import { Box, getBoxProps } from "@/components/Box/Box";
import { getFocusOutlineProps } from "@/components/FocusOutline/FocusOutline";
import { getStackProps } from "@/components/Stack/Stack";
import { SVGAdd } from "@/components/SVGIcon/static/SVGAdd";
import { SVGChevronDown } from "@/components/SVGIcon/static/SVGChevronDown";
import { SVGSubtract } from "@/components/SVGIcon/static/SVGSubtract";
import { Typography } from "@/components/Typography/Typography";

import styles from "./Accordion.module.scss";
import { AccordionGroupContext } from "../AccordionGroup/AccordionGroup";
import { Divider } from "../Divider/Divider";

export type AccordionProps = {
  /**
   * Provide a label for the Accordion
   */
  label: string;

  /**
   * current state of accordion (controlled)
   */
  isOpen?: boolean;

  /**
   * Accordion content
   */
  children: string | React.ReactNode;

  /** `arrow` will be the default icon, `equation` will be the `+` and `-` when opened */
  iconStyle?: "arrow" | "equation";

  /**
   * ariaLabel of the Accordion
   */
  ariaLabel?: string;

  /**
   * Provide custom class for Accordion
   */
  className?: string | undefined;
};

/**
 * Accordion should be used within an `AccordionGroup`. It displays extra content when opened
 *
 * ## Usage
 *
 * ```tsx
 * <Accordion label="Accordion Label"> Accordion content. Can be a string or React Component</Accordion>
 * ```
 */
export const Accordion: React.FC<AccordionProps> = ({
  isOpen: open,
  label,
  children,
  iconStyle = "arrow",
  ariaLabel = "accordion",
  className,
}) => {
  const { name, showDivider } = useContext(AccordionGroupContext);
  const boxStyle = getBoxProps({ px: "xs", py: "even-less" });
  const stackStyle = getStackProps({
    spacing: "xs",
    alignItems: "center",
    justifyContent: "space-between",
  });

  return (
    <>
      <details
        aria-label={ariaLabel}
        className={cn(styles.detailsContainer, className)}
        open={open}
        name={name}
      >
        <summary
          {...mergeProps(
            getFocusOutlineProps({ outlineOffSet: "smallest" }),
            getBorderRadiusProps("small"),
            { style: { ...boxStyle.style, ...stackStyle.style } },
            { className: cn(styles.labelContainer, boxStyle.className, stackStyle.className) }
          )}
        >
          {/* <Stack
            component={Box}
            px="xs"
            py="even-less"
            spacing="xs"
            alignItems="center"
            className={styles.labelContainer}
            justifyContent="space-between"
          > */}
          <Typography component="span" variant="s2" fontWeight="regular">
            {label}
          </Typography>
          {iconStyle === "arrow" ? (
            <div className={styles.arrowIconDetails}>
              <SVGChevronDown width={16} height={16} />
            </div>
          ) : (
            <div className={styles.equationContainer}>
              <SVGAdd width={16} height={16} className={styles.add} />
              <SVGSubtract width={16} height={16} className={styles.subtract} />
            </div>
          )}
          {/* </Stack> */}
        </summary>
        <Box px="xs" pb="even-less">
          <div>{children}</div>
        </Box>
      </details>
      {showDivider && <Divider variant="dark" />}
    </>
  );
};
