import cn from "classnames";
import { useContext, useEffect, useState } from "react";

import { ScrollIndicator } from "@/components/ScrollIndicator/ScrollIndicator";

import {
  useGlobalScrollingStyles,
  useScrollingControlsState,
  useSlideScrolling,
} from "./VerticalScroll.hooks";
import styles from "./VerticalScroll.module.scss";
import { VerticalScrollContext } from "./VerticalScrollContext";
import { VerticalScrollNav } from "./VerticalScrollNav";
import { VerticalScrollSlides } from "./VerticalScrollSlides";
import { Responsive } from "../Responsive/Responsive";
import { ThemeVariantScope } from "../ThemeVariantScope/ThemeVariantScope";

export const VerticalScrollBody: React.FC<{
  children: React.ReactElement[];
}> = ({ children }) => {
  const {
    options: { isDisabled, indicatorAriaLabel },
  } = useContext(VerticalScrollContext);

  // We set it to false from backend and will apply only when components hydrated on client side
  const [currentlyDisabled, setCurrentlyDisabled] = useState(false);

  const { hideScrollIndicator, hideScrollNav, updateScrollingControlsState } =
    useScrollingControlsState();

  const { scrollToNext } = useSlideScrolling();

  useEffect(() => {
    setCurrentlyDisabled(isDisabled);
  }, [isDisabled]);

  useGlobalScrollingStyles();

  return (
    <>
      {children.length > 1 && (
        <VerticalScrollNav count={children.length} hidden={currentlyDisabled || hideScrollNav} />
      )}

      <VerticalScrollSlides onScrollVisibilityChange={updateScrollingControlsState}>
        {children}
      </VerticalScrollSlides>

      <Responsive breakpoint="large">
        <ThemeVariantScope variant="alt" isContents>
          <ScrollIndicator
            onClick={scrollToNext}
            className={cn(styles.scrollIndicator, {
              [styles.scrollIndicatorHidden]: currentlyDisabled || hideScrollIndicator,
            })}
            ariaLabel={indicatorAriaLabel}
          />
        </ThemeVariantScope>
      </Responsive>
    </>
  );
};
