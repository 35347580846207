import cn from "classnames";

import { GridProps } from "@/components/Grid/Grid.types";
import { Stack } from "@/components/Stack/Stack";
import { Typography } from "@/components/Typography/Typography";

import styles from "./Grid.module.scss";

export const Grid: React.FC<React.PropsWithChildren & GridProps> = ({ heading, children }) => (
  <Stack direction="column" spacing="m">
    {heading && <Typography variant="h3">{heading}</Typography>}
    <Stack direction="row" flexWrap="wrap" className={cn(styles.grid)}>
      {children}
    </Stack>
  </Stack>
);
