import cn from "classnames";
import { memo } from "react";

import styles from "./Divider.module.scss";

export const DIVIDER_VARIANTS = ["invisible", "lightest", "light", "dark", "darkest"] as const;

export type DividerVariant = (typeof DIVIDER_VARIANTS)[number];
export type DividerProps = React.HTMLAttributes<HTMLDivElement> & {
  /**
   * Default is lightest which sets the opacity to 10%, invisible sets the opacity to 0%,
   * light sets the opacity to 30%, dark sets the opacity to 50%, Darkest sets the opacity to 100%.
   */
  variant?: DividerVariant;
};

const variantClasses: Record<DividerVariant, string> = {
  invisible: styles.invisible,
  lightest: styles.lightest,
  light: styles.light,
  dark: styles.dark,
  darkest: styles.darkest,
};

/**
 * A component used to divide content. This provides flexibility to create hierarchy amongst related/grouped content. Additionally the invisible variant can be used to create a physical gap between content items if a stack cannot be applied (this should be “visible” for screen readers, so gap is valuable for any audience).
 */
let Divider: React.FC<DividerProps> = ({ className, variant = "lightest", ...rest }) => {
  return (
    <div
      className={cn(styles.divider, className, variantClasses[variant])}
      role="separator"
      {...rest}
    />
  );
};

Divider = memo(Divider);

export { Divider };
