import cn from "classnames";

import { getBorderRadiusProps } from "@/components/BorderRadius/BorderRadius";
import { getBoxProps } from "@/components/Box/Box";
import { ThemeVariantScope } from "@/components/ThemeVariantScope/ThemeVariantScope";
import type { ThemeVariant } from "@/theming/themingTypes";
import { mergeProps } from "@/utils/reactExtensions";

import styles from "./FormSection.module.scss";

type FormSectionProps = React.PropsWithChildren<
  React.HTMLAttributes<HTMLDivElement> & {
    /**
     * Variant to override closest theme variant.
     */
    variant?: ThemeVariant;
  }
>;

/**
 * `FormSection` component allows you to wrap `form elements` in a section with predefined styles.
 * This component should only be used for `form elements` and should be used in conjunction with the `FormContainer`
 * component.
 *
 * `FormSection` supports local theme which can be achieve by setting the `variant` prop.
 * If form section is set to the opposite variant of the parent section it must have no input elements in it.
 * This is to ensure that the focus outline is always visible when users are required to provide inputs.
 *
 * Usage:
 *
 * ```tsx
 *<FormContainer>
 *  <FormSection variant="alt">
 *    Form Elements
 *  </FormSection>
 *</FormContainer>
 * ```
 */
export const FormSection: React.FC<FormSectionProps> = ({
  className,
  children,
  variant,
  ...rest
}) => (
  <ThemeVariantScope
    variant={variant}
    {...mergeProps(
      getBoxProps({ p: "default" }),
      getBorderRadiusProps("extra-large"),
      {
        className: cn(styles.formSection, className),
        "data-testid": "form-section",
      },
      rest
    )}
  >
    {children}
  </ThemeVariantScope>
);
