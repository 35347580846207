import { useMemo, useRef, useState } from "react";

import styles from "./VerticalScroll.module.scss";
import { VerticalScrollOptions } from "./VerticalScroll.types";
import { VerticalScrollContext } from "./VerticalScrollContext";
import { VerticalScrollItem } from "./VerticalScrollItem";

interface Props {
  options: VerticalScrollOptions;
  slidesCount: number;
}

const addAndClone = (set: Set<number>, index: number) => {
  if (set.has(index)) {
    return set;
  }
  const next = new Set(set);
  next.add(index);
  return next;
};

const deleteAndClone = (set: Set<number>, index: number) => {
  if (!set.has(index)) {
    return set;
  }
  const next = new Set(set);
  next.delete(index);
  return next;
};

export const VerticalScrollRoot: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  options,
  slidesCount,
}) => {
  const currentSlideRef = useRef<number>(0);
  const isScrollingRef = useRef<boolean>(false);
  const itemsRef = useRef(new Map<number, VerticalScrollItem>());
  const [hiddenSlideIndexes, setHiddenSlideIndexes] = useState(new Set<number>());

  const value = useMemo(
    () => ({
      itemsRef,
      isScrollingRef,
      currentSlideRef,
      slidesCount,
      options,
      hiddenSlideIndexes,
      hideSlide: (index: number) => setHiddenSlideIndexes((prev) => addAndClone(prev, index)),
      showSlide: (index: number) => setHiddenSlideIndexes((prev) => deleteAndClone(prev, index)),
    }),
    [slidesCount, options, hiddenSlideIndexes]
  );

  return (
    <div className={styles.wrapper} data-testid="lk-vertical-scroll">
      <VerticalScrollContext.Provider value={value}>{children}</VerticalScrollContext.Provider>
    </div>
  );
};
