import { replaceInTree } from "@tmca/react-element-replace";

import { DisclaimerReference } from "../DisclaimerReference";

export const disclaimerReplacer = {
  order: 200,
  exec: (children: React.ReactNode) => ({
    stopProcessing: false,
    result: replaceInTree(children, {
      match: (x) => typeof x === "string",
      replace: (content: string) =>
        content
          // IMPORTANT: Use of parentheses allow to include splitter text in the array
          // if there was no text before tag it will be split as empty string
          .split(/\[(.*?)\]/gi)
          .map((token, i) =>
            // Every even (second, forth) value will be the content of parentheses in regex
            i % 2 === 1 ? <DisclaimerReference key={`${token}_${i}`} reference={token} /> : token
          ),
    }),
  }),
};
