import cn from "classnames";

import styles from "./FocusOutline.module.scss";

type FocusOutlineProps = {
  outlineOffSet?: "default" | "none" | "small" | "smallest";
  isFormElement?: boolean;
};

/**
 * A Headless component that provides focus outline to other elements.
 * If it is used along with the `InputBorder` component, consumers do not need to set borderRadius as they
 * will shared the one from the `InputBorder` component, which means the style property will be empty.
 *
 * @param {FocusOutlineProps} props - Border radius and outline offset for styling focus outline.
 * @returns {FocusOutlineProps} FocusOutline component props.
 *
 * ## Usage
 *
 * ```tsx
 * const focusOutlineProps = getFocusOutlineProps({
 *   outlineOffSet: "none",
 * });
 *
 * return <div {...focusOutlineProps}>Content</div>;
 * ```
 */
export const getFocusOutlineProps = ({
  outlineOffSet = "default",
  isFormElement = true,
}: FocusOutlineProps) => ({
  className: cn(styles.focusOutline, styles[`${outlineOffSet}OutlineOffSet`], {
    [styles.isFormElement]: isFormElement,
  }),
});
