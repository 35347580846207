import { mergeProps } from "@/utils/reactExtensions";

import { GenericLinkVariant } from "./GenericLink.types";
import { isExternalLink } from "./GenericLink.utils";
import { getLinkStyles } from "./getLinkStyles";
import { ANCHOR_TAG_REL_FOR_BLANK_TARGET } from "../common/constants";
import { useLinkContext } from "../common/linkContext";

export type LinkStyleProps = {
  /**
   * Visual variant of the link.
   * senkei: Shows an animated senkei line
   * subtle: Only shows underline on hover
   * quiet: No underline. Used in lists like autocomplete results.
   */
  variant?: GenericLinkVariant;

  /**
   * By default, renders an external icon next to external links
   */
  hideExternalIcon?: boolean;
};

export type GenericLinkProps = React.PropsWithChildren<
  React.HTMLProps<HTMLAnchorElement> & LinkStyleProps
>;

/**
 * An anchor tag styled as a link. Shows underline on hover.
 *
 * ## Usage
 * ```tsx
 * <GenericLink href="https://example.com">Example</GenericLink>
 * ```
 */
export const GenericLink: React.FC<GenericLinkProps> = ({
  href = "#",
  target,
  variant,
  hideExternalIcon,
  children,
  ...rest
}) => {
  const { LinkComponent } = useLinkContext();

  /*
   * For 'senkei' variants, wrap the children in a <span> so mixin can follow the content width.
   */
  const parsedChildren = variant === "senkei" ? <span>{children}</span> : children;

  return (
    <LinkComponent
      data-testid="lk-link"
      href={href}
      target={target}
      rel={target === "_blank" ? ANCHOR_TAG_REL_FOR_BLANK_TARGET : ""}
      aria-label={typeof children === "string" ? children : undefined}
      {...mergeProps(
        getLinkStyles({ variant, hideExternalIcon: isExternalLink(href) && !hideExternalIcon }),
        rest
      )}
    >
      {parsedChildren}
    </LinkComponent>
  );
};
