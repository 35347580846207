import * as React from "react";
import { useSelector } from "react-redux";
import {
    type HeadlessSiteContextRaw,
    primaryNavigationDataSelector,
    PrimaryNavRawDataWithContext,
} from "ReduxSlices/general";
import {
    PrimaryNav,
    PrimaryNavContainer,
    type PrimaryNavData,
    convertToPrimaryNavData,
    GlobalStylesScope,
    AuthenticationContextProvider,
    darkTheme,
} from "@tmca/lexus-kit/css-in-js";
import { RouteSection, routeString } from "Helpers/routes";
import { useSettingsPromise } from "Hooks/usePromiseState";
import { useNavigate } from "react-router-dom";
import {
    type HeadlessSiteProps,
    LexusHeadlessSiteContext,
} from "Components/PrimaryNavigation/LexusHeadlessSiteContext";
import { useContext, useMemo } from "react";

type Environment = "prod" | "prodEu" | "apse2";
const WidgetsProvider = React.lazy(() =>
    import("@sitecore-search/react").then(module => ({ default: module.WidgetsProvider }))
);

const SearchPopoverWidget = React.lazy(() =>
    import("./modules/NavigationSearch.js").then(module => ({ default: module.SearchPopoverWidget }))
);

// TODO hardcode ENCORE_URL for now until authenticationUrl is refactored
const ENCORE_URL = typeof window !== "undefined" ? location.protocol + "//" + location.host : "";

const SearchForm: React.FC<{
    links: PrimaryNavData["searchLinks"];
    searchSettings: PrimaryNavData["searchSettings"];
    className?: string;
}> = ({ links, searchSettings, className }) => {
    const { searchPage } = useContext(LexusHeadlessSiteContext) || {};
    const {
        rfkId,
        maxPreviewResults,
        resultsBlockAriaLabel,
        searchAllLinkText,
        searchFieldPlaceholder,
        searchSuggestionName,
    } = searchSettings || {};

    return !rfkId || !searchPage ? null : (
        <form method="GET" action={searchPage} className={className}>
            <SearchPopoverWidget
                rfkId={rfkId}
                maxResults={maxPreviewResults}
                placeholderText={searchFieldPlaceholder}
                searchAllText={searchAllLinkText}
                resultsAriaLabel={resultsBlockAriaLabel}
                suggestionName={searchSuggestionName}
                linkBlocks={links}
            />
        </form>
    );
};

const isSearchEnvironment = (str: string | undefined): str is Environment =>
    (str && ["prod", "prodEu", "apse2"].includes(str)) || false;

const convertContext = (rawData: HeadlessSiteContextRaw, baseUrl: string): HeadlessSiteProps => ({
    publicUrl: rawData.config.publicUrl?.value,
    searchPage: baseUrl + rawData.config.searchPage?.value,
    searchEntity: rawData.config.searchEntity?.value,
    searchIndexSource: rawData.config.searchIndexSource?.value.split(","),
    searchCustomerKey: rawData.config.searchCustomerKey?.value,
    searchEnv: rawData.config.searchEnv?.value,
    serverApiHost: rawData.config.serverApiHost?.value,
    encoreBaseUrl: rawData.encoreBaseUrl?.value,
    loginUrl: rawData.loginUrl?.value,
    logoutUrl: rawData.logoutUrl?.value,
    refreshUrl: rawData.refreshUrl?.value,
    encoreVehicleUnitApiUrl: rawData.encoreVehicleUnitApiUrl?.value,
    encoreGuestApiUrl: rawData.encoreGuestApiUrl?.value,
});

const Navigation: React.FC<{ data: PrimaryNavData }> = ({ data }) => {
    const { searchCustomerKey, searchEnv, serverApiHost, searchIndexSource } =
        useContext(LexusHeadlessSiteContext) || {};

    const searchConfig =
        searchCustomerKey && isSearchEnvironment(searchEnv) && serverApiHost && searchIndexSource?.length
            ? {
                  env: searchEnv,
                  customerKey: searchCustomerKey,
                  serviceHost: serverApiHost,
              }
            : null;

    const disabledSearchSettings = useMemo(
        () => ({ ...data.searchSettings, showSearch: false }),
        [data.searchSettings]
    );

    const generalSettings = useSettingsPromise(settings => settings.general);
    const mainsiteBaseUrl = generalSettings?.mainsiteBaseUrl
        ? generalSettings?.mainsiteBaseUrl
        : generalSettings?.lexusBrandBaseUrl;

    return searchConfig ? (
        <WidgetsProvider {...searchConfig}>
            <PrimaryNav mainMenuLogoHref={mainsiteBaseUrl} {...data}>
                <PrimaryNav.SearchPopover>
                    <SearchForm
                        links={data.searchLinks}
                        searchSettings={data.searchSettings}
                        className="lx-nav-search__form-desktop"
                    />
                </PrimaryNav.SearchPopover>
                <PrimaryNav.SearchMenuTrigger>
                    <SearchForm links={data.searchLinks} searchSettings={data.searchSettings} />
                </PrimaryNav.SearchMenuTrigger>
            </PrimaryNav>
        </WidgetsProvider>
    ) : (
        <PrimaryNav mainMenuLogoHref={mainsiteBaseUrl} {...data} searchSettings={disabledSearchSettings} />
    );
};

const PrimaryNavigationSC10: React.FC = () => {
    //#region hooks
    const dataJson = useSelector(primaryNavigationDataSelector);
    const generalSettings = useSettingsPromise(settings => settings.general);
    //#endregion

    const navigate = useNavigate();
    const AUTHENTICATION_DATA = {
        config: {
            loginFormUrl: `${ENCORE_URL}/signin?type=iframe`,
            logoutUrl: `${ENCORE_URL}/silentsignout`,
            refreshUrl: `${ENCORE_URL}/refresh`,
            isAuthenticationHost: true,
        },
        onLogout: () => {
            navigate(routeString(RouteSection.SignOut));
        },
    };

    const data: PrimaryNavData | undefined = useMemo(() => {
        if (dataJson && dataJson.nav && "details" in dataJson.nav) {
            // TODO: JSON.parse(JSON.stringify(data)) required as we do mutate the object structure in LKit
            // we need to fix it. https://toyotaau.atlassian.net/browse/LEXNW-509
            const dataRaw: PrimaryNavRawDataWithContext = JSON.parse(JSON.stringify(dataJson));
            return convertToPrimaryNavData(dataRaw.nav);
        }
    }, [dataJson]);

    const headlessSiteContext: HeadlessSiteProps | null = !dataJson?.context
        ? null
        : convertContext(
              dataJson.context,
              generalSettings?.mainsiteBaseUrl ?? generalSettings?.lexusBrandBaseUrl ?? ""
          );

    if (!data) {
        return null;
    }

    return data ? (
        <GlobalStylesScope themeDefinition={darkTheme}>
            <PrimaryNavContainer
                initialIsFixedAtTop={false}
                initialIsSolidBackground={true}
                initialIsSolidContainer={true}
            >
                <AuthenticationContextProvider {...AUTHENTICATION_DATA}>
                    <LexusHeadlessSiteContext.Provider value={headlessSiteContext}>
                        <Navigation data={data} />
                    </LexusHeadlessSiteContext.Provider>
                </AuthenticationContextProvider>
            </PrimaryNavContainer>
        </GlobalStylesScope>
    ) : null;
};

export default PrimaryNavigationSC10;
