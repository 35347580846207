import { isValidElement, useMemo } from "react";
import flattenChildren from "react-keyed-flatten-children";

import type { VerticalScrollOptions } from "./VerticalScroll.types";
import { VerticalScrollBody } from "./VerticalScrollBody";
import { VerticalScrollRoot } from "./VerticalScrollRoot";

type Props = React.PropsWithChildren<Partial<VerticalScrollOptions>>;

const useMemoWithDefault = ({
  isDisabled = false,
  lockFromOverscroll = false,
  noMobileSnapping = false,
  noMobileNav = false,
  useProximitySnapping = false,
  disableSnappingOnFooter = false,
  hideNavAfterLastThreshold = 0.1,
  navButtonVisibilityThreshold = 0,
  indicatorAriaLabel,
  scrollNavAriaLabel,
  scrollNavButtonsAriaLabelFactory,
}: Partial<VerticalScrollOptions>): VerticalScrollOptions =>
  useMemo(
    () => ({
      disableSnappingOnFooter,
      hideNavAfterLastThreshold,
      indicatorAriaLabel,
      isDisabled,
      lockFromOverscroll,
      navButtonVisibilityThreshold,
      noMobileNav,
      noMobileSnapping,
      scrollNavAriaLabel,
      scrollNavButtonsAriaLabelFactory,
      useProximitySnapping,
    }),
    [
      disableSnappingOnFooter,
      hideNavAfterLastThreshold,
      indicatorAriaLabel,
      isDisabled,
      lockFromOverscroll,
      navButtonVisibilityThreshold,
      noMobileNav,
      noMobileSnapping,
      scrollNavAriaLabel,
      scrollNavButtonsAriaLabelFactory,
      useProximitySnapping,
    ]
  );

/**
 * A vertical scroll layout component that allows for a full-screen vertical scrolling experience.
 * It is designed to be used with a set of children that represent the slides.
 *
 * ## Notes
 * - The component will automatically add a global style to the document to disable scrolling when the modal is open.
 * - You must ensure that external elements have scroll-snap-* CSS props defined to work correctly. Otherwise page
 * may scroll to the nearest snapped slide.
 *   example:
 * ```css
 * footer {
 *  scroll-snap-align: end;
 *  scroll-snap-stop: normal;
 * }
 * ```
 *
 * ## Usage
 *
 * ```tsx
 * const SURROUNDING_ELEMENTS_STYLES = `
 * footer {
 *   scroll-snap-align: end;
 *   scroll-snap-stop: normal;
 * }
 * `;
 * ...
 * <style>{SURROUNDING_ELEMENTS_STYLES}</style>
 * ...
 * <VerticalScroll
 *   indicatorAriaLabel="Scroll to the next slide"
 *   scrollNavAriaLabel="Scroll to slide navigation"
 *   scrollNavButtonsAriaLabelFactory={
 *     (index: number) => `Scroll to slide ${index + 1}`
 *   }
 * >
 *   <div style={{ height: "100dvh" }}>Slide 1</div>
 *   <div style={{ height: "100dvh" }}>Slide 2</div>
 *   <div style={{ height: "100dvh" }}>Slide 3</div>
 * </VerticalScroll>
 * ```
 */
export const VerticalScroll: React.FC<Props> = ({ children, ...options }) => {
  const validChildren = flattenChildren(children).filter(isValidElement);
  const slidesCount = validChildren.length;

  const memoizedOptions = useMemoWithDefault(options);

  return (
    <VerticalScrollRoot options={memoizedOptions} slidesCount={slidesCount}>
      <VerticalScrollBody>{validChildren}</VerticalScrollBody>
    </VerticalScrollRoot>
  );
};
