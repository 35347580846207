import { CalendarDate } from "@internationalized/date";
import cn from "classnames";
import { useContext, useMemo } from "react";
import {
  DatePickerProps as DatePickerPrimitiveProps,
  DateValue,
  I18nProvider,
} from "react-aria-components";
import {
  DatePicker as DatePickerPrimitive,
  Group,
  DateInput,
  Button,
  Popover,
  Dialog,
  Calendar,
  DateSegment,
  Heading,
  CalendarGrid,
  CalendarCell,
  CalendarGridHeader,
  CalendarHeaderCell,
  CalendarGridBody,
} from "react-aria-components";

import { getBorderRadiusProps } from "@/components/BorderRadius/BorderRadius";
import { Box } from "@/components/Box/Box";
import { getFocusOutlineProps } from "@/components/FocusOutline/FocusOutline";
import { FormHelperText } from "@/components/FormHelperText/FormHelperText";
import { GlobalStylesScope } from "@/components/GlobalStylesScope/GlobalStylesScope";
import { InputBorder } from "@/components/InputBorder/InputBorder";
import { getInputBorderState } from "@/components/InputBorder/InputBorder.utils";
import { InputLabel } from "@/components/InputLabel/InputLabel";
import LoadingSpinner from "@/components/LoadingSpinner/LoadingSpinner";
import { Stack } from "@/components/Stack/Stack";
import { SVGCalendar } from "@/components/SVGIcon/static/SVGCalendar";
import { SVGChevronLeft } from "@/components/SVGIcon/static/SVGChevronLeft";
import { SVGChevronRight } from "@/components/SVGIcon/static/SVGChevronRight";
import { Typography } from "@/components/Typography/Typography";
import { ThemeContext } from "@/theming/ThemeContext";
import { mergeProps } from "@/utils/reactExtensions";

import styles from "./DatePicker.module.scss";

export interface DatePickerProps<T extends DateValue> extends DatePickerPrimitiveProps<T> {
  label?: string;
  description?: string;
  errorMessage?: string;
  isInvalid?: boolean;
  isDisabled?: boolean;
  isCalendarDisabled?: boolean;
  isLoading?: boolean;
  onCalendarFocusChange?: (date: CalendarDate) => void;
  /* Display date for for focused month */
  onlyShowFocusedMonthDates?: boolean;
  locale?: string;
}

const isCurrentDay = (date: CalendarDate) => {
  const today = new Date();
  return (
    date.year === today.getFullYear() &&
    date.month === today.getMonth() + 1 &&
    date.day === today.getDate()
  );
};

/**
 * - When using DatePicker component, you need to use the [@internationalized/date](https://react-spectrum.adobe.com/internationalized/date/) library.
 * - When `isInvalid` is undefined, the component will use the default state.
 * - When `isInvalid` is false, the component will use the success state.
 */
export const DatePicker = <T extends DateValue>({
  label,
  isInvalid,
  errorMessage,
  isDisabled,
  isLoading,
  onCalendarFocusChange,
  isCalendarDisabled,
  onlyShowFocusedMonthDates,
  locale = "en-AU",
  ...props
}: DatePickerProps<T>) => {
  const formatDay = useMemo(() => {
    return (date: string) => date.substring(0, 2);
  }, []);

  const showErrorMessage = !!errorMessage;
  const themeDefinition = useContext(ThemeContext);

  const { className: focusOutlineClassName, ...restFocusOutlineProps } = getFocusOutlineProps({
    outlineOffSet: "none",
  });

  const isDefault = isInvalid === undefined;

  return (
    <I18nProvider locale={locale}>
      <DatePickerPrimitive
        {...props}
        className={styles.datePicker}
        isInvalid={isInvalid || showErrorMessage}
        isDisabled={isDisabled}
      >
        <Stack direction="column" spacing="3xs">
          {label && <InputLabel>{label}</InputLabel>}
          <InputBorder
            component="div"
            isHoverStateDisabled={isDisabled || !isDefault}
            state={getInputBorderState({
              isInvalid,
              isDefault,
            })}
          >
            <Group className={styles.dateGroup}>
              <DateInput
                {...mergeProps(getBorderRadiusProps("small"), {
                  className: styles.dateInput,
                })}
              >
                {(segment) => (
                  <DateSegment
                    segment={segment}
                    className={cn(styles.dateSegment, focusOutlineClassName)}
                    {...restFocusOutlineProps}
                  />
                )}
              </DateInput>
              {isLoading && <LoadingSpinner className={styles.loadingSpinner} />}
              <Button className={styles.calendarTriggerButton}>
                <SVGCalendar />
              </Button>
            </Group>
          </InputBorder>
          {showErrorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
        </Stack>
        <Popover className={styles.popover}>
          <GlobalStylesScope themeDefinition={themeDefinition.currentTheme}>
            <Dialog>
              <Calendar
                onFocusChange={onCalendarFocusChange}
                isDisabled={isCalendarDisabled}
                {...mergeProps(getBorderRadiusProps("medium"), {
                  className: styles.calendar,
                })}
              >
                <Box p="default" className={styles.datePickerContainer}>
                  <header className={styles.header}>
                    <Button slot="previous" className={styles.iconButtonContainer}>
                      <SVGChevronLeft />
                    </Button>
                    <Heading className={styles.heading} />
                    <Button slot="next" className={styles.iconButtonContainer}>
                      <SVGChevronRight />
                    </Button>
                  </header>
                  <div className={styles.calendarDatesContainer}>
                    <CalendarGrid weekdayStyle="short">
                      <CalendarGridHeader>
                        {(day) => (
                          <CalendarHeaderCell>
                            <Box py="s">
                              <Typography variant="h6" className={styles.headerText}>
                                {formatDay(day)}
                              </Typography>
                            </Box>
                          </CalendarHeaderCell>
                        )}
                      </CalendarGridHeader>
                      <CalendarGridBody>
                        {(date) => (
                          <CalendarCell
                            className={cn(styles.calendarCell, {
                              [styles.calendarCellToday]: isCurrentDay(date),
                              [styles.datesFromCurrentMonthOnly]: onlyShowFocusedMonthDates,
                            })}
                            date={date}
                          />
                        )}
                      </CalendarGridBody>
                    </CalendarGrid>
                    {isLoading && (
                      <div className={styles.datePickerSpinnerContainer}>
                        <LoadingSpinner />
                      </div>
                    )}
                  </div>
                </Box>
              </Calendar>
            </Dialog>
          </GlobalStylesScope>
        </Popover>
      </DatePickerPrimitive>
    </I18nProvider>
  );
};
