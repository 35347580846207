import { useEffect, useRef } from "react";

import { getAllFocusableElements } from "@/utils/DOMUtils";

interface FocusBlockerProps extends React.HTMLAttributes<HTMLDivElement>, React.PropsWithChildren {}
/**
 * `FocusBlocker` component makes its all children inaccessible by Tab key and observes mutations.
 * It is useful for components like SlideShow to skip fake slides that should not be accessible by keyboard.
 *
 * ## Usage
 *
 * ```tsx
 *  <FocusBlocker>My Children</FocusBlocker>
 * ```
 */
export const FocusBlocker: React.FC<FocusBlockerProps> = ({ children, className }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observerCallback: MutationCallback = () => {
      const focusBlocker = wrapperRef.current;

      if (!focusBlocker) return;

      const elements = getAllFocusableElements({
        root: focusBlocker,
      });

      elements.forEach((child) => {
        if (child instanceof HTMLElement) {
          child.setAttribute("tabindex", "-1");
        }
      });
    };

    const observer = new MutationObserver(observerCallback);

    if (wrapperRef.current) {
      observer.observe(wrapperRef.current, {
        childList: true,
        subtree: true,
      });
    }

    observerCallback([], observer);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className={className} ref={wrapperRef} aria-hidden={true}>
      {children}
    </div>
  );
};
