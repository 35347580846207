import { useState, useRef, useMemo, useCallback } from "react";
import { useId } from "react-aria";

import { FocusCatcher } from "@/components/FocusCatcher/FocusCatcher";
import { Popover } from "@/components/Popover/Popover";
import { TextField, type TextFieldProps } from "@/components/TextField/TextField";
import { useResizeObserver } from "@/hooks/useResizeObserver";
import { getAllFocusableElements, getNextFocusableElement } from "@/utils/DOMUtils";
import { mergeProps } from "@/utils/reactExtensions";

export interface TextFieldWithPopoverProps
  extends React.PropsWithChildren<Omit<TextFieldProps, "children">> {
  /**
   * The offset of the popover from the trigger element.
   * @default 4
   */
  popoverOffset?: number;
}

/**
 * The TextFieldWithPopover combines a text input with a Popover, allowing users to get suggested for the current input options.
 * Consumers must action any option selections in Popover themselves.
 *
 * ```tsx
 * <TextFieldWithPopover placeholder="Put your value here">
 *  <Stack>
 *    <GenericLink aria-label="Link 1" href="#" variant="subtle">
 *      Link 1
 *    </GenericLink>
 *  </Stack>
 * </TextFieldWithPopover>
 * ```
 */
export const TextFieldWithPopover: React.FC<TextFieldWithPopoverProps> = ({
  children,
  popoverOffset = 4,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [textValue, setTextValue] = useState("");

  const popoverRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const rectTriggerRef = useRef<HTMLDivElement>(null);
  const popoverId = useId();

  const focusOnFirstInPopoverOrNext = useCallback(() => {
    const firstInPopover = getAllFocusableElements({ root: popoverRef.current })[0];

    (
      firstInPopover || getNextFocusableElement({ reference: popoverRef.current, loop: true })
    )?.focus();
  }, []);

  const textFieldOptionalProps = useMemo(() => ({ type: "search" }), []);

  const textFieldMandatoryProps = useMemo(
    () => ({
      "aria-autocomplete": "list",
      "aria-haspopup": "listbox",
      role: "combobox",
      "aria-controls": popoverId,
      "aria-owns": popoverId,
      "aria-expanded": open,
      onFocus: () => setOpen(true),
      onBlur: () => {
        // We need to wait for the next event loop to check if the focus is inside the popover
        setTimeout(() => {
          popoverRef.current?.contains(document.activeElement) || setOpen(false);
        }, 0);
      },
      onKeyDown: (e: KeyboardEvent) => {
        if (e.key === "ArrowDown" && !open) {
          e.preventDefault();
          setOpen(true);
        }
        if (open && e.key === "Escape") {
          setOpen(false);
        }
      },
      // It would be nice to add this later through context, currently there is no need
      // "aria-activedescendant": "id here of focused",
    }),
    [open, popoverId]
  );

  useResizeObserver(
    rectTriggerRef,
    useCallback(() => {
      if (!rectTriggerRef.current?.offsetParent) {
        setOpen(false);
      }
    }, [])
  );

  const handleOpenChange = useCallback(
    (newState: boolean) => {
      if (newState === false) {
        inputRef.current?.focus();
      }
      setOpen?.(newState);
    },
    [setOpen]
  );

  return (
    <>
      <div ref={rectTriggerRef}>
        <TextField
          value={textValue}
          onChange={(value) => setTextValue(value)}
          ref={inputRef}
          {...mergeProps(textFieldOptionalProps, props, textFieldMandatoryProps)}
        />
      </div>
      {open && <FocusCatcher onFocus={focusOnFirstInPopoverOrNext} />}
      <Popover
        open={open}
        onOpenChange={handleOpenChange}
        triggerRef={rectTriggerRef}
        rectTriggerRef={rectTriggerRef}
        popoverRef={popoverRef}
        offset={popoverOffset}
        onBlurAttempt={(direction) => {
          if (direction === "forward") {
            const next = getNextFocusableElement({
              reference: inputRef.current,
              loop: true,
              direction,
            });
            next?.focus();
            setOpen(false);
          } else {
            inputRef.current?.focus();
          }
        }}
      >
        <div id={popoverId}>{children}</div>
      </Popover>
    </>
  );
};
