import cn from "classnames";
import {
  withGroupedChildren,
  type GroupedChildrenProps,
  type WithGroupedChildrenComponent,
} from "react-grouped-children";

import { CardProps } from "@/components/Card/Card.types";

import styles from "./Card.module.scss";
import { CardContent } from "./CardContent";
import { CardFooter } from "./CardFooter";
import { CardTop } from "./CardTop";

const childrenSpec = {
  /**
   * Use CardContent.Top to render top portion of the card
   */
  Top: CardTop,
  /**
   * Use CardContent.Footer to render footer portion of the card
   */
  Footer: CardFooter,
} as const;

const CardContainer: React.FC<
  React.PropsWithChildren & GroupedChildrenProps<typeof childrenSpec> & CardProps
> = ({
  top,
  children,
  footer,
  padding,
  noBackground,
  layout = "vertical",
  noHorizontalGap,
  justifyContent,
  className,
  classNameContent,
}) => {
  return (
    <div
      className={cn(className, styles.card, {
        [styles.noBackground]: noBackground,
        [styles.horizontal]: layout === "horizontal",
        [styles.auto]: layout === "auto",
        [styles.noHorizontalGap]: noHorizontalGap,
      })}
    >
      {top}
      {children && (
        <CardContent
          padding={padding}
          layout={layout}
          justifyContent={justifyContent}
          className={classNameContent}
        >
          {children}
        </CardContent>
      )}
      {footer}
    </div>
  );
};

const Component: WithGroupedChildrenComponent<
  typeof childrenSpec,
  React.PropsWithChildren & CardProps
> = withGroupedChildren({
  childrenSpec,
})(CardContainer);

/**
 * @deprecated This component is deprecated and will be removed in future releases.
 * Please use the `Card` component instead.
 */
export const CardWithHeader = Component;

/**
 * The `Card` component is intended to be used within a `CardTable` component to display card content.
 *
 * `Card` should be used in conjunction with `Card.Top` and `Card.Footer` to render
 * top and footer content. `children` can be a JSX element or text.
 *
 * ## Usage
 * ```tsx
 * <Card>
 *  <Card.Top>Top Content</Card.Top>
 *  {body}
 *  <Card.Footer>Footer Content</Card.Footer>
 * </Card>
 * ```
 * While the order of your children is not important as long as they are wrapped correctly, for readability it is recommended to follow the order of Top-Body-Footer.
 */
export const Card = Component;
