import { INDICATOR_PROGRESS_CSS_PROP_NAME } from "./VerticalScroll.constants";

export class VerticalScrollItem {
  private _slideWrapper: HTMLLIElement | null;
  private _navItem: HTMLButtonElement | null;
  private _isNavHidden: boolean;
  private _progress: number;

  constructor(
    slideWrapper: HTMLLIElement | null = null,
    navItem: HTMLButtonElement | null = null,
    progress = 0
  ) {
    this._slideWrapper = slideWrapper;
    this._navItem = navItem;
    this._progress = progress;
    this._isNavHidden = false;
  }

  private syncProgress() {
    this._navItem?.style.setProperty(INDICATOR_PROGRESS_CSS_PROP_NAME, this._progress.toString());
  }

  get slideWrapper() {
    return this._slideWrapper;
  }

  set slideWrapper(element: HTMLLIElement | null) {
    this._slideWrapper = element;
  }

  get navItem() {
    return this._navItem;
  }

  set navItem(element: HTMLButtonElement | null) {
    this._navItem = element;
    this.syncProgress();
  }

  get progress() {
    return this._progress;
  }

  set progress(progress: number) {
    this._progress = progress;
    this.syncProgress();
  }
}
