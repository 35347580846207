import debounce from "lodash/debounce";
import { useEffect, useRef, useState } from "react";
import { TabList as PrimitiveTabList } from "react-aria-components";

import { Box } from "@/components/Box/Box";
import { SVGChevronLeft } from "@/components/SVGIcon/static/SVGChevronLeft";
import { SVGChevronRight } from "@/components/SVGIcon/static/SVGChevronRight";

import { getTabsListProps } from "./HeadlessComponents";
import styles from "./Tabs.module.scss";
import { useCustomTabsContext } from "./Tabs.utils";

const debouncedResizeEvent = 150;

export interface TabsListProps {
  children: React.ReactNode;
}

interface ScrollArrowProps {
  direction: "left" | "right";
  onClick: () => void;
  arrowStyle?: string;
}

const ScrollArrow: React.FC<ScrollArrowProps> = ({ direction, onClick }) => {
  const isLeft = direction === "left";
  const ChevronIcon = isLeft ? SVGChevronLeft : SVGChevronRight;
  const arrowClass = isLeft ? styles.leftArrow : styles.rightArrow;

  return (
    <Box
      pr={isLeft ? "xs" : "none"}
      pl={!isLeft ? "xs" : "none"}
      className={arrowClass}
      alignItems="center"
    >
      <ChevronIcon onClick={onClick} width={16} height={16} />
    </Box>
  );
};

export const TabsList: React.FC<TabsListProps> = ({ children }) => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const { variant } = useCustomTabsContext();
  const { className: TabsListClassName } = getTabsListProps({ variant });

  const updateArrows = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const debouncedUpdateArrows = debounce(updateArrows, debouncedResizeEvent);

    scrollContainer && scrollContainer.addEventListener("scroll", debouncedUpdateArrows);
    window.addEventListener("resize", updateArrows);
    requestAnimationFrame(updateArrows);

    return () => {
      scrollContainer && scrollContainer.removeEventListener("scroll", updateArrows);
      window.removeEventListener("resize", debouncedUpdateArrows);
      debouncedUpdateArrows.cancel();
    };
  }, []);

  const scroll = (direction: "left" | "right") => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === "left" ? -100 : 100;
      scrollContainerRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const scrollLeft = () => scroll("left");
  const scrollRight = () => scroll("right");

  return (
    <Box px="2xs" className={styles.tabListContainer}>
      {canScrollLeft && <ScrollArrow direction="left" onClick={scrollLeft} />}
      <PrimitiveTabList className={TabsListClassName} ref={scrollContainerRef}>
        {children}
      </PrimitiveTabList>
      {canScrollRight && <ScrollArrow direction="right" onClick={scrollRight} />}
    </Box>
  );
};
