import { createContext, useContext } from "react";
import { Key, TabListStateContext } from "react-aria-components";

import { hasProperty } from "@/utils/typeGuards";

export enum TabOrientations {
  VERTICAL = "vertical",
  HORIZONTAL = "horizontal",
}

export enum TabVariants {
  DEFAULT = "default",
  OUTLINED = "outlined",
}

export type OrientationType = `${TabOrientations}`;
export type VariantType = `${TabVariants}`;

//Create custom TabsContext because we need manage the additional properties like registerTab
interface CustomTabsContextType {
  orientation: OrientationType;
  variant: VariantType;
  onSearchQueryMatched?: (value: boolean) => void;
}

export const CustomTabsContext = createContext<CustomTabsContextType | undefined>(undefined);

export const useCustomTabsContext = () => {
  const context = useContext(CustomTabsContext);
  if (!context) {
    throw new Error("useCustomTabsContext must be used within a CustomTabsProvider");
  }
  return context;
};

type ReactContextType<T> = T extends React.Context<infer U> ? U : never;

export const isKeyDisabled = (
  state: ReactContextType<typeof TabListStateContext>,
  nextKey: Key
) => {
  if (state.disabledKeys.has(nextKey)) {
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const props = state.collection.getItem(nextKey)?.props;
  if (hasProperty(props, "isDisabled")) {
    return !!props.isDisabled;
  }
};
