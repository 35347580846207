import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGUser: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    {...rest}
  >
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M10.516 4.294a2.818 2.818 0 1 1-5.635 0 2.818 2.818 0 0 1 5.635 0Zm1.476 0a4.293 4.293 0 1 1-8.587 0 4.293 4.293 0 0 1 8.587 0ZM1.459 16v-.925c.084-.637.298-1.482 1.064-2.188.787-.726 2.279-1.415 5.175-1.415 2.897 0 4.389.69 5.176 1.415.766.706.98 1.55 1.063 2.188V16h1.476v-1.019l-.005-.045c-.1-.79-.377-2.069-1.534-3.135-1.154-1.063-3.062-1.805-6.176-1.805-3.114 0-5.021.742-6.175 1.805C.366 12.867.088 14.146-.011 14.936l-.006.045V16H1.46Z"
      clip-rule="evenodd"
    />
  </svg>
);

SVGUser = memo(SVGUser);

export { SVGUser };
