/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
export const SitecoreContextReactContext = React.createContext({});
export const ComponentFactoryReactContext = React.createContext({});
export class SitecoreContext extends React.Component {
    constructor(props) {
        super(props);
        /**
         * Update context state. Value can be @type {LayoutServiceData} which will be automatically transformed
         * or you can provide exact @type {SitecoreContextValue}
         * @param {SitecoreContextValue | LayoutServiceData} value New context value
         */
        this.setContext = (value) => {
            this.setState({
                context: value.sitecore
                    ? this.constructContext(value)
                    : Object.assign({}, value),
            });
        };
        const context = this.constructContext(props.layoutData);
        this.state = {
            context,
            setContext: this.setContext,
        };
    }
    constructContext(layoutData) {
        var _a;
        if (!layoutData) {
            return {
                pageEditing: false,
            };
        }
        return Object.assign({ route: layoutData.sitecore.route, itemId: (_a = layoutData.sitecore.route) === null || _a === void 0 ? void 0 : _a.itemId }, layoutData.sitecore.context);
    }
    componentDidUpdate(prevProps) {
        // In case if somebody will manage SitecoreContext state by passing fresh `layoutData` prop
        // instead of using `updateSitecoreContext`
        if (!deepEqual(prevProps.layoutData, this.props.layoutData)) {
            this.setContext(this.props.layoutData);
            return;
        }
    }
    render() {
        return (React.createElement(ComponentFactoryReactContext.Provider, { value: this.props.componentFactory },
            React.createElement(SitecoreContextReactContext.Provider, { value: this.state }, this.props.children)));
    }
}
SitecoreContext.propTypes = {
    children: PropTypes.any.isRequired,
    componentFactory: PropTypes.func,
    layoutData: PropTypes.shape({
        sitecore: PropTypes.shape({
            context: PropTypes.any,
            route: PropTypes.any,
        }),
    }),
};
SitecoreContext.displayName = 'SitecoreContext';
