import cn from "classnames";

import { Typography } from "@/components/Typography/Typography";

import styles from "./Label.module.scss";

export interface LabelProps extends React.PropsWithChildren {
  /**
   * Extra styling for component
   */
  className?: string;
}
/**
 * `Label` component is used to display `L2` typography with consistent modifiers
 * like opacity, font weight and color.
 *
 * Note: if you want `L1` typography, look on `InputLabel` component instead
 *
 * `Label` allows `className` for custom styling which will be passed to Typography component
 * but you should avoid using it to keep Label consistent across consumer applications
 *
 * ## Usage
 *
 * ```tsx
 * <Label>Label</Label>
 * ```
 */
export const Label: React.FC<React.PropsWithChildren<LabelProps>> = ({
  children,
  className,
}: LabelProps) => (
  <Typography className={cn(styles.label, className)} variant="l2">
    {children}
  </Typography>
);
