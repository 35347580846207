import cn from "classnames";

import { Box } from "@/components/Box/Box";
import { CardProps } from "@/components/Card/Card.types";
import { Stack } from "@/components/Stack/Stack";

import styles from "./Card.module.scss";

export const CardContent: React.FC<
  React.PropsWithChildren & Pick<CardProps, "padding" | "layout" | "justifyContent" | "className">
> = ({ children, padding, layout, justifyContent, className }) => {
  return (
    <Box
      py={padding === "minimal" ? "2xs" : padding === "small" ? "m" : "l"}
      px={padding === "minimal" || padding === "small" ? "none" : "s"}
      className={cn(className, styles.cardContent)}
    >
      <Stack
        direction="column"
        spacing={
          padding === "minimal" ||
          padding === "small" ||
          layout === "horizontal" ||
          layout === "auto"
            ? "2xs"
            : "l"
        }
        justifyContent={!!justifyContent ? justifyContent : "flex-start"}
        alignItems={
          padding === "minimal" ||
          padding === "small" ||
          layout === "horizontal" ||
          layout === "auto"
            ? "flex-start"
            : "center"
        }
        className={styles.cardContent}
      >
        {children}
      </Stack>
    </Box>
  );
};
