import cn from "classnames";
import { useState } from "react";

import { Responsive } from "@/components/Responsive/Responsive";
import { SVGArrowDown } from "@/components/SVGIcon/static/SVGArrowDown";
import { SVGSelector, isValidIcon } from "@/components/SVGIcon/static/SVGSelector";
import { Typography } from "@/components/Typography/Typography";

import styles from "./Footer.module.scss";
import type { FooterColumnData, FooterData, FooterItemData } from "./types";
import { ANCHOR_TAG_REL_FOR_BLANK_TARGET } from "../common/constants";

export type FooterProps = {
  data: FooterData;
};

const Group = (linkGroup: FooterItemData) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleOnClick = () => {
    setIsExpanded((currentValue) => !currentValue);
  };
  if (!linkGroup) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const wrapperStyles = linkGroup.wrapperCssClass.value?.split(" ").map((a) => styles[a]);

  return (
    <div className={cn(styles.footerBlock, wrapperStyles)}>
      <div className={styles.footerTitle} onClick={handleOnClick}>
        <Typography component="h3" variant="h5">
          {linkGroup.name}
        </Typography>
        {!wrapperStyles?.includes(styles.isPhone) && (
          <SVGArrowDown
            className={cn(
              styles.footerTitleChevron,
              isExpanded ? styles.footerTitleChevronExpanded : ""
            )}
            height={25}
            width={25}
          />
        )}
      </div>
      <div className={cn(styles.footerLinkGroup, isExpanded ? styles.footerLinkGroupExpanded : "")}>
        {linkGroup.children.results.map((item, index) => {
          const SvgComponent = isValidIcon(item.icon?.value) && SVGSelector[item.icon.value];
          return item.isDivider.value ? (
            <span className={styles.divider} key={`${linkGroup.name}${index}`} />
          ) : (
            <div
              className={cn(styles.linkWrapper, SvgComponent && styles.telWrapper)}
              key={`${linkGroup.name}${item.link.label || ""}`}
            >
              {SvgComponent && (
                <div className={styles.icon}>
                  <SvgComponent width={19} height={19} className={styles.footerTelIcon} />
                </div>
              )}
              <a
                className={cn(styles.footerLink, SvgComponent && styles.phoneLink)}
                href={item.link.url}
                target={item.link.target || ""}
                rel={item.link.target === "_blank" ? ANCHOR_TAG_REL_FOR_BLANK_TARGET : ""}
              >
                <span>{item.link.label}</span>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const SocialMedia = (medias: FooterItemData) => {
  if (!medias) {
    return null;
  }

  return (
    <div className={styles.socialMedia}>
      {medias.children.results.map((social) => {
        const SvgComponent = isValidIcon(social.icon?.value) && SVGSelector[social.icon.value];
        return (
          SvgComponent && (
            <a
              className={styles.socialMediaIconLink}
              key={social.icon?.value || social.link?.url}
              href={social.link.url}
              target="_blank"
              rel={ANCHOR_TAG_REL_FOR_BLANK_TARGET}
              aria-label={social.link.label || ""}
            >
              <SvgComponent key={social.link.label} width={24} height={24} />
            </a>
          )
        );
      })}
    </div>
  );
};

const Column: React.FC<FooterColumnData> = ({ items }) => (
  <div className={styles.footerColumn}>
    {items.results.map((itemLevel1) => {
      if (itemLevel1.itemType.value === "LinkGroup") {
        if (itemLevel1.isMobileOnly.value) {
          return (
            <Responsive breakpoint="small" key={itemLevel1.name}>
              <Group {...itemLevel1} />
            </Responsive>
          );
        } else if (itemLevel1.isDesktopOnly.value) {
          return (
            <Responsive breakpoint="large" key={itemLevel1.name}>
              <Group {...itemLevel1} />
            </Responsive>
          );
        }
        return <Group {...itemLevel1} key={itemLevel1.name} />;
      } else if (itemLevel1.itemType.value === "SocialMedia") {
        return <SocialMedia {...itemLevel1} key={itemLevel1.name} />;
      }
    })}
  </div>
);

/**
 * The top part/main section of the footer. This element contains the sitemap links, socials and contact information.
 */
const Footer: React.FC<FooterProps> = ({ data }) => {
  const footerColumns = data?.details?.children?.results;

  return (
    <>
      <div className={styles.footer}>
        <div className={styles.footerWrap}>
          {footerColumns.map((column) => (
            <Column {...column} key={column.name} />
          ))}
        </div>
      </div>
    </>
  );
};

export { Footer };
