import debounce from "lodash/debounce";
import {
  useCallback,
  useMemo,
  useState,
  useRef,
  MouseEvent,
  TouchEvent,
  useEffect,
  RefObject,
  MutableRefObject,
} from "react";

import { useTransitionControl } from "@/contexts/TransitionContext";
import { useResizeObserver } from "@/hooks/useResizeObserver";

import { DEBOUNCE_TIME, DRAG_THRESHOLD, TRANSITION_INTERVAL } from "./SlideShow.utils";

/**
 * Custom hook to handle drag and swipe gestures.
 *
 * @param {() => void} onSwipeLeft - Callback function to be called when a left swipe is detected.
 * @param {() => void} onSwipeRight - Callback function to be called when a right swipe is detected.
 * @param {(value: boolean) => void} setIsLeftSwipe - Function to set the swipe direction.
 * @returns {Object} Handlers for mouse and touch events.
 *
 * @example
 * const { handleMouseDown, handleMouseMove, handleMouseUp, handleTouchStart, handleTouchMove, handleTouchEnd } = useDragAndSwipe(
 *   () => console.log('Swiped left'),
 *   () => console.log('Swiped right'),
 *   (isLeftSwipe) => console.log('Is left swipe:', isLeftSwipe)
 * );
 */
export const useDragAndSwipe = (onSwipeLeft: () => void, onSwipeRight: () => void) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [translate, setTranslate] = useState(0);

  const handleStart = useCallback((startPosition: number) => {
    setIsDragging(true);
    setStartX(startPosition);
  }, []);

  const handleMove = useCallback(
    (currentPosition: number) => {
      if (!isDragging) return;
      const diff = currentPosition - startX;
      setTranslate(diff);
    },
    [isDragging, startX]
  );

  const handleEnd = useCallback(() => {
    setIsDragging(false);
    if (translate > DRAG_THRESHOLD) {
      onSwipeRight();
    } else if (translate < -DRAG_THRESHOLD) {
      onSwipeLeft();
    }
    setTranslate(0);
  }, [translate, onSwipeRight, onSwipeLeft]);

  return useMemo(
    () => ({
      isDragging,
      handleMouseDown: (e: React.MouseEvent<HTMLDivElement>) => handleStart(e.clientX),
      handleMouseMove: (e: MouseEvent<HTMLDivElement>) => handleMove(e.clientX),
      handleMouseUp: handleEnd,
      handleTouchStart: (e: TouchEvent<HTMLDivElement>) => handleStart(e.touches[0].clientX),
      handleTouchMove: (e: TouchEvent<HTMLDivElement>) => handleMove(e.touches[0].clientX),
      handleTouchEnd: handleEnd,
    }),
    [handleStart, handleMove, handleEnd, isDragging]
  );
};

type SyncVideoSlidesParams = {
  childRefs: React.MutableRefObject<RefObject<HTMLDivElement>[]>;
};

/**
 * Hook to synchronize video playback states between specific video elements in a list of slides,
 * in order to create a seamless video playback experience when looping through the slides.
 *
 * @param {Object} SyncVideoSlidesParams - Parameters for the hook.
 * @param {React.RefObject<HTMLDivElement>[]} SyncVideoSlidesParams.childRefs -
 *        Array of refs pointing to the DOM nodes of the slides.
 *
 * Behavior:
 * - Synchronizes playback of:
 *   - Second-to-last video with the first video.
 *   - Second video with the last video.
 * - Synchronization includes:
 *   - Matching `currentTime` between source and target videos.
 *   - Pausing and playing target video based on the source video.
 * @param {React.RefObject<HTMLDivElement>} props.containerRef - The reference to the container element.
 */
export const useSyncVideoSlides = ({ childRefs }: SyncVideoSlidesParams) => {
  useEffect(() => {
    const relevantRefs = [
      childRefs.current[0],
      childRefs.current[1],
      childRefs.current[childRefs.current.length - 2],
      childRefs.current[childRefs.current.length - 1],
    ];

    const getVideoElements = (): (HTMLVideoElement | null)[] =>
      relevantRefs.map((ref) => ref.current?.querySelector("video") as HTMLVideoElement | null);

    const [firstVideo, secondVideo, secondLastVideo, lastVideo] = getVideoElements();

    const syncVideos = (sourceVideo: HTMLVideoElement, targetVideo: HTMLVideoElement) => {
      targetVideo.currentTime = sourceVideo.currentTime;
      if (!sourceVideo.paused) {
        void targetVideo.play();
      } else {
        targetVideo.pause();
      }
    };

    const removeListeners: (() => void)[] = [];

    const addSyncListeners = (sourceVideo: HTMLVideoElement, targetVideo: HTMLVideoElement) => {
      const playHandler = () => syncVideos(sourceVideo, targetVideo);
      const pauseHandler = () => syncVideos(sourceVideo, targetVideo);

      sourceVideo.addEventListener("play", playHandler);
      sourceVideo.addEventListener("pause", pauseHandler);

      removeListeners.push(() => {
        sourceVideo.removeEventListener("play", playHandler);
        sourceVideo.removeEventListener("pause", pauseHandler);
      });
    };

    if (firstVideo && secondLastVideo) {
      addSyncListeners(secondLastVideo, firstVideo);
    }

    if (lastVideo && secondVideo) {
      addSyncListeners(secondVideo, lastVideo);
    }

    return () => {
      removeListeners.forEach((removeListener) => removeListener());
    };
  }, [childRefs]);
};

interface UseDebouncedScrollHandlerOptions {
  containerRef: React.RefObject<HTMLElement>;
  slideCount: number;
  debounceTime?: number;
  onSlideChange?: (currentSlide: number) => void;
}

/**
 * Custom hook that handles debounced scroll events for a sliding container.
 * It ensures that the scroll position updates the current slide index
 * and wraps around the slide count when reaching the start or end.
 *
 * @param {Object} params - The configuration options for the scroll handler.
 * @param {React.RefObject<HTMLElement>} containerRef - A reference to the container element that is scrollable.
 * @param {number} slideCount - Total number of slides in the container.
 * @param {Function} onSlideChange - Callback function triggered when the slide changes.
 */
export const useDebouncedScrollHandler = ({
  containerRef,
  slideCount,
  onSlideChange,
}: UseDebouncedScrollHandlerOptions) => {
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const debouncedHandleScroll = debounce(() => {
      const container = containerRef.current;
      if (!container) return;

      const slideWidth = container.offsetWidth;
      const currentSlide = Math.round(container.scrollLeft / slideWidth);

      if (currentSlide === 0) {
        const newSlide = slideCount - 2;
        onSlideChange?.(newSlide);
        container.scrollTo({ left: slideWidth * newSlide, behavior: "auto" });
      } else if (currentSlide > slideCount - 2) {
        const newSlide = 1;
        onSlideChange?.(newSlide);
        container.scrollTo({ left: slideWidth, behavior: "auto" });
      } else {
        onSlideChange?.(currentSlide);
      }
    }, DEBOUNCE_TIME);

    container.addEventListener("scroll", debouncedHandleScroll);

    return () => {
      container.removeEventListener("scroll", debouncedHandleScroll);
      debouncedHandleScroll.cancel();
    };
  }, [onSlideChange, containerRef, slideCount]);
};

interface UseDebouncedResizeHandlerOptions {
  containerRef: React.RefObject<HTMLElement>;
  currentSlide: number;
}

/**
 * Custom hook that handles debounced resize events for a container element.
 * It ensures that the container scrolls to the current slide when the window is resized.
 *
 * @param {Object} params - The configuration options for the resize handler.
 * @param {React.RefObject<HTMLElement>} containerRef - A reference to the container element that is resizable.
 * @param {number} currentSlide - The index of the current slide to maintain when the container is resized.
 */
export const useDebouncedResizeHandler = ({
  containerRef,
  currentSlide,
}: UseDebouncedResizeHandlerOptions) => {
  const currentSlideRef = useRef<number>(currentSlide);
  currentSlideRef.current = currentSlide;

  const handleResize = useMemo(
    () =>
      debounce(() => {
        if (!containerRef.current) return;

        const slideWidth = containerRef.current.offsetWidth;
        containerRef.current.scrollTo({
          left: slideWidth * currentSlideRef.current,
          behavior: "auto",
        });
      }, DEBOUNCE_TIME),
    [containerRef]
  );

  useResizeObserver(containerRef, handleResize);

  useEffect(() => {
    return () => {
      handleResize.cancel();
    };
  }, [handleResize]);
};

interface UseAutoSlideTransitionOptions {
  nextSlide: () => void;
  currentSlide: number;
  slideDurations: number[];
}

/**
 * Custom hook to automatically transition to the next slide at a specified interval.
 * The transition will pause if `isTransitionPaused` is true.
 *
 * @param {Object} params - The configuration options for the auto-slide transition.
 * @param {Function} nextSlide - A function that is called to transition to the next slide.
 * @param {number} currentSlide - The index of the current slide.
 */
export const useAutoSlideTransition = ({
  nextSlide,
  currentSlide,
  slideDurations,
}: UseAutoSlideTransitionOptions) => {
  const { transitionDuration, isTransitionPaused, setTransitionDuration } =
    useTransitionControl() || {
      isTransitionPaused: true,
    };

  useEffect(() => {
    setTransitionDuration && setTransitionDuration(slideDurations[currentSlide]);
  }, [setTransitionDuration, currentSlide, slideDurations]);

  useEffect(() => {
    if (isTransitionPaused) return;

    const interval = setTimeout(() => {
      nextSlide();
    }, transitionDuration || TRANSITION_INTERVAL);

    return () => {
      clearTimeout(interval);
    };
  }, [isTransitionPaused, nextSlide, transitionDuration, currentSlide]);
};

interface UseSlideDurationsProps {
  childRefs: MutableRefObject<RefObject<HTMLDivElement>[]>;
  slideCount: number;
  setSlideDurations: React.Dispatch<React.SetStateAction<number[]>>;
}

/**
 * Custom hook to manage slide durations and event listeners for video elements.
 *
 * @param {UseSlideDurationsProps} props - The properties for the hook.
 * @param {React.MutableRefObject<(HTMLDivElement | null)[]>} props.childRefs - The references to the slide elements.
 * @param {number} props.slideCount - The total number of slides.
 * @param {React.Dispatch<React.SetStateAction<number[]>>} props.setSlideDurations - The function to set the slide durations.
 */
export const useSlideDurations = ({
  childRefs,
  slideCount,
  setSlideDurations,
}: UseSlideDurationsProps) => {
  const updateSlideDurations = useCallback(() => {
    const updatedDurations = Array.from({ length: slideCount }).map((_, index) => {
      const ref = childRefs.current[index];

      if (ref) {
        const videoElement = ref.current?.querySelector<HTMLVideoElement>("video") || null;
        return videoElement ? videoElement.duration * 1000 : TRANSITION_INTERVAL;
      }

      return TRANSITION_INTERVAL;
    });

    setSlideDurations(updatedDurations);
  }, [childRefs, slideCount, setSlideDurations]);

  const handleEventListeners = useCallback(
    (action: "add" | "remove") => {
      const currentChildRefs = childRefs.current;

      Array.from({ length: slideCount }).forEach((_, index) => {
        const ref = currentChildRefs[index];
        if (ref) {
          const videoElement = ref.current?.querySelector<HTMLVideoElement>("video");

          if (action === "add") {
            videoElement?.addEventListener("loadedmetadata", updateSlideDurations);
          } else {
            videoElement?.removeEventListener("loadedmetadata", updateSlideDurations);
          }
        }
      });
    },
    [childRefs, slideCount, updateSlideDurations]
  );

  useEffect(() => {
    updateSlideDurations();
    handleEventListeners("add");

    return () => {
      handleEventListeners("remove");
    };
  }, [handleEventListeners, updateSlideDurations]);
};
