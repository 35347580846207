export const spacingMap = {
  least: "--ld-c-box-pad-scaled-least",
  "even-less": "--ld-c-box-pad-scaled-even-less",
  less: "--ld-c-box-pad-scaled-less",
  default: "--ld-c-box-pad-scaled-default",
  more: "--ld-c-box-pad-scaled-more",
  "even-more": "--ld-c-box-pad-scaled-even-more",
  most: "--ld-c-box-pad-scaled-most",
  "5xl": "--ld-c-box-pad-static-5xl",
  "4xl": "--ld-c-box-pad-static-4xl",
  "3xl": "--ld-c-box-pad-static-3xl",
  "2xl": "--ld-c-box-pad-static-2xl",
  xl: "--ld-c-box-pad-static-xl",
  l: "--ld-c-box-pad-static-l",
  m: "--ld-c-box-pad-static-m",
  s: "--ld-c-box-pad-static-s",
  xs: "--ld-c-box-pad-static-xs",
  "2xs": "--ld-c-box-pad-static-2xs",
  "3xs": "--ld-c-box-pad-static-3xs",
  "4xs": "--ld-c-box-pad-static-4xs",
  none: "--ld-c-box-pad-static-none",
} as const;

export type SpacingScale = keyof typeof spacingMap;

export const mapSpacingToCSSVar = (spacing: SpacingScale | undefined): string =>
  spacing ? `var(${spacingMap[spacing]})` : `var(${spacingMap.none})`;
