import React from 'react';
import { PlaceholderCommon } from './PlaceholderCommon';
import { withComponentFactory } from '../enhancers/withComponentFactory';
import { HorizonEditor } from '@sitecore-jss/sitecore-jss/utils';
/**
 * @param {HtmlElementRendering | ComponentRendering} rendering
 */
function isRawRendering(rendering) {
    return (!rendering.componentName &&
        rendering.name !== undefined);
}
class PlaceholderComponent extends PlaceholderCommon {
    constructor(props) {
        super(props);
        this.isEmpty = false;
    }
    componentDidMount() {
        super.componentDidMount();
        if (this.isEmpty && HorizonEditor.isActive()) {
            HorizonEditor.resetChromes();
        }
    }
    /**
     * In case we need to render an empty placeholder, some part of the markup will be inserted by the EE,
     * so we need to separate the empty placeholder's markup and allow React reconciliation to be executed correctly
     * and retain sibling tags
     * @param {React.ReactNode | React.ReactElement[]} node react node
     * @returns react node
     */
    renderEmptyPlaceholder(node) {
        return React.createElement("div", { className: "sc-jss-empty-placeholder" }, node);
    }
    render() {
        const childProps = Object.assign({}, this.props);
        delete childProps.componentFactory;
        if (this.state.error) {
            if (childProps.errorComponent) {
                return React.createElement(childProps.errorComponent, { error: this.state.error });
            }
            return (React.createElement("div", { className: "sc-jss-placeholder-error" },
                "A rendering error occurred: ",
                this.state.error.message,
                "."));
        }
        const renderingData = childProps.rendering;
        const placeholderData = PlaceholderCommon.getPlaceholderDataFromRenderingData(renderingData, this.props.name);
        const components = this.getComponentsForRenderingData(placeholderData);
        this.isEmpty = placeholderData.every((rendering) => isRawRendering(rendering));
        if (this.props.renderEmpty && this.isEmpty) {
            const rendered = this.props.renderEmpty(components);
            return components.length ? this.renderEmptyPlaceholder(rendered) : rendered;
        }
        else if (components.length && this.isEmpty) {
            return this.renderEmptyPlaceholder(components);
        }
        else if (this.props.render) {
            return this.props.render(components, placeholderData, childProps);
        }
        else if (this.props.renderEach) {
            const renderEach = this.props.renderEach;
            return components.map((component, index) => {
                if (component && component.props && component.props.type === 'text/sitecore') {
                    return component;
                }
                return renderEach(component, index);
            });
        }
        else {
            return components;
        }
    }
}
PlaceholderComponent.propTypes = PlaceholderCommon.propTypes;
export const Placeholder = withComponentFactory(PlaceholderComponent);
