import { Box } from "@/components/Box/Box";
import { Stack } from "@/components/Stack/Stack";

export const CardFooter: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Box pb="l" px="s">
      <Stack direction="column" spacing="2xs" justifyContent="center" alignItems="center">
        {children}
      </Stack>
    </Box>
  );
};
