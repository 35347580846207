import { useCallback, useEffect, useRef, useState } from "react";

import { useAnalytics } from "@/utils/DataAnalytics";
import {
  DataAnalyticsEventActions,
  DataAnalyticsEventTargets,
} from "@/utils/DataAnalytics/models/DataAnalyticsDefinitions";
import { hasValue, isElementOfArray } from "@/utils/typeGuards";

import { VideoSource } from "./sharedTypes";
import { ContainerCustomProps } from "../types";

const observerPlayThreshold = 0.2;
const fullVideoProgress = 100;
const progressReportingThreshold = 10;

export const analyticDeclaration = { component: "DynamicMedia" };

export const playbackEvents = [
  DataAnalyticsEventActions.VideoPause,
  DataAnalyticsEventActions.VideoPlay,
  DataAnalyticsEventActions.VideoEnded,
] as const;

const getObserver = (
  videoElement: HTMLVideoElement,
  isVideoIntersectingRef: React.MutableRefObject<boolean | undefined>,
  sync: () => void
) => {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        const videoElement = entry.target as HTMLVideoElement;

        if (
          !entry ||
          typeof videoElement.pause !== "function" ||
          typeof videoElement.play !== "function"
        ) {
          return;
        }

        isVideoIntersectingRef.current =
          entry.isIntersecting && entry.intersectionRatio >= observerPlayThreshold;

        sync();
      });
    },
    {
      rootMargin: "0px",
      threshold: observerPlayThreshold,
    }
  );
  observer.observe(videoElement);

  return observer;
};

export const getVideoUrls = (video: string | undefined): VideoSource[] | undefined => {
  if (!video) {
    return;
  }
  const videos = video.split(/\<br\s*\/\>|\r?\n/gi);
  const regex = /^((?:https?:\/)?\/[^\|\r\n]+)(?:\|(.+))?$/i;
  const expecterPatternsNumber = 3;

  const processedSources = videos
    .map((videoSrc: string) => {
      const match = regex.exec(videoSrc);
      if (!match || match.length < expecterPatternsNumber) {
        return undefined;
      }
      const [, src, type] = match;
      return { src, type };
    })
    .filter(hasValue);

  return (processedSources.length && processedSources) || undefined;
};

export const useVideoStateManager = (
  videoComponentRef: React.RefObject<HTMLVideoElement>,
  isVideoPaused: boolean | undefined
) => {
  const [videoCanPlay, setVideoCanPlay] = useState(false);

  const isVideoPausedRef = useRef<boolean>();
  const isVideoIntersectingRef = useRef<boolean>();
  isVideoPausedRef.current = isVideoPaused;

  const syncEvents = useCallback(() => {
    const videoElement = videoComponentRef.current;
    if (!videoElement) return;

    if (isVideoIntersectingRef.current && !isVideoPausedRef?.current) {
      void videoElement.play();
    } else {
      videoElement.pause();
    }
  }, [videoComponentRef]);

  useEffect(() => {
    syncEvents();
  }, [isVideoPaused, syncEvents]);

  useEffect(() => {
    const videoElement = videoComponentRef.current;
    if (!videoElement) return;

    const handleCanplay = () => setVideoCanPlay(true);
    const observer = getObserver(videoElement, isVideoIntersectingRef, syncEvents);

    videoElement.addEventListener("canplay", handleCanplay, true);

    return () => {
      if (videoElement) {
        observer.unobserve(videoElement);
        videoElement.removeEventListener("canplay", handleCanplay, true);
      }
    };
  }, [syncEvents, videoComponentRef]);
  return videoCanPlay;
};

export const useVideoAnalytics = (videoComponentRef: React.RefObject<HTMLVideoElement>) => {
  const { registerInteraction } = useAnalytics(analyticDeclaration);
  const videoProgress = useRef(0);

  useEffect(() => {
    const videoElement = videoComponentRef.current;
    if (!videoElement) {
      return;
    }

    const handlePlaybackChange = (e: Event) =>
      isElementOfArray(playbackEvents, e.type)
        ? registerInteraction(DataAnalyticsEventTargets.Unknown, e.type, {
            src: videoElement.currentSrc,
          })
        : undefined;
    const handleTimeUpdate = () => {
      if (
        !videoElement ||
        !isFinite(videoElement.currentTime) ||
        !isFinite(videoElement.duration)
      ) {
        return;
      }

      const currentProgress =
        (Math.floor(
          (progressReportingThreshold * videoElement.currentTime) / videoElement.duration
        ) *
          fullVideoProgress) /
        progressReportingThreshold;
      if (videoProgress.current === currentProgress) {
        return;
      }
      videoProgress.current = currentProgress;
      registerInteraction(DataAnalyticsEventTargets.Unknown, DataAnalyticsEventActions.VideoPlay, {
        src: videoElement.currentSrc,
        currentProgress: `${currentProgress}%`,
      });
    };

    videoElement.addEventListener("timeupdate", handleTimeUpdate, true);
    playbackEvents.forEach((ev) => videoElement.addEventListener(ev, handlePlaybackChange, true));

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("timeupdate", handleTimeUpdate, true);
        playbackEvents.forEach((ev) =>
          videoElement.removeEventListener(ev, handlePlaybackChange, true)
        );
      }
    };
  }, [videoComponentRef, registerInteraction]); // https://github.com/facebook/react/issues/20752
};

export const getCustomCssProps = ({
  minHeightDesktop,
  minHeightMobile,
  aspectRatio,
}: ContainerCustomProps): React.CSSProperties => ({
  ["--dynamic-media-min-height-desktop"]: minHeightDesktop
    ? (`${minHeightDesktop}px` as const)
    : undefined,
  ["--dynamic-media-min-height-mobile"]: minHeightMobile
    ? (`${minHeightMobile}px` as const)
    : undefined,
  ["--dynamic-media-aspect-ratio"]:
    typeof aspectRatio === "number" && aspectRatio > 0
      ? (`${aspectRatio * 100}%` as const)
      : undefined,
});
