import "./SecondaryNavigationLinks.scss";

import * as React from "react";

import { featuredSecondaryNavigationItem, getSecondaryNavigationPrimaryItems } from "../secondaryNavigationItems";

import { RouteSection } from "../../../helpers/routes";
import SecondaryNavigationLink from "../SecondaryNavigationLink/SecondaryNavigationLink";
import classnames from "classnames";
import { createBemFn } from "lexus-style-guide/utilities/bem";
import { removeDash } from "../../../helpers/string";
import { routeString } from "../../../helpers/routes";
import { useVehicles } from "../../../hooks/useVehicles";
import { useSelector } from "react-redux";
import { dashboardBannerStatesSelector } from "ReduxSlices/user";
import { UserEncoreStatus } from "Helpers/users";

const bem = createBemFn("secondary-navigation-links");

const SecondaryNavigationLinks: React.FC = () => {
    const vehicles = useVehicles(true);
    const allowedRoutes = getSecondaryNavigationPrimaryItems(vehicles) as RouteSection[];
    const { membershipStatus } = useSelector(dashboardBannerStatesSelector);

    const isDisabledRouteForNoVehicle = (route: RouteSection) => {
        return [RouteSection.Encore, RouteSection.Vehicle].includes(route) && vehicles && vehicles.length === 0;
    };

    const isDisabledRouteForExpiredMembership = (route: RouteSection) => {
        return route === RouteSection.Encore && membershipStatus === UserEncoreStatus.EXPIRED;
    };

    return (
        <nav className={bem()}>
            {allowedRoutes
                .filter(route => route !== featuredSecondaryNavigationItem)
                .map(route => (
                    <SecondaryNavigationLink
                        to={routeString(route)}
                        className={classnames(bem("link"), {
                            disabled: isDisabledRouteForNoVehicle(route) || isDisabledRouteForExpiredMembership(route),
                        })}
                        disabled={isDisabledRouteForNoVehicle(route) || isDisabledRouteForExpiredMembership(route)}
                        key={route}
                    >
                        {removeDash(route)}
                    </SecondaryNavigationLink>
                ))}
        </nav>
    );
};

export default SecondaryNavigationLinks;
