import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGService: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={width}
    height={height}
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.137 6.353c1.719-1.27 4.11-1.577 6.076-.67a.667.667 0 0 1 .192 1.077l-2.6 2.6c-.273.273-.273.651 0 .924l.8.8c.273.273.65.273.924 0l2.533-2.533a.667.667 0 0 1 1.083.206 6.102 6.102 0 0 1-1.197 6.66l-.01.01c-1.688 1.689-4.095 2.12-6.169 1.435L9.871 25.76a2.624 2.624 0 0 1-3.827-.086l-.002-.001c-1.01-1.102-.794-2.774.152-3.721l.001-.001 8.901-8.965c-.743-2.303-.095-4.996 2.032-6.626l.01-.007Zm.797 1.068c-1.738 1.337-2.212 3.607-1.45 5.484.1.247.044.53-.144.72l-9.2 9.267-.002.002c-.52.52-.57 1.379-.114 1.877.54.584 1.37.582 1.905.046l9.2-9.2a.667.667 0 0 1 .726-.144c1.702.704 3.74.407 5.135-.984a4.767 4.767 0 0 0 1.238-4.218l-1.757 1.756a1.955 1.955 0 0 1-2.809 0l-.8-.8a1.955 1.955 0 0 1 0-2.81l1.834-1.833a4.833 4.833 0 0 0-3.762.837Z"
      clipRule="evenodd"
    />
    <circle cx="8.667" cy="23.222" r=".667" />
  </svg>

  /* eslint-enable max-len */
);
SVGService = memo(SVGService);

export { SVGService };
