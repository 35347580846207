import { Stack } from "@/components/Stack/Stack";

import styles from "./RadioItemDetail.module.scss";
import { Typography } from "../Typography/Typography";

export interface RadioItemDetail {
  icon: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}

export const RadioItemDetail: React.FC<RadioItemDetail> = ({ icon, children, className }) => {
  return (
    <Stack alignItems="center" spacing="3xs" className={className}>
      <div className={styles.svgIconWrapper}>{icon}</div>
      <Typography variant="b2" className={styles.breakWord}>
        {children}
      </Typography>
    </Stack>
  );
};

RadioItemDetail.displayName = "RadioItemDetail";
