import cn from "classnames";
import { useContext, useLayoutEffect } from "react";
import { Key, TabListStateContext } from "react-aria-components";

import styles from "./Tabs.module.scss";
import { isKeyDisabled, OrientationType, VariantType } from "./Tabs.utils";

/**
 * A headless component that handles the selection of a new tab when the current tab is disabled.
 *
 * @param {Object} props - The props for the DisableHandler component.
 * @param {string} props.id - The ID of the tab.
 * @param {boolean} props.isDisabled - Whether the tab is disabled.
 * @returns {null} This component does not render anything.
 *
 * @example
 * <DisableHandler id="tab1" isDisabled={true} />
 */
export const DisableHandler: React.FC<{ id: string; isDisabled: boolean }> = ({
  id,
  isDisabled,
}) => {
  const state = useContext(TabListStateContext);
  const isActive = state?.selectedKey === id;

  useLayoutEffect(() => {
    if (isDisabled && isActive) {
      const getEnabledKey = (direction: "forward" | "backward") => {
        let nextKey: Key | null = state.selectedKey;
        while (nextKey) {
          nextKey =
            state.collection[direction === "forward" ? "getKeyAfter" : "getKeyBefore"](nextKey);
          if (nextKey && !isKeyDisabled(state, nextKey)) {
            return nextKey;
          }
        }
      };

      const enabledKey = getEnabledKey("forward") || getEnabledKey("backward");
      enabledKey && state.setSelectedKey(enabledKey);
    }
  }, [isDisabled, isActive, state]);

  return null;
};

/**
 * A headless component that provides styles for TabsTab component.
 *
 * @param {Object} params - The parameters for the function.
 * @param {OrientationType} params.orientation - The orientation type of the Tabs.
 * @param {VariantType} params.variant - The variant type of the Tabs.
 * @returns {Object} The props for the Tabs tab.
 * @returns {string} return.className - The class name for the Tabs tab.
 *
 */
export const getTabsTabProps = ({
  variant,
}: {
  orientation: OrientationType;
  variant: VariantType;
}): {
  className: string;
} => ({
  className: cn(styles[`${variant}TabsTab`]),
});

/**
 * A headless component that provides styles for TabsList component.
 *
 * @param {Object} params - The parameters for the function.
 * @param {VariantType} params.variant - The variant type of the Tabs.
 * @returns {Object} The props for the TabsList.
 * @returns {string} return.className - The class name for the Tabs list.
 *
 */
export const getTabsListProps = ({
  variant,
}: {
  variant: VariantType;
}): {
  className: string;
} => ({
  className: cn(styles.tabsList, styles[`${variant}TabsList`]),
});
