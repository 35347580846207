import { mergeProps as mergePropsAria } from "@react-aria/utils";
import { CSSProperties, isValidElement } from "react";

import { hasProperty, isValidReactNode } from "./typeGuards";

export const getValidReactChildrenOrDefault = <D>(node: unknown, defaultValue: D) =>
  isValidElement(node) &&
  hasProperty(node.props, "children") &&
  isValidReactNode(node.props.children)
    ? node.props.children
    : defaultValue;

interface Props {
  [key: string]: unknown;
}

type PropsArg = Props | React.HTMLAttributes<HTMLElement> | null | undefined;

// taken from: https://stackoverflow.com/questions/51603250/typescript-3-parameter-list-intersection-type/51604379#51604379
type TupleTypes<T> = { [P in keyof T]: T[P] } extends { [key: number]: infer V }
  ? NullToObject<V>
  : never;
type NullToObject<T> = T extends null | undefined ? object : T;

type UnionToIntersection<U> = (U extends unknown ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;

/**
 * Merges multiple props objects together. Event handlers are chained,
 * classNames are combined, and ids are deduplicated - different ids
 * will trigger a side-effect and re-render components hooked up with `useId`.
 * Styles objects are shallow merged.
 * For all other props, the last prop object overrides all previous ones.
 * @param args - Multiple sets of props to merge together.
 */
export const mergeProps = <T extends PropsArg[]>(
  ...args: T
): UnionToIntersection<TupleTypes<T>> & object => {
  let style: CSSProperties | undefined =
    args[0]?.style && typeof args[0]?.style === "object" ? args[0].style : undefined;

  for (let i = 1; i < args.length; i++) {
    const nextStyle = args[i]?.style;
    const isValidStyle = !!nextStyle && typeof nextStyle === "object";

    if (style && isValidStyle) {
      style = { ...style, ...nextStyle };
    } else if (isValidStyle) {
      style = nextStyle;
    }
  }

  return (mergePropsAria(...(style ? [...args, { style }] : args)) ?? {}) as UnionToIntersection<
    TupleTypes<T>
  > &
    object;
};
