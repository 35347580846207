import { getBorderRadiusProps } from "@/components/BorderRadius/BorderRadius";
import { mergeProps } from "@/utils/reactExtensions";

import styles from "./CardTable.module.scss";

/**
 * A composition component which act as a container to hold and stack more than 2 of the `Card` component.
 * Can be used to create pseudo tables and carousels.
 *
 * ## Usage
 * ```tsx
 * <CardTable>
 *   <Card>Card 1</Card>
 *   <Card>Card 2</Card>
 *   <Card>Card 3</Card>
 * </CardTable>
 * ```
 */
export const CardTable: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className={styles.container}>
      <div
        {...mergeProps(getBorderRadiusProps("small"), {
          className: styles.cardTable,
        })}
      >
        {children}
      </div>
    </div>
  );
};
