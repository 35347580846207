import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGArrowRight: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  /* eslint-disable max-len */
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={width}
    height={height}
    fill="currentColor"
    {...rest}
  >
    <path
      fill="currentColor"
      d="M8.08526 2.8457L6.67105 4.25992L10.1719 7.76074H2.00098V9.75861H10.1724L6.67104 13.2599L8.08526 14.6741L13.9995 8.75992L8.08526 2.8457Z"
    />
  </svg>

  /* eslint-enable max-len */
);
SVGArrowRight = memo(SVGArrowRight);

export { SVGArrowRight };
