import { createContext, useContext, useReducer } from "react";

import { BodyTypeData, FilterTypes } from "../types/PrimaryNav.types";

export enum FilterActionTypes {
  UPDATE_FUEL = "UPDATE_FUEL",
  UPDATE_BODY = "UPDATE_BODY",
  UPDATE_MODEL_CARD_RESULTS = "UPDATE_MODEL_CARD_RESULTS",
  RESET_FILTERS = "RESET_FILTERS",
  SET_FUEL_DEFAULT = "SET_FUEL_DEFAULT",
  SET_BODY_DEFAULT = "SET_BODY_DEFAULT",
  INITIALISE_FILTERS = "INITIALISE_FILTERS",
}

type FilterAction =
  | { type: FilterActionTypes.UPDATE_FUEL; payload: FilterTypes[] }
  | { type: FilterActionTypes.UPDATE_BODY; payload: FilterTypes[] }
  | { type: FilterActionTypes.UPDATE_MODEL_CARD_RESULTS; payload: BodyTypeData[] }
  | { type: FilterActionTypes.RESET_FILTERS }
  | { type: FilterActionTypes.SET_FUEL_DEFAULT; payload: FilterTypes[] }
  | { type: FilterActionTypes.SET_BODY_DEFAULT; payload: FilterTypes[] }
  | {
      type: FilterActionTypes.INITIALISE_FILTERS;
      payload: { bodyDefault: FilterTypes[]; fuelDefault: FilterTypes[] };
    };

type FilterContextState = {
  bodyTypesFilter: FilterTypes[];
  bodyTypesFilterDefaultValue: FilterTypes[];
  fuelTypesFilter: FilterTypes[];
  fuelTypesFilterDefaultValue: FilterTypes[];
  modelCardResults: BodyTypeData[];
};

const initialState = {
  bodyTypesFilter: [],
  bodyTypesFilterDefaultValue: [],
  fuelTypesFilter: [],
  fuelTypesFilterDefaultValue: [],
  modelCardResults: [],
};

const FilterContext = createContext<FilterContextState>(initialState);

const FilterDispatchContext = createContext<React.Dispatch<FilterAction>>(() => null);

export const FilterProvider = ({ children }: { children: React.ReactNode }) => {
  const [filter, dispatch] = useReducer(filterReducer, initialState);

  return (
    <FilterContext.Provider value={filter}>
      <FilterDispatchContext.Provider value={dispatch}>{children}</FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export const useFilter = () => {
  return useContext(FilterContext);
};

export const useFilterDispatch = () => {
  return useContext(FilterDispatchContext);
};

const filterReducer = (state: FilterContextState, action: FilterAction): FilterContextState => {
  switch (action.type) {
    case FilterActionTypes.UPDATE_FUEL: {
      return {
        ...state,
        fuelTypesFilter: action.payload,
      };
    }
    case FilterActionTypes.SET_FUEL_DEFAULT: {
      return {
        ...state,
        fuelTypesFilterDefaultValue: action.payload,
      };
    }
    case FilterActionTypes.SET_BODY_DEFAULT: {
      return {
        ...state,
        bodyTypesFilterDefaultValue: action.payload,
      };
    }
    case FilterActionTypes.UPDATE_BODY: {
      return {
        ...state,
        bodyTypesFilter: action.payload,
      };
    }
    case FilterActionTypes.UPDATE_MODEL_CARD_RESULTS: {
      return {
        ...state,
        modelCardResults: action.payload,
      };
    }
    case FilterActionTypes.RESET_FILTERS: {
      return {
        ...state,
        bodyTypesFilter: state.bodyTypesFilterDefaultValue,
        fuelTypesFilter: state.fuelTypesFilterDefaultValue,
      };
    }
    case FilterActionTypes.INITIALISE_FILTERS: {
      return {
        ...state,
        bodyTypesFilter: action.payload.bodyDefault,
        bodyTypesFilterDefaultValue: action.payload.bodyDefault,
        fuelTypesFilter: action.payload.fuelDefault,
        fuelTypesFilterDefaultValue: action.payload.fuelDefault,
      };
    }
    default: {
      throw Error("The action type is not supported! Please check dispatched action type.");
    }
  }
};
