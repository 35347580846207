// Helper function to check for left mouse button and no modifier keys
export const isPrimaryMouseClick = (e: MouseEvent | React.MouseEvent): boolean =>
  e.button === 0 && !e.altKey && !e.ctrlKey && !e.metaKey;

// Helper function to check if the event target is inside an interactive element
export const isInsideInteractiveElement = (target: EventTarget | null): boolean => {
  const interactiveElements = ["BUTTON", "A", "INPUT", "TEXTAREA", "SELECT", "LABEL"];
  let currentElement = target as HTMLElement | null;

  while (currentElement) {
    if (interactiveElements.includes(currentElement.tagName.toUpperCase())) {
      return true; // Found an interactive element
    }
    currentElement = currentElement.parentElement;
  }

  return false;
};

export const getCustomCssProps = (noOfVisibleItems: number): React.CSSProperties => {
  return {
    ["--lk-private-carousel-max-items"]: noOfVisibleItems,
  };
};

declare module "csstype" {
  interface Properties {
    "--lk-private-carousel-max-items"?: number;
  }
}
