import { createContext, useContext, useMemo } from "react";

import { UncapitalizeKeys } from "@/utils/advancedTypes";

type CommonLinkProps = JSX.IntrinsicElements["a"];

export type LinkContextValue = {
  LinkComponent: React.ElementType<CommonLinkProps>;
};

const defaultLinkContext: LinkContextValue = {
  LinkComponent: "a",
};

const LinkContext = createContext<LinkContextValue>(defaultLinkContext);

type Props = UncapitalizeKeys<LinkContextValue>;

/**
 * @description
 * Use `LinkContext` to pass a custom `Link` component so that all `Typography` of `variant="textLink"` and `GenericLink`
 * within the context render as the provided `Link` instead of the default `<a>` tag.
 * This is useful when working with libraries that require their Link component for routing and navigation, removing
 * the need to manually replacing existing components.
 *
 * @param linkComponent A wrapper component to render a custom Link component.
 *
 * ### Example passing the `Link` component from `react-router-dom` that requires a `to` prop instead of `href`:
 *
 * ```tsx
 * <LinkContextProvider linkComponent={({ children, href, ...props }) =>
 *  <Link {...props} to={href} otherCustomProps={children.toString()}>{children}</Link>
 * }>...
 * ```
 */
const LinkContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  linkComponent,
  children,
}) => {
  const contextValue = useMemo(() => ({ LinkComponent: linkComponent }), [linkComponent]);

  return <LinkContext.Provider value={contextValue}>{children}</LinkContext.Provider>;
};

const useLinkContext = () => useContext(LinkContext);

export { LinkContextProvider, useLinkContext };
