import { memo } from "react";

import { SVGIconSizes, SVGStaticProps } from "../types";

let SVGEncoreSmall: React.FC<SVGStaticProps> = ({
  width = SVGIconSizes.DEFAULT,
  height = SVGIconSizes.DEFAULT,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    {...rest}
  >
    <path
      fill="currentColor"
      d="M11.636 6.182h-5.09V3.636h6.545V2.182h-8v5.454h6.545V6.182Zm1.454 6.182H6.547V9.818h5.09V8.364H5.123v5.454h7.968v-1.454Z"
    />
    <path fill="currentColor" d="M4.364 14.546V1.455h8.727V0H2.909v16h10.182v-1.454H4.364Z" />
  </svg>
);

SVGEncoreSmall = memo(SVGEncoreSmall);

export { SVGEncoreSmall };
